{ "compressionlevel":-1,
 "height":1,
 "infinite":true,
 "layers":[
        {
         "id":2,
         "image":"level-big.jpg",
         "name":"bg",
         "opacity":1,
         "type":"imagelayer",
         "visible":true,
         "x":0,
         "y":0
        }, 
        {
         "color":"#ff00ff",
         "draworder":"topdown",
         "id":9,
         "name":"itemdata",
         "objects":[
                {
                 "class":"",
                 "gid":2,
                 "height":128,
                 "id":43,
                 "name":"Blood",
                 "properties":[
                        {
                         "name":"image",
                         "type":"file",
                         "value":"blood-bag.png"
                        }, 
                        {
                         "name":"velocityMultiplier",
                         "type":"float",
                         "value":0.8
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":128,
                 "x":5.33333,
                 "y":-24
                }, 
                {
                 "class":"",
                 "gid":3,
                 "height":128,
                 "id":188,
                 "name":"Meat",
                 "properties":[
                        {
                         "name":"image",
                         "type":"file",
                         "value":"meat.png"
                        }, 
                        {
                         "name":"velocityMultiplier",
                         "type":"float",
                         "value":0.6
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":128,
                 "x":186.333,
                 "y":-23.6667
                }, 
                {
                 "class":"",
                 "gid":5,
                 "height":128,
                 "id":189,
                 "name":"Gretel",
                 "properties":[
                        {
                         "name":"image",
                         "type":"file",
                         "value":"gretel.png"
                        }, 
                        {
                         "name":"velocityMultiplier",
                         "type":"float",
                         "value":0.3
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":128,
                 "x":347.333,
                 "y":-10
                }, 
                {
                 "class":"",
                 "gid":4,
                 "height":128,
                 "id":190,
                 "name":"Hansel",
                 "properties":[
                        {
                         "name":"image",
                         "type":"file",
                         "value":"hansel.png"
                        }, 
                        {
                         "name":"velocityMultiplier",
                         "type":"float",
                         "value":0.25
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":128,
                 "x":503.333,
                 "y":-15.3333
                }],
         "opacity":1,
         "type":"objectgroup",
         "visible":true,
         "x":0,
         "y":0
        }, 
        {
         "color":"#00ffff",
         "draworder":"topdown",
         "id":10,
         "name":"cardata",
         "objects":[
                {
                 "class":"",
                 "gid":1,
                 "height":64,
                 "id":42,
                 "name":"BasicCariage",
                 "properties":[
                        {
                         "name":"acceleration",
                         "type":"float",
                         "value":500
                        }, 
                        {
                         "name":"image",
                         "type":"file",
                         "value":"truck.png"
                        }, 
                        {
                         "name":"rotationSpeed",
                         "type":"float",
                         "value":1
                        }, 
                        {
                         "name":"speed",
                         "type":"float",
                         "value":100
                        }, 
                        {
                         "name":"velocityRotationSpeedPenalty",
                         "type":"float",
                         "value":40
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":128,
                 "x":17.6667,
                 "y":-197.334
                }, 
                {
                 "class":"",
                 "height":50,
                 "id":50,
                 "name":"",
                 "rotation":0,
                 "text":
                    {
                     "color":"#ffffff",
                     "halign":"center",
                     "text":"Ez a sima egyszer\u0171 lovaskocsi",
                     "valign":"center",
                     "wrap":true
                    },
                 "visible":true,
                 "width":200,
                 "x":108.5,
                 "y":-290.833
                }],
         "opacity":1,
         "type":"objectgroup",
         "visible":true,
         "x":0,
         "y":0
        }, 
        {
         "draworder":"topdown",
         "id":18,
         "name":"enemydata",
         "objects":[
                {
                 "class":"",
                 "height":0,
                 "id":312,
                 "name":"Bat",
                 "point":true,
                 "properties":[
                        {
                         "name":"image",
                         "type":"file",
                         "value":"bat.png"
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":86.5,
                 "y":-431.5
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":313,
                 "name":"Werewolf",
                 "point":true,
                 "properties":[
                        {
                         "name":"image",
                         "type":"file",
                         "value":"werewolf.png"
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":133.5,
                 "y":-434.5
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":314,
                 "name":"Broom",
                 "point":true,
                 "properties":[
                        {
                         "name":"image",
                         "type":"file",
                         "value":"broom.png"
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":187,
                 "y":-435
                }],
         "opacity":1,
         "type":"objectgroup",
         "visible":true,
         "x":0,
         "y":0
        }, 
        {
         "draworder":"topdown",
         "id":15,
         "name":"ambientdata",
         "objects":[
                {
                 "class":"",
                 "height":0,
                 "id":194,
                 "name":"Forest",
                 "point":true,
                 "properties":[
                        {
                         "name":"sound",
                         "type":"file",
                         "value":"Forest.flac"
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":84,
                 "y":-340.667
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":195,
                 "name":"City",
                 "point":true,
                 "properties":[
                        {
                         "name":"sound",
                         "type":"file",
                         "value":"City.flac"
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":141.333,
                 "y":-346.667
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":196,
                 "name":"Water",
                 "point":true,
                 "properties":[
                        {
                         "name":"sound",
                         "type":"file",
                         "value":"Water.flac"
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":216,
                 "y":-346
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":197,
                 "name":"Mountain",
                 "point":true,
                 "properties":[
                        {
                         "name":"sound",
                         "type":"file",
                         "value":"Mountain.flac"
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":272,
                 "y":-346.667
                }],
         "opacity":1,
         "type":"objectgroup",
         "visible":true,
         "x":0,
         "y":0
        }, 
        {
         "color":"#00aa7f",
         "draworder":"topdown",
         "id":14,
         "name":"ambient",
         "objects":[
                {
                 "class":"",
                 "ellipse":true,
                 "height":850,
                 "id":264,
                 "name":"Forest",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":194
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":918,
                 "x":2928,
                 "y":1862
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":882,
                 "id":265,
                 "name":"Forest",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":194
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":882,
                 "x":4658,
                 "y":2558
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":512,
                 "id":266,
                 "name":"City",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":195
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":512,
                 "x":3250,
                 "y":1460
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":1072.91,
                 "id":267,
                 "name":"Forest",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":194
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":1206.24,
                 "x":3471.12,
                 "y":3555.97
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":603.212,
                 "id":268,
                 "name":"Forest",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":194
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":836.545,
                 "x":1680.21,
                 "y":4449.91
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":382,
                 "id":269,
                 "name":"Forest",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":194
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":460.788,
                 "x":2616.57,
                 "y":4159
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":851.697,
                 "id":270,
                 "name":"Forest",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":194
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":1018.36,
                 "x":2028.7,
                 "y":3440.81
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":369.879,
                 "id":271,
                 "name":"Forest",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":194
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":403.212,
                 "x":2889.3,
                 "y":3231.72
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":669.879,
                 "id":272,
                 "name":"Forest",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":194
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":933.515,
                 "x":3343.85,
                 "y":2689.3
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":648.667,
                 "id":273,
                 "name":"Forest",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":194
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":797.147,
                 "x":4434.76,
                 "y":1480.21
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":566.848,
                 "id":274,
                 "name":"Forest",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":194
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":882,
                 "x":3392.33,
                 "y":1122.64
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":609.273,
                 "id":275,
                 "name":"Forest",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":194
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":648.666,
                 "x":2871.12,
                 "y":880.212
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":988.061,
                 "id":276,
                 "name":"Forest",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":194
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":1175.94,
                 "x":2204.45,
                 "y":5046.88
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":1145.64,
                 "id":277,
                 "name":"Forest",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":194
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":1172.91,
                 "x":5622.64,
                 "y":1671.12
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":530.485,
                 "id":278,
                 "name":"Forest",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":194
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":527.455,
                 "x":6737.79,
                 "y":1434.76
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":393.818,
                 "id":279,
                 "name":"City",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":195
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":493.818,
                 "x":2386.42,
                 "y":5471.27
                },
            
                {
                 "class":"",
                 "ellipse":true,
                 "height":964,
                 "id":280,
                 "name":"Water",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":196
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":964,
                 "x":1976,
                 "y":1870
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":1487.88,
                 "id":281,
                 "name":"",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":197
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":1487.88,
                 "x":5136.36,
                 "y":542.424
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":603.212,
                 "id":282,
                 "name":"Forest",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":194
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":836.545,
                 "x":2239.3,
                 "y":4395.36
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":1487.88,
                 "id":283,
                 "name":"",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":197
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":1487.88,
                 "x":3786.36,
                 "y":134.848
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":939.395,
                 "id":284,
                 "name":"",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":197
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":1075.76,
                 "x":3798.48,
                 "y":4856.06
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":964,
                 "id":285,
                 "name":"Water",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":196
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":964,
                 "x":1818,
                 "y":2669.52
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":964,
                 "id":286,
                 "name":"Water",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":196
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":964,
                 "x":2305.88,
                 "y":1099.82
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":964,
                 "id":287,
                 "name":"Water",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":196
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":964,
                 "x":3181.64,
                 "y":269.515
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":964,
                 "id":288,
                 "name":"Water",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":196
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":964,
                 "x":3918,
                 "y":42.2424
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":536.727,
                 "id":289,
                 "name":"Water",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":196
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":564,
                 "x":5402.85,
                 "y":3427.09
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":388.242,
                 "id":290,
                 "name":"Water",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":196
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":464,
                 "x":4845.27,
                 "y":4193.76
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":964,
                 "id":291,
                 "name":"Water",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":196
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":964,
                 "x":3487.7,
                 "y":4290.73
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":964,
                 "id":292,
                 "name":"Water",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":196
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":964,
                 "x":4142.24,
                 "y":3951.33
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":964,
                 "id":293,
                 "name":"Water",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":196
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":964,
                 "x":3287.7,
                 "y":5842.24
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":964,
                 "id":294,
                 "name":"Water",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":196
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":964,
                 "x":4133.15,
                 "y":5769.52
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":964,
                 "id":295,
                 "name":"Water",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":196
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":964,
                 "x":2066.48,
                 "y":5672.55
                },
            
                {
                 "class":"",
                 "ellipse":true,
                 "height":964,
                 "id":296,
                 "name":"Water",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":196
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":964,
                 "x":6887.7,
                 "y":2345.27
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":964,
                 "id":297,
                 "name":"Water",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":196
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":964,
                 "x":6978.61,
                 "y":1578.61
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":964,
                 "id":298,
                 "name":"Water",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":196
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":964,
                 "x":6884.67,
                 "y":430.121
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":964,
                 "id":299,
                 "name":"Water",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":196
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":964,
                 "x":6139.21,
                 "y":3081.64
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":964,
                 "id":300,
                 "name":"Water",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":196
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":964,
                 "x":1039.21,
                 "y":4675.58
                }, 
                {
                 "class":"",
                 "ellipse":true,
                 "height":408,
                 "id":306,
                 "name":"Water",
                 "properties":[
                        {
                         "name":"effect",
                         "type":"object",
                         "value":196
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":480,
                 "x":4102,
                 "y":3170
                }],
         "opacity":1,
         "type":"objectgroup",
         "visible":true,
         "x":0,
         "y":0
        }, 
        {
         "color":"#646464",
         "draworder":"topdown",
         "id":4,
         "name":"road",
         "objects":[
                {
                 "class":"",
                 "height":0,
                 "id":168,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":18.6667,
                         "y":24
                        }, 
                        {
                         "x":-54.6667,
                         "y":57.3333
                        }, 
                        {
                         "x":-113.333,
                         "y":125.333
                        }, 
                        {
                         "x":-153.333,
                         "y":152
                        }, 
                        {
                         "x":-210.667,
                         "y":220
                        }, 
                        {
                         "x":-212,
                         "y":290.667
                        }, 
                        {
                         "x":-188,
                         "y":304
                        }, 
                        {
                         "x":-96,
                         "y":292
                        }, 
                        {
                         "x":-10.6667,
                         "y":280
                        }, 
                        {
                         "x":57.3333,
                         "y":284
                        }, 
                        {
                         "x":138.667,
                         "y":296
                        }, 
                        {
                         "x":201.333,
                         "y":312
                        }, 
                        {
                         "x":261.333,
                         "y":368
                        }, 
                        {
                         "x":326.667,
                         "y":450.667
                        }, 
                        {
                         "x":386.667,
                         "y":529.333
                        },
                    
                        {
                         "x":412,
                         "y":600
                        }, 
                        {
                         "x":410.667,
                         "y":652
                        }, 
                        {
                         "x":385.333,
                         "y":689.333
                        }, 
                        {
                         "x":332,
                         "y":716
                        }, 
                        {
                         "x":276,
                         "y":769.333
                        }, 
                        {
                         "x":221.333,
                         "y":772
                        }, 
                        {
                         "x":140,
                         "y":770.667
                        }, 
                        {
                         "x":77.3333,
                         "y":769.333
                        }, 
                        {
                         "x":37.3333,
                         "y":736
                        }, 
                        {
                         "x":20,
                         "y":672
                        }, 
                        {
                         "x":8,
                         "y":598.667
                        }, 
                        {
                         "x":56,
                         "y":596
                        }, 
                        {
                         "x":64,
                         "y":637.333
                        }, 
                        {
                         "x":70.6667,
                         "y":694.667
                        }, 
                        {
                         "x":105.333,
                         "y":728
                        }, 
                        {
                         "x":153.333,
                         "y":737.333
                        },
                    
                        {
                         "x":240,
                         "y":738.667
                        }, 
                        {
                         "x":296,
                         "y":704
                        }, 
                        {
                         "x":322.667,
                         "y":669.333
                        }, 
                        {
                         "x":356,
                         "y":645.333
                        }, 
                        {
                         "x":373.333,
                         "y":601.333
                        }, 
                        {
                         "x":352,
                         "y":558.667
                        }, 
                        {
                         "x":290.667,
                         "y":474.667
                        }, 
                        {
                         "x":176,
                         "y":349.333
                        }, 
                        {
                         "x":20,
                         "y":318.667
                        }, 
                        {
                         "x":-105.333,
                         "y":338.667
                        }, 
                        {
                         "x":-198.667,
                         "y":354.667
                        }, 
                        {
                         "x":-253.333,
                         "y":338.667
                        }, 
                        {
                         "x":-276,
                         "y":284
                        }, 
                        {
                         "x":-262.667,
                         "y":212
                        }, 
                        {
                         "x":-233.333,
                         "y":165.333
                        }, 
                        {
                         "x":-200,
                         "y":132
                        },
                    
                        {
                         "x":-149.333,
                         "y":88
                        }, 
                        {
                         "x":-120,
                         "y":52
                        }, 
                        {
                         "x":-72,
                         "y":18.6667
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3452,
                 "y":1193.33
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":169,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-41.3333,
                         "y":36
                        }, 
                        {
                         "x":-89.3333,
                         "y":66.6667
                        }, 
                        {
                         "x":-121.333,
                         "y":98.6667
                        }, 
                        {
                         "x":-149.333,
                         "y":145.333
                        }, 
                        {
                         "x":-182.667,
                         "y":176
                        }, 
                        {
                         "x":-241.333,
                         "y":226.667
                        }, 
                        {
                         "x":-278.667,
                         "y":266.667
                        }, 
                        {
                         "x":-293.333,
                         "y":341.333
                        }, 
                        {
                         "x":-264,
                         "y":414.667
                        }, 
                        {
                         "x":-209.333,
                         "y":441.333
                        }, 
                        {
                         "x":-140,
                         "y":468
                        }, 
                        {
                         "x":-74.6667,
                         "y":465.333
                        }, 
                        {
                         "x":-26.6667,
                         "y":470.667
                        }, 
                        {
                         "x":32,
                         "y":494.667
                        }, 
                        {
                         "x":142.667,
                         "y":550.667
                        },
                    
                        {
                         "x":188,
                         "y":589.333
                        }, 
                        {
                         "x":229.333,
                         "y":637.333
                        }, 
                        {
                         "x":249.333,
                         "y":681.333
                        }, 
                        {
                         "x":276,
                         "y":750.667
                        }, 
                        {
                         "x":288,
                         "y":800
                        }, 
                        {
                         "x":321.333,
                         "y":849.333
                        }, 
                        {
                         "x":365.333,
                         "y":880
                        }, 
                        {
                         "x":420,
                         "y":892
                        }, 
                        {
                         "x":478.667,
                         "y":886.667
                        }, 
                        {
                         "x":524,
                         "y":869.333
                        }, 
                        {
                         "x":569.333,
                         "y":824
                        }, 
                        {
                         "x":569.333,
                         "y":801.333
                        }, 
                        {
                         "x":494.667,
                         "y":802.667
                        }, 
                        {
                         "x":453.333,
                         "y":830.667
                        }, 
                        {
                         "x":398.667,
                         "y":836
                        }, 
                        {
                         "x":352,
                         "y":790.667
                        },
                    
                        {
                         "x":333.333,
                         "y":725.333
                        }, 
                        {
                         "x":321.333,
                         "y":676
                        }, 
                        {
                         "x":301.333,
                         "y":626.667
                        }, 
                        {
                         "x":278.667,
                         "y":594.667
                        }, 
                        {
                         "x":234.667,
                         "y":553.333
                        }, 
                        {
                         "x":189.333,
                         "y":520
                        }, 
                        {
                         "x":145.333,
                         "y":500
                        }, 
                        {
                         "x":76,
                         "y":460
                        }, 
                        {
                         "x":14.6667,
                         "y":424
                        }, 
                        {
                         "x":-62.6667,
                         "y":417.333
                        }, 
                        {
                         "x":-134.667,
                         "y":417.333
                        }, 
                        {
                         "x":-193.333,
                         "y":394.667
                        }, 
                        {
                         "x":-232,
                         "y":342.667
                        }, 
                        {
                         "x":-221.333,
                         "y":276
                        }, 
                        {
                         "x":-197.333,
                         "y":244
                        }, 
                        {
                         "x":-140,
                         "y":197.333
                        },
                    
                        {
                         "x":-85.3333,
                         "y":136
                        }, 
                        {
                         "x":-46.6667,
                         "y":82.6667
                        }, 
                        {
                         "x":45.3333,
                         "y":17.3333
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3498.67,
                 "y":1918.67
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":175,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":64,
                         "y":4
                        }, 
                        {
                         "x":60,
                         "y":84
                        }, 
                        {
                         "x":98,
                         "y":158
                        }, 
                        {
                         "x":134,
                         "y":210
                        }, 
                        {
                         "x":220,
                         "y":262
                        }, 
                        {
                         "x":296,
                         "y":296
                        }, 
                        {
                         "x":342,
                         "y":306
                        }, 
                        {
                         "x":418,
                         "y":262
                        }, 
                        {
                         "x":496,
                         "y":148
                        }, 
                        {
                         "x":582,
                         "y":-34
                        }, 
                        {
                         "x":666,
                         "y":-242
                        }, 
                        {
                         "x":718,
                         "y":-360
                        }, 
                        {
                         "x":680,
                         "y":-512
                        }, 
                        {
                         "x":678,
                         "y":-642
                        }, 
                        {
                         "x":734,
                         "y":-762
                        },
                    
                        {
                         "x":836,
                         "y":-832
                        }, 
                        {
                         "x":992,
                         "y":-860
                        }, 
                        {
                         "x":1166,
                         "y":-874
                        }, 
                        {
                         "x":1354,
                         "y":-840
                        }, 
                        {
                         "x":1480,
                         "y":-816
                        }, 
                        {
                         "x":1568,
                         "y":-794
                        }, 
                        {
                         "x":1708,
                         "y":-800
                        }, 
                        {
                         "x":1870,
                         "y":-794
                        }, 
                        {
                         "x":1934,
                         "y":-922
                        }, 
                        {
                         "x":1998,
                         "y":-936
                        }, 
                        {
                         "x":1914,
                         "y":-706
                        }, 
                        {
                         "x":1772,
                         "y":-718
                        }, 
                        {
                         "x":1656,
                         "y":-724
                        }, 
                        {
                         "x":1458,
                         "y":-760
                        }, 
                        {
                         "x":1310,
                         "y":-788
                        }, 
                        {
                         "x":1202,
                         "y":-814
                        },
                    
                        {
                         "x":1092,
                         "y":-804
                        }, 
                        {
                         "x":940,
                         "y":-778
                        }, 
                        {
                         "x":838,
                         "y":-754
                        }, 
                        {
                         "x":798,
                         "y":-712
                        }, 
                        {
                         "x":762,
                         "y":-642
                        }, 
                        {
                         "x":760,
                         "y":-526
                        }, 
                        {
                         "x":780,
                         "y":-418
                        }, 
                        {
                         "x":772,
                         "y":-322
                        }, 
                        {
                         "x":738,
                         "y":-232
                        }, 
                        {
                         "x":674,
                         "y":-62
                        }, 
                        {
                         "x":602,
                         "y":108
                        }, 
                        {
                         "x":544,
                         "y":238
                        }, 
                        {
                         "x":454,
                         "y":338
                        }, 
                        {
                         "x":382,
                         "y":380
                        }, 
                        {
                         "x":248,
                         "y":354
                        }, 
                        {
                         "x":118,
                         "y":274
                        },
                    
                        {
                         "x":16,
                         "y":174
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2796,
                 "y":3238
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":176,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":80,
                         "y":12
                        }, 
                        {
                         "x":38,
                         "y":168
                        }, 
                        {
                         "x":8,
                         "y":296
                        }, 
                        {
                         "x":-22,
                         "y":396
                        }, 
                        {
                         "x":-52,
                         "y":466
                        }, 
                        {
                         "x":-84,
                         "y":538
                        }, 
                        {
                         "x":-112,
                         "y":608
                        }, 
                        {
                         "x":-124,
                         "y":666
                        }, 
                        {
                         "x":-106,
                         "y":740
                        }, 
                        {
                         "x":-62,
                         "y":812
                        }, 
                        {
                         "x":-10,
                         "y":854
                        }, 
                        {
                         "x":62,
                         "y":876
                        }, 
                        {
                         "x":276,
                         "y":870
                        }, 
                        {
                         "x":420,
                         "y":874
                        }, 
                        {
                         "x":634,
                         "y":888
                        },
                    
                        {
                         "x":820,
                         "y":866
                        }, 
                        {
                         "x":962,
                         "y":812
                        }, 
                        {
                         "x":1196,
                         "y":708
                        }, 
                        {
                         "x":1320,
                         "y":622
                        }, 
                        {
                         "x":1416,
                         "y":558
                        }, 
                        {
                         "x":1446,
                         "y":456
                        }, 
                        {
                         "x":1438,
                         "y":408
                        }, 
                        {
                         "x":1444,
                         "y":284
                        }, 
                        {
                         "x":1450,
                         "y":232
                        }, 
                        {
                         "x":1410,
                         "y":162
                        }, 
                        {
                         "x":1484,
                         "y":162
                        }, 
                        {
                         "x":1534,
                         "y":260
                        }, 
                        {
                         "x":1504,
                         "y":348
                        }, 
                        {
                         "x":1500,
                         "y":440
                        }, 
                        {
                         "x":1496,
                         "y":536
                        }, 
                        {
                         "x":1482,
                         "y":598
                        },
                    
                        {
                         "x":1452,
                         "y":630
                        }, 
                        {
                         "x":1392,
                         "y":666
                        }, 
                        {
                         "x":1324,
                         "y":704
                        }, 
                        {
                         "x":1214,
                         "y":762
                        }, 
                        {
                         "x":1074,
                         "y":836
                        }, 
                        {
                         "x":942,
                         "y":902
                        }, 
                        {
                         "x":800,
                         "y":946
                        }, 
                        {
                         "x":636,
                         "y":960
                        }, 
                        {
                         "x":394,
                         "y":926
                        }, 
                        {
                         "x":246,
                         "y":930
                        }, 
                        {
                         "x":58,
                         "y":944
                        }, 
                        {
                         "x":-94,
                         "y":886
                        }, 
                        {
                         "x":-192,
                         "y":716
                        }, 
                        {
                         "x":-186,
                         "y":576
                        }, 
                        {
                         "x":-108,
                         "y":448
                        }, 
                        {
                         "x":-62,
                         "y":312
                        },
                    
                        {
                         "x":-16,
                         "y":160
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4644,
                 "y":2512
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":177,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-14,
                         "y":52
                        }, 
                        {
                         "x":-110,
                         "y":32
                        }, 
                        {
                         "x":-202,
                         "y":8
                        }, 
                        {
                         "x":-288,
                         "y":40
                        }, 
                        {
                         "x":-382,
                         "y":46
                        }, 
                        {
                         "x":-500,
                         "y":10
                        }, 
                        {
                         "x":-536,
                         "y":-14
                        }, 
                        {
                         "x":-594,
                         "y":12
                        }, 
                        {
                         "x":-650,
                         "y":62
                        }, 
                        {
                         "x":-678,
                         "y":174
                        }, 
                        {
                         "x":-692,
                         "y":274
                        }, 
                        {
                         "x":-672,
                         "y":396
                        }, 
                        {
                         "x":-606,
                         "y":496
                        }, 
                        {
                         "x":-510,
                         "y":570
                        }, 
                        {
                         "x":-432,
                         "y":642
                        },
                    
                        {
                         "x":-370,
                         "y":720
                        }, 
                        {
                         "x":-376,
                         "y":802
                        }, 
                        {
                         "x":-354,
                         "y":844
                        }, 
                        {
                         "x":-552,
                         "y":1064
                        }, 
                        {
                         "x":-476,
                         "y":1076
                        }, 
                        {
                         "x":-320,
                         "y":912
                        }, 
                        {
                         "x":-288,
                         "y":786
                        }, 
                        {
                         "x":-322,
                         "y":678
                        }, 
                        {
                         "x":-414,
                         "y":564
                        }, 
                        {
                         "x":-512,
                         "y":470
                        }, 
                        {
                         "x":-578,
                         "y":398
                        }, 
                        {
                         "x":-626,
                         "y":328
                        }, 
                        {
                         "x":-622,
                         "y":242
                        }, 
                        {
                         "x":-576,
                         "y":106
                        }, 
                        {
                         "x":-530,
                         "y":60
                        }, 
                        {
                         "x":-416,
                         "y":74
                        },
                    
                        {
                         "x":-314,
                         "y":72
                        }, 
                        {
                         "x":-230,
                         "y":60
                        }, 
                        {
                         "x":-190,
                         "y":50
                        }, 
                        {
                         "x":-122,
                         "y":58
                        }, 
                        {
                         "x":-72,
                         "y":82
                        }, 
                        {
                         "x":-12,
                         "y":94
                        }, 
                        {
                         "x":34,
                         "y":92
                        }, 
                        {
                         "x":52,
                         "y":54
                        }, 
                        {
                         "x":80,
                         "y":-18
                        }, 
                        {
                         "x":6,
                         "y":-18
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":6126,
                 "y":2286
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":178,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":72,
                         "y":4
                        }, 
                        {
                         "x":108,
                         "y":118
                        }, 
                        {
                         "x":172,
                         "y":186
                        }, 
                        {
                         "x":242,
                         "y":236
                        }, 
                        {
                         "x":304,
                         "y":326
                        }, 
                        {
                         "x":346,
                         "y":432
                        }, 
                        {
                         "x":304,
                         "y":566
                        }, 
                        {
                         "x":258,
                         "y":648
                        }, 
                        {
                         "x":180,
                         "y":720
                        }, 
                        {
                         "x":70,
                         "y":784
                        }, 
                        {
                         "x":10,
                         "y":842
                        }, 
                        {
                         "x":-48,
                         "y":930
                        }, 
                        {
                         "x":-96,
                         "y":922
                        }, 
                        {
                         "x":-246,
                         "y":770
                        }, 
                        {
                         "x":-160,
                         "y":752
                        },
                    
                        {
                         "x":-78,
                         "y":812
                        }, 
                        {
                         "x":52,
                         "y":700
                        }, 
                        {
                         "x":198,
                         "y":610
                        }, 
                        {
                         "x":278,
                         "y":444
                        }, 
                        {
                         "x":238,
                         "y":338
                        }, 
                        {
                         "x":152,
                         "y":230
                        }, 
                        {
                         "x":78,
                         "y":174
                        }, 
                        {
                         "x":42,
                         "y":96
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":6956,
                 "y":1190
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":179,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":100,
                         "y":16
                        }, 
                        {
                         "x":24,
                         "y":94
                        }, 
                        {
                         "x":8,
                         "y":148
                        }, 
                        {
                         "x":32,
                         "y":210
                        }, 
                        {
                         "x":86,
                         "y":220
                        }, 
                        {
                         "x":206,
                         "y":218
                        }, 
                        {
                         "x":330,
                         "y":270
                        }, 
                        {
                         "x":510,
                         "y":332
                        }, 
                        {
                         "x":518,
                         "y":392
                        }, 
                        {
                         "x":430,
                         "y":470
                        }, 
                        {
                         "x":290,
                         "y":570
                        }, 
                        {
                         "x":168,
                         "y":642
                        }, 
                        {
                         "x":-32,
                         "y":696
                        }, 
                        {
                         "x":-158,
                         "y":674
                        }, 
                        {
                         "x":-280,
                         "y":660
                        },
                    
                        {
                         "x":-390,
                         "y":676
                        }, 
                        {
                         "x":-544,
                         "y":674
                        }, 
                        {
                         "x":-602,
                         "y":654
                        }, 
                        {
                         "x":-676,
                         "y":684
                        }, 
                        {
                         "x":-708,
                         "y":638
                        }, 
                        {
                         "x":-586,
                         "y":610
                        }, 
                        {
                         "x":-448,
                         "y":626
                        }, 
                        {
                         "x":-320,
                         "y":610
                        }, 
                        {
                         "x":-32,
                         "y":636
                        }, 
                        {
                         "x":160,
                         "y":592
                        }, 
                        {
                         "x":266,
                         "y":502
                        }, 
                        {
                         "x":460,
                         "y":368
                        }, 
                        {
                         "x":284,
                         "y":316
                        }, 
                        {
                         "x":190,
                         "y":258
                        }, 
                        {
                         "x":96,
                         "y":290
                        }, 
                        {
                         "x":0,
                         "y":282
                        },
                    
                        {
                         "x":-26,
                         "y":214
                        }, 
                        {
                         "x":-58,
                         "y":164
                        }, 
                        {
                         "x":-40,
                         "y":80
                        }, 
                        {
                         "x":0,
                         "y":44
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":6842,
                 "y":2094
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":180,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":66.6667,
                         "y":129.333
                        }, 
                        {
                         "x":105.333,
                         "y":230.667
                        }, 
                        {
                         "x":88,
                         "y":292
                        }, 
                        {
                         "x":-53.3333,
                         "y":286.667
                        }, 
                        {
                         "x":-189.333,
                         "y":261.333
                        }, 
                        {
                         "x":-286.667,
                         "y":221.333
                        }, 
                        {
                         "x":-382.667,
                         "y":226.667
                        }, 
                        {
                         "x":-416,
                         "y":252
                        }, 
                        {
                         "x":-446.667,
                         "y":254.667
                        }, 
                        {
                         "x":-476,
                         "y":200
                        }, 
                        {
                         "x":-506.667,
                         "y":164
                        }, 
                        {
                         "x":-541.333,
                         "y":157.333
                        }, 
                        {
                         "x":-572,
                         "y":169.333
                        }, 
                        {
                         "x":-609.333,
                         "y":208
                        }, 
                        {
                         "x":-652,
                         "y":262.667
                        },
                    
                        {
                         "x":-688,
                         "y":296
                        }, 
                        {
                         "x":-742.667,
                         "y":276
                        }, 
                        {
                         "x":-800,
                         "y":266.667
                        }, 
                        {
                         "x":-936,
                         "y":304
                        }, 
                        {
                         "x":-1137.33,
                         "y":306.667
                        }, 
                        {
                         "x":-1328,
                         "y":318.667
                        }, 
                        {
                         "x":-1388,
                         "y":332
                        }, 
                        {
                         "x":-1645.33,
                         "y":289.333
                        }, 
                        {
                         "x":-1794.67,
                         "y":258.667
                        }, 
                        {
                         "x":-1829.33,
                         "y":210.667
                        }, 
                        {
                         "x":-1788,
                         "y":144
                        }, 
                        {
                         "x":-1724,
                         "y":149.333
                        }, 
                        {
                         "x":-1636,
                         "y":141.333
                        }, 
                        {
                         "x":-1506.67,
                         "y":157.333
                        }, 
                        {
                         "x":-1441.33,
                         "y":133.333
                        }, 
                        {
                         "x":-1408,
                         "y":72
                        },
                    
                        {
                         "x":-1494.67,
                         "y":54.6667
                        }, 
                        {
                         "x":-1537.33,
                         "y":92
                        }, 
                        {
                         "x":-1608,
                         "y":110.667
                        }, 
                        {
                         "x":-1734.67,
                         "y":109.333
                        }, 
                        {
                         "x":-1785.33,
                         "y":101.333
                        }, 
                        {
                         "x":-1850.67,
                         "y":164
                        }, 
                        {
                         "x":-1877.33,
                         "y":200
                        }, 
                        {
                         "x":-1850.67,
                         "y":261.333
                        }, 
                        {
                         "x":-1806.67,
                         "y":292
                        }, 
                        {
                         "x":-1678.67,
                         "y":326.667
                        }, 
                        {
                         "x":-1478.67,
                         "y":365.333
                        }, 
                        {
                         "x":-1289.33,
                         "y":376
                        }, 
                        {
                         "x":-1137.33,
                         "y":345.333
                        }, 
                        {
                         "x":-922.667,
                         "y":345.333
                        }, 
                        {
                         "x":-800,
                         "y":306.667
                        }, 
                        {
                         "x":-725.333,
                         "y":332
                        },
                    
                        {
                         "x":-662.667,
                         "y":329.333
                        }, 
                        {
                         "x":-576,
                         "y":276
                        }, 
                        {
                         "x":-552,
                         "y":214.667
                        }, 
                        {
                         "x":-526.667,
                         "y":212
                        }, 
                        {
                         "x":-508,
                         "y":241.333
                        }, 
                        {
                         "x":-482.667,
                         "y":286.667
                        }, 
                        {
                         "x":-461.333,
                         "y":310.667
                        }, 
                        {
                         "x":-400,
                         "y":293.333
                        }, 
                        {
                         "x":-334.667,
                         "y":286.667
                        }, 
                        {
                         "x":-282.667,
                         "y":270.667
                        }, 
                        {
                         "x":-160,
                         "y":317.333
                        }, 
                        {
                         "x":8,
                         "y":336
                        }, 
                        {
                         "x":128,
                         "y":341.333
                        }, 
                        {
                         "x":140,
                         "y":257.333
                        }, 
                        {
                         "x":136,
                         "y":176
                        }, 
                        {
                         "x":33.3333,
                         "y":-48
                        },
                    
                        {
                         "x":-4,
                         "y":-44
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4149.33,
                 "y":5657.33
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":181,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-2.66667,
                         "y":-86.6667
                        }, 
                        {
                         "x":-5.33333,
                         "y":-158.667
                        }, 
                        {
                         "x":-13.3333,
                         "y":-249.333
                        }, 
                        {
                         "x":30.6667,
                         "y":-368
                        }, 
                        {
                         "x":93.3333,
                         "y":-522.667
                        }, 
                        {
                         "x":105.333,
                         "y":-577.333
                        }, 
                        {
                         "x":124,
                         "y":-642.667
                        }, 
                        {
                         "x":169.333,
                         "y":-733.333
                        }, 
                        {
                         "x":248,
                         "y":-797.333
                        }, 
                        {
                         "x":353.333,
                         "y":-813.333
                        }, 
                        {
                         "x":476,
                         "y":-852
                        }, 
                        {
                         "x":576,
                         "y":-909.333
                        }, 
                        {
                         "x":666.667,
                         "y":-1004
                        }, 
                        {
                         "x":701.333,
                         "y":-1062.67
                        }, 
                        {
                         "x":688,
                         "y":-1129.33
                        },
                    
                        {
                         "x":656,
                         "y":-1181.33
                        }, 
                        {
                         "x":612,
                         "y":-1238.67
                        }, 
                        {
                         "x":528,
                         "y":-1330.67
                        }, 
                        {
                         "x":522.667,
                         "y":-1412
                        }, 
                        {
                         "x":560,
                         "y":-1465.33
                        }, 
                        {
                         "x":618.667,
                         "y":-1553.33
                        }, 
                        {
                         "x":678.667,
                         "y":-1540
                        }, 
                        {
                         "x":666.667,
                         "y":-1512
                        }, 
                        {
                         "x":601.333,
                         "y":-1454.67
                        }, 
                        {
                         "x":562.667,
                         "y":-1370.67
                        }, 
                        {
                         "x":589.333,
                         "y":-1317.33
                        }, 
                        {
                         "x":641.333,
                         "y":-1260
                        }, 
                        {
                         "x":745.333,
                         "y":-1124
                        }, 
                        {
                         "x":749.333,
                         "y":-1020
                        }, 
                        {
                         "x":669.333,
                         "y":-949.333
                        }, 
                        {
                         "x":604,
                         "y":-868
                        },
                    
                        {
                         "x":541.333,
                         "y":-817.333
                        }, 
                        {
                         "x":433.333,
                         "y":-784
                        }, 
                        {
                         "x":254.667,
                         "y":-745.333
                        }, 
                        {
                         "x":178.667,
                         "y":-632
                        }, 
                        {
                         "x":130.667,
                         "y":-545.333
                        }, 
                        {
                         "x":112,
                         "y":-434.667
                        }, 
                        {
                         "x":69.3333,
                         "y":-328
                        }, 
                        {
                         "x":36,
                         "y":-240
                        }, 
                        {
                         "x":30.6667,
                         "y":-133.333
                        }, 
                        {
                         "x":30.6667,
                         "y":-57.3333
                        }, 
                        {
                         "x":52,
                         "y":0
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2357.33,
                 "y":5773.33
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":182,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-20,
                         "y":65.3333
                        }, 
                        {
                         "x":13.3333,
                         "y":114.667
                        }, 
                        {
                         "x":73.3333,
                         "y":146.667
                        }, 
                        {
                         "x":110.667,
                         "y":196
                        }, 
                        {
                         "x":145.333,
                         "y":262.667
                        }, 
                        {
                         "x":174.667,
                         "y":320
                        }, 
                        {
                         "x":250.667,
                         "y":358.667
                        }, 
                        {
                         "x":361.333,
                         "y":373.333
                        }, 
                        {
                         "x":493.333,
                         "y":377.333
                        }, 
                        {
                         "x":608,
                         "y":376
                        }, 
                        {
                         "x":686.667,
                         "y":361.333
                        }, 
                        {
                         "x":692,
                         "y":285.333
                        }, 
                        {
                         "x":602.667,
                         "y":316
                        }, 
                        {
                         "x":554.667,
                         "y":320
                        }, 
                        {
                         "x":490.667,
                         "y":310.667
                        },
                    
                        {
                         "x":394.667,
                         "y":310.667
                        }, 
                        {
                         "x":293.333,
                         "y":294.667
                        }, 
                        {
                         "x":208,
                         "y":260
                        }, 
                        {
                         "x":169.333,
                         "y":169.333
                        }, 
                        {
                         "x":148,
                         "y":116
                        }, 
                        {
                         "x":54.6667,
                         "y":68
                        }, 
                        {
                         "x":37.3333,
                         "y":-9.33333
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":1796,
                 "y":4845.33
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":183,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":94.6667,
                         "y":92
                        }, 
                        {
                         "x":178.667,
                         "y":134.667
                        }, 
                        {
                         "x":286.667,
                         "y":144
                        }, 
                        {
                         "x":290.667,
                         "y":50.6667
                        }, 
                        {
                         "x":180,
                         "y":50.6667
                        }, 
                        {
                         "x":132,
                         "y":70.6667
                        }, 
                        {
                         "x":56,
                         "y":0
                        }, 
                        {
                         "x":21.3333,
                         "y":-22.6667
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2865.33,
                 "y":4940
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":184,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-97.3333,
                         "y":-45.3333
                        }, 
                        {
                         "x":-156,
                         "y":-96
                        }, 
                        {
                         "x":-125.333,
                         "y":-206.667
                        }, 
                        {
                         "x":-25.3333,
                         "y":-297.333
                        }, 
                        {
                         "x":92,
                         "y":-324
                        }, 
                        {
                         "x":104,
                         "y":-370.667
                        }, 
                        {
                         "x":54.6667,
                         "y":-422.667
                        }, 
                        {
                         "x":12,
                         "y":-502.667
                        }, 
                        {
                         "x":-50.6667,
                         "y":-549.333
                        }, 
                        {
                         "x":-125.333,
                         "y":-586.667
                        }, 
                        {
                         "x":-234.667,
                         "y":-650.667
                        }, 
                        {
                         "x":-309.333,
                         "y":-700
                        }, 
                        {
                         "x":-304,
                         "y":-760
                        }, 
                        {
                         "x":-177.333,
                         "y":-838.667
                        }, 
                        {
                         "x":-126.667,
                         "y":-893.333
                        },
                    
                        {
                         "x":-86.6667,
                         "y":-920
                        }, 
                        {
                         "x":-41.3333,
                         "y":-846.667
                        }, 
                        {
                         "x":-138.667,
                         "y":-813.333
                        }, 
                        {
                         "x":-205.333,
                         "y":-750.667
                        }, 
                        {
                         "x":-214.667,
                         "y":-706.667
                        }, 
                        {
                         "x":-132,
                         "y":-649.333
                        }, 
                        {
                         "x":-60,
                         "y":-608
                        }, 
                        {
                         "x":-20,
                         "y":-560
                        }, 
                        {
                         "x":56,
                         "y":-520
                        }, 
                        {
                         "x":90.6667,
                         "y":-468
                        }, 
                        {
                         "x":145.333,
                         "y":-390.667
                        }, 
                        {
                         "x":166.667,
                         "y":-326.667
                        }, 
                        {
                         "x":145.333,
                         "y":-289.333
                        }, 
                        {
                         "x":86.6667,
                         "y":-272
                        }, 
                        {
                         "x":53.3333,
                         "y":-280
                        }, 
                        {
                         "x":4,
                         "y":-261.333
                        },
                    
                        {
                         "x":-29.3333,
                         "y":-229.333
                        }, 
                        {
                         "x":-42.6667,
                         "y":-200
                        }, 
                        {
                         "x":-72,
                         "y":-153.333
                        }, 
                        {
                         "x":-80,
                         "y":-106.667
                        }, 
                        {
                         "x":-36,
                         "y":-74.6667
                        }, 
                        {
                         "x":-2.66667,
                         "y":-48
                        }, 
                        {
                         "x":21.3333,
                         "y":-36
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2926.67,
                 "y":4309.33
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":185,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":77.3333,
                         "y":33.3333
                        }, 
                        {
                         "x":180,
                         "y":92
                        }, 
                        {
                         "x":228,
                         "y":150.667
                        }, 
                        {
                         "x":253.333,
                         "y":228
                        }, 
                        {
                         "x":266.667,
                         "y":269.333
                        }, 
                        {
                         "x":301.333,
                         "y":316
                        }, 
                        {
                         "x":373.333,
                         "y":353.333
                        }, 
                        {
                         "x":438.667,
                         "y":377.333
                        }, 
                        {
                         "x":513.333,
                         "y":386.667
                        }, 
                        {
                         "x":598.667,
                         "y":373.333
                        }, 
                        {
                         "x":676,
                         "y":313.333
                        }, 
                        {
                         "x":728,
                         "y":272
                        }, 
                        {
                         "x":774.667,
                         "y":196
                        }, 
                        {
                         "x":822.667,
                         "y":148
                        }, 
                        {
                         "x":909.333,
                         "y":118.667
                        },
                    
                        {
                         "x":989.333,
                         "y":118.667
                        }, 
                        {
                         "x":1096,
                         "y":157.333
                        }, 
                        {
                         "x":1161.33,
                         "y":182.667
                        }, 
                        {
                         "x":1230.67,
                         "y":164
                        }, 
                        {
                         "x":1265.33,
                         "y":116
                        }, 
                        {
                         "x":1310.67,
                         "y":64
                        }, 
                        {
                         "x":1408,
                         "y":36
                        }, 
                        {
                         "x":1550.67,
                         "y":34.6667
                        }, 
                        {
                         "x":1586.67,
                         "y":-50.6667
                        }, 
                        {
                         "x":1564,
                         "y":-102.667
                        }, 
                        {
                         "x":1517.33,
                         "y":-113.333
                        }, 
                        {
                         "x":1430.67,
                         "y":-101.333
                        }, 
                        {
                         "x":1373.33,
                         "y":-76
                        }, 
                        {
                         "x":1298.67,
                         "y":-89.3333
                        }, 
                        {
                         "x":1266.67,
                         "y":-61.3333
                        }, 
                        {
                         "x":1324,
                         "y":-38.6667
                        },
                    
                        {
                         "x":1426.67,
                         "y":-33.3333
                        }, 
                        {
                         "x":1500,
                         "y":-60
                        }, 
                        {
                         "x":1534.67,
                         "y":-65.3333
                        }, 
                        {
                         "x":1533.33,
                         "y":-28
                        }, 
                        {
                         "x":1480,
                         "y":8
                        }, 
                        {
                         "x":1370.67,
                         "y":14.6667
                        }, 
                        {
                         "x":1302.67,
                         "y":22.6667
                        }, 
                        {
                         "x":1257.33,
                         "y":41.3333
                        }, 
                        {
                         "x":1197.33,
                         "y":124
                        }, 
                        {
                         "x":1121.33,
                         "y":121.333
                        }, 
                        {
                         "x":1057.33,
                         "y":98.6667
                        }, 
                        {
                         "x":992,
                         "y":76
                        }, 
                        {
                         "x":941.333,
                         "y":68
                        }, 
                        {
                         "x":830.667,
                         "y":130.667
                        }, 
                        {
                         "x":772,
                         "y":149.333
                        }, 
                        {
                         "x":716,
                         "y":198.667
                        },
                    
                        {
                         "x":684,
                         "y":232
                        }, 
                        {
                         "x":638.667,
                         "y":274.667
                        }, 
                        {
                         "x":592,
                         "y":304
                        }, 
                        {
                         "x":522.667,
                         "y":338.667
                        }, 
                        {
                         "x":421.333,
                         "y":317.333
                        }, 
                        {
                         "x":342.667,
                         "y":285.333
                        }, 
                        {
                         "x":300,
                         "y":233.333
                        }, 
                        {
                         "x":297.333,
                         "y":160
                        }, 
                        {
                         "x":257.333,
                         "y":102.667
                        }, 
                        {
                         "x":186.667,
                         "y":48
                        }, 
                        {
                         "x":122.667,
                         "y":2.66667
                        }, 
                        {
                         "x":56,
                         "y":-36
                        }, 
                        {
                         "x":17.3333,
                         "y":-56
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3038.67,
                 "y":4018.67
                }],
         "opacity":1,
         "type":"objectgroup",
         "visible":false,
         "x":0,
         "y":0
        }, 
        {
         "color":"#0000ff",
         "draworder":"topdown",
         "id":5,
         "name":"pickup-points",
         "objects":[
                {
                 "class":"",
                 "height":0,
                 "id":21,
                 "name":"Bloodweiser maker",
                 "point":true,
                 "properties":[
                        {
                         "name":"description",
                         "type":"string",
                         "value":"Come, pick it and bring it!"
                        }, 
                        {
                         "name":"maxCapacity",
                         "type":"int",
                         "value":20
                        }, 
                        {
                         "name":"produces",
                         "type":"object",
                         "value":43
                        }, 
                        {
                         "name":"productionCycleTime",
                         "type":"float",
                         "value":30
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4038,
                 "y":2697
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":215,
                 "name":"Wherewolf mee(a)ter",
                 "point":true,
                 "properties":[
                        {
                         "name":"description",
                         "type":"string",
                         "value":"We have the best steak, ty it!"
                        }, 
                        {
                         "name":"maxCapacity",
                         "type":"int",
                         "value":20
                        }, 
                        {
                         "name":"produces",
                         "type":"object",
                         "value":188
                        }, 
                        {
                         "name":"productionCycleTime",
                         "type":"float",
                         "value":30
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4764,
                 "y":2282
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":216,
                 "name":"Hansel's house",
                 "point":true,
                 "properties":[
                        {
                         "name":"description",
                         "type":"string",
                         "value":"Welcome, I'm Hansel, can i help you?"
                        }, 
                        {
                         "name":"maxCapacity",
                         "type":"int",
                         "value":20
                        }, 
                        {
                         "name":"produces",
                         "type":"object",
                         "value":190
                        }, 
                        {
                         "name":"productionCycleTime",
                         "type":"float",
                         "value":30
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":6092.85,
                 "y":2691.64
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":217,
                 "name":"Gretel's Home",
                 "point":true,
                 "properties":[
                        {
                         "name":"description",
                         "type":"string",
                         "value":"I'm new here, can you show me this area?"
                        }, 
                        {
                         "name":"maxCapacity",
                         "type":"int",
                         "value":20
                        }, 
                        {
                         "name":"produces",
                         "type":"object",
                         "value":189
                        }, 
                        {
                         "name":"productionCycleTime",
                         "type":"float",
                         "value":30
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3131.33,
                 "y":5016
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":302,
                 "name":"Meat palace",
                 "point":true,
                 "properties":[
                        {
                         "name":"description",
                         "type":"string",
                         "value":"Only rare steak!"
                        }, 
                        {
                         "name":"maxCapacity",
                         "type":"int",
                         "value":20
                        }, 
                        {
                         "name":"produces",
                         "type":"object",
                         "value":188
                        }, 
                        {
                         "name":"productionCycleTime",
                         "type":"float",
                         "value":30
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3010,
                 "y":4224
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":303,
                 "name":"Wherewolf mee(a)ter",
                 "point":true,
                 "properties":[
                        {
                         "name":"description",
                         "type":"string",
                         "value":"Get steak from here"
                        }, 
                        {
                         "name":"maxCapacity",
                         "type":"int",
                         "value":20
                        }, 
                        {
                         "name":"produces",
                         "type":"object",
                         "value":188
                        }, 
                        {
                         "name":"productionCycleTime",
                         "type":"float",
                         "value":30
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":6806.06,
                 "y":1972.73
                }],
         "opacity":1,
         "type":"objectgroup",
         "visible":true,
         "x":0,
         "y":0
        }, 
        {
         "color":"#ff0000",
         "draworder":"topdown",
         "id":6,
         "name":"drop-points",
         "objects":[
                {
                 "class":"",
                 "height":0,
                 "id":22,
                 "name":"Vampire castle",
                 "point":true,
                 "properties":[
                        {
                         "name":"consumes",
                         "type":"object",
                         "value":43
                        }, 
                        {
                         "name":"description",
                         "type":"string",
                         "value":"We need some Bloodweiser!"
                        }, 
                        {
                         "name":"hungerCycleTime",
                         "type":"float",
                         "value":30
                        }, 
                        {
                         "name":"image",
                         "type":"file",
                         "value":"forklift.png"
                        }, 
                        {
                         "name":"maxHunger",
                         "type":"float",
                         "value":10
                        }, 
                        {
                         "name":"spawnArea",
                         "type":"object",
                         "value":315
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3496.33,
                 "y":1780
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":218,
                 "name":"Wherewolfcave",
                 "point":true,
                 "properties":[
                        {
                         "name":"consumes",
                         "type":"object",
                         "value":188
                        }, 
                        {
                         "name":"description",
                         "type":"string",
                         "value":"We need meat, or your bones!"
                        }, 
                        {
                         "name":"hungerCycleTime",
                         "type":"float",
                         "value":30
                        }, 
                        {
                         "name":"image",
                         "type":"file",
                         "value":"forklift.png"
                        }, 
                        {
                         "name":"maxHunger",
                         "type":"float",
                         "value":10
                        }, 
                        {
                         "name":"spawnArea",
                         "type":"object",
                         "value":320
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3580,
                 "y":1190
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":219,
                 "name":"Witchranch",
                 "point":true,
                 "properties":[
                        {
                         "name":"consumes",
                         "type":"object",
                         "value":190
                        }, 
                        {
                         "name":"description",
                         "type":"string",
                         "value":"Hansel, pls bring Hansel with you!"
                        }, 
                        {
                         "name":"hungerCycleTime",
                         "type":"float",
                         "value":30
                        }, 
                        {
                         "name":"image",
                         "type":"file",
                         "value":"forklift.png"
                        }, 
                        {
                         "name":"maxHunger",
                         "type":"float",
                         "value":10
                        }, 
                        {
                         "name":"spawnArea",
                         "type":"object",
                         "value":318
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":6190,
                 "y":2240
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":220,
                 "name":"Witchranch",
                 "point":true,
                 "properties":[
                        {
                         "name":"consumes",
                         "type":"object",
                         "value":189
                        }, 
                        {
                         "name":"description",
                         "type":"string",
                         "value":"Hey, pls found Gretel!"
                        }, 
                        {
                         "name":"hungerCycleTime",
                         "type":"float",
                         "value":30
                        }, 
                        {
                         "name":"image",
                         "type":"file",
                         "value":"forklift.png"
                        }, 
                        {
                         "name":"maxHunger",
                         "type":"float",
                         "value":10
                        }, 
                        {
                         "name":"spawnArea",
                         "type":"object",
                         "value":317
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4328,
                 "y":3934
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":301,
                 "name":"Wolfy's home",
                 "point":true,
                 "properties":[
                        {
                         "name":"consumes",
                         "type":"object",
                         "value":188
                        }, 
                        {
                         "name":"description",
                         "type":"string",
                         "value":"I'm a vegetarian wolf. Just kidding :)"
                        }, 
                        {
                         "name":"hungerCycleTime",
                         "type":"float",
                         "value":30
                        }, 
                        {
                         "name":"image",
                         "type":"file",
                         "value":"forklift.png"
                        }, 
                        {
                         "name":"maxHunger",
                         "type":"float",
                         "value":10
                        }, 
                        {
                         "name":"spawnArea",
                         "type":"object",
                         "value":321
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":1866,
                 "y":4844
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":304,
                 "name":"Wolfy's cave",
                 "point":true,
                 "properties":[
                        {
                         "name":"consumes",
                         "type":"object",
                         "value":188
                        }, 
                        {
                         "name":"description",
                         "type":"string",
                         "value":"It's mooooooonday!"
                        }, 
                        {
                         "name":"hungerCycleTime",
                         "type":"float",
                         "value":30
                        }, 
                        {
                         "name":"image",
                         "type":"file",
                         "value":"forklift.png"
                        }, 
                        {
                         "name":"maxHunger",
                         "type":"float",
                         "value":10
                        }, 
                        {
                         "name":"spawnArea",
                         "type":"object",
                         "value":319
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":7003.03,
                 "y":1184.85
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":305,
                 "name":"Society of Bloodweiser addict",
                 "point":true,
                 "properties":[
                        {
                         "name":"consumes",
                         "type":"object",
                         "value":43
                        }, 
                        {
                         "name":"description",
                         "type":"string",
                         "value":"We need more Bloodweiser!"
                        }, 
                        {
                         "name":"hungerCycleTime",
                         "type":"float",
                         "value":30
                        }, 
                        {
                         "name":"image",
                         "type":"file",
                         "value":"forklift.png"
                        }, 
                        {
                         "name":"maxHunger",
                         "type":"float",
                         "value":10
                        }, 
                        {
                         "name":"spawnArea",
                         "type":"object",
                         "value":316
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2827.27,
                 "y":3200
                }],
         "opacity":1,
         "type":"objectgroup",
         "visible":true,
         "x":0,
         "y":0
        }, 
        {
         "color":"#ffff00",
         "draworder":"topdown",
         "id":7,
         "name":"obstacles",
         "objects":[
                {
                 "class":"",
                 "height":0,
                 "id":54,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":192,
                         "y":-2.66667
                        }, 
                        {
                         "x":330.667,
                         "y":-92
                        }, 
                        {
                         "x":530.667,
                         "y":-64
                        }, 
                        {
                         "x":669.333,
                         "y":-74.6667
                        }, 
                        {
                         "x":702.667,
                         "y":-168
                        }, 
                        {
                         "x":702.667,
                         "y":-192
                        }, 
                        {
                         "x":608,
                         "y":-228
                        }, 
                        {
                         "x":538.667,
                         "y":-278.667
                        }, 
                        {
                         "x":345.333,
                         "y":-333.333
                        }, 
                        {
                         "x":158.667,
                         "y":-348
                        }, 
                        {
                         "x":-18.6667,
                         "y":-320
                        }, 
                        {
                         "x":-89.3333,
                         "y":-234.667
                        }, 
                        {
                         "x":-164,
                         "y":-198.667
                        }, 
                        {
                         "x":-169.333,
                         "y":-138.667
                        }, 
                        {
                         "x":-90.6667,
                         "y":-48
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2158.67,
                 "y":3793.33
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":106,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-48,
                         "y":60
                        }, 
                        {
                         "x":-92,
                         "y":58
                        }, 
                        {
                         "x":-224,
                         "y":-120
                        }, 
                        {
                         "x":-150,
                         "y":-188
                        }, 
                        {
                         "x":-62,
                         "y":-164
                        }, 
                        {
                         "x":-4,
                         "y":-138
                        }, 
                        {
                         "x":52,
                         "y":-142
                        }, 
                        {
                         "x":208,
                         "y":-186
                        }, 
                        {
                         "x":308,
                         "y":-190
                        }, 
                        {
                         "x":356,
                         "y":-164
                        }, 
                        {
                         "x":360,
                         "y":-130
                        }, 
                        {
                         "x":474,
                         "y":-60
                        }, 
                        {
                         "x":522,
                         "y":-36
                        }, 
                        {
                         "x":670,
                         "y":-78
                        }, 
                        {
                         "x":742,
                         "y":-196
                        },
                    
                        {
                         "x":788,
                         "y":-232
                        }, 
                        {
                         "x":940,
                         "y":-180
                        }, 
                        {
                         "x":1086,
                         "y":-132
                        }, 
                        {
                         "x":1212,
                         "y":-104
                        }, 
                        {
                         "x":1232,
                         "y":-58
                        }, 
                        {
                         "x":1144,
                         "y":40
                        }, 
                        {
                         "x":1002,
                         "y":66
                        }, 
                        {
                         "x":886,
                         "y":186
                        }, 
                        {
                         "x":576,
                         "y":200
                        }, 
                        {
                         "x":516,
                         "y":152
                        }, 
                        {
                         "x":454,
                         "y":166
                        }, 
                        {
                         "x":366,
                         "y":188
                        }, 
                        {
                         "x":288,
                         "y":144
                        }, 
                        {
                         "x":260,
                         "y":160
                        }, 
                        {
                         "x":230,
                         "y":138
                        }, 
                        {
                         "x":198,
                         "y":138
                        },
                    
                        {
                         "x":208,
                         "y":54
                        }, 
                        {
                         "x":186,
                         "y":12
                        }, 
                        {
                         "x":132,
                         "y":-32
                        }, 
                        {
                         "x":94,
                         "y":-46
                        }, 
                        {
                         "x":58,
                         "y":-34
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":1755.7,
                 "y":4539.94
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":107,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-90,
                         "y":16
                        }, 
                        {
                         "x":-128,
                         "y":8
                        }, 
                        {
                         "x":-194,
                         "y":50
                        }, 
                        {
                         "x":-248,
                         "y":138
                        }, 
                        {
                         "x":-232,
                         "y":202
                        }, 
                        {
                         "x":-238,
                         "y":290
                        }, 
                        {
                         "x":-376,
                         "y":558
                        }, 
                        {
                         "x":-290,
                         "y":566
                        }, 
                        {
                         "x":-180,
                         "y":412
                        }, 
                        {
                         "x":-120,
                         "y":546
                        }, 
                        {
                         "x":-72,
                         "y":556
                        }, 
                        {
                         "x":-2,
                         "y":506
                        }, 
                        {
                         "x":30,
                         "y":474
                        }, 
                        {
                         "x":74,
                         "y":592
                        }, 
                        {
                         "x":160,
                         "y":646
                        },
                    
                        {
                         "x":168,
                         "y":738
                        }, 
                        {
                         "x":184,
                         "y":792
                        }, 
                        {
                         "x":160,
                         "y":804
                        }, 
                        {
                         "x":82,
                         "y":770
                        }, 
                        {
                         "x":-60,
                         "y":800
                        }, 
                        {
                         "x":-96,
                         "y":834
                        }, 
                        {
                         "x":-136,
                         "y":836
                        }, 
                        {
                         "x":-162,
                         "y":812
                        }, 
                        {
                         "x":-266,
                         "y":772
                        }, 
                        {
                         "x":-328,
                         "y":774
                        }, 
                        {
                         "x":-398,
                         "y":818
                        }, 
                        {
                         "x":-440,
                         "y":846
                        }, 
                        {
                         "x":-422,
                         "y":872
                        }, 
                        {
                         "x":-288,
                         "y":838
                        }, 
                        {
                         "x":-114,
                         "y":882
                        }, 
                        {
                         "x":22,
                         "y":852
                        },
                    
                        {
                         "x":104,
                         "y":838
                        }, 
                        {
                         "x":206,
                         "y":902
                        }, 
                        {
                         "x":374,
                         "y":868
                        }, 
                        {
                         "x":514,
                         "y":900
                        }, 
                        {
                         "x":590,
                         "y":826
                        }, 
                        {
                         "x":516,
                         "y":698
                        }, 
                        {
                         "x":482,
                         "y":604
                        }, 
                        {
                         "x":450,
                         "y":560
                        }, 
                        {
                         "x":354,
                         "y":548
                        }, 
                        {
                         "x":302,
                         "y":540
                        }, 
                        {
                         "x":270,
                         "y":436
                        }, 
                        {
                         "x":74,
                         "y":324
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2765.7,
                 "y":4827.94
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":108,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":2,
                         "y":58
                        }, 
                        {
                         "x":-30,
                         "y":62
                        }, 
                        {
                         "x":-106,
                         "y":184
                        }, 
                        {
                         "x":-150,
                         "y":134
                        }, 
                        {
                         "x":-106,
                         "y":22
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2305.7,
                 "y":5501.94
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":109,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":2,
                         "y":74
                        }, 
                        {
                         "x":118,
                         "y":172
                        }, 
                        {
                         "x":204,
                         "y":118
                        }, 
                        {
                         "x":276,
                         "y":148
                        }, 
                        {
                         "x":638,
                         "y":150
                        }, 
                        {
                         "x":806,
                         "y":36
                        }, 
                        {
                         "x":822,
                         "y":-34
                        }, 
                        {
                         "x":754,
                         "y":-96
                        }, 
                        {
                         "x":716,
                         "y":-124
                        }, 
                        {
                         "x":708,
                         "y":-198
                        }, 
                        {
                         "x":670,
                         "y":-102
                        }, 
                        {
                         "x":530,
                         "y":-104
                        }, 
                        {
                         "x":490,
                         "y":-90
                        }, 
                        {
                         "x":410,
                         "y":-90
                        }, 
                        {
                         "x":138,
                         "y":-68
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2869.7,
                 "y":5893.94
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":110,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-62,
                         "y":146
                        }, 
                        {
                         "x":-30,
                         "y":266
                        }, 
                        {
                         "x":-4,
                         "y":272
                        }, 
                        {
                         "x":-2,
                         "y":194
                        }, 
                        {
                         "x":116,
                         "y":154
                        }, 
                        {
                         "x":122,
                         "y":86
                        }, 
                        {
                         "x":148,
                         "y":52
                        }, 
                        {
                         "x":52,
                         "y":-12
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3437.7,
                 "y":5445.94
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":111,
                 "name":"",
                 "polygon":[
                        {
                         "x":100,
                         "y":-128
                        }, 
                        {
                         "x":0,
                         "y":-36
                        }, 
                        {
                         "x":44,
                         "y":98
                        }, 
                        {
                         "x":192,
                         "y":184
                        }, 
                        {
                         "x":226,
                         "y":196
                        }, 
                        {
                         "x":316,
                         "y":242
                        }, 
                        {
                         "x":346,
                         "y":310
                        }, 
                        {
                         "x":404,
                         "y":368
                        }, 
                        {
                         "x":494,
                         "y":408
                        }, 
                        {
                         "x":644,
                         "y":490
                        }, 
                        {
                         "x":708,
                         "y":494
                        }, 
                        {
                         "x":730,
                         "y":384
                        }, 
                        {
                         "x":776,
                         "y":378
                        }, 
                        {
                         "x":754,
                         "y":274
                        }, 
                        {
                         "x":636,
                         "y":240
                        }, 
                        {
                         "x":600,
                         "y":224
                        },
                    
                        {
                         "x":652,
                         "y":212
                        }, 
                        {
                         "x":748,
                         "y":182
                        }, 
                        {
                         "x":720,
                         "y":164
                        }, 
                        {
                         "x":686,
                         "y":142
                        }, 
                        {
                         "x":664,
                         "y":154
                        }, 
                        {
                         "x":594,
                         "y":138
                        }, 
                        {
                         "x":564,
                         "y":122
                        }, 
                        {
                         "x":540,
                         "y":70
                        }, 
                        {
                         "x":492,
                         "y":2
                        }, 
                        {
                         "x":480,
                         "y":-112
                        }, 
                        {
                         "x":410,
                         "y":-170
                        }, 
                        {
                         "x":350,
                         "y":-148
                        }, 
                        {
                         "x":288,
                         "y":-214
                        }, 
                        {
                         "x":214,
                         "y":-238
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3309.7,
                 "y":5127.94
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":112,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":72,
                         "y":-64
                        }, 
                        {
                         "x":102,
                         "y":-54
                        }, 
                        {
                         "x":160,
                         "y":-72
                        }, 
                        {
                         "x":148,
                         "y":-32
                        }, 
                        {
                         "x":118,
                         "y":30
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4221.7,
                 "y":5399.94
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":113,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":58,
                         "y":14
                        }, 
                        {
                         "x":126,
                         "y":26
                        }, 
                        {
                         "x":144,
                         "y":26
                        }, 
                        {
                         "x":120,
                         "y":60
                        }, 
                        {
                         "x":176,
                         "y":106
                        }, 
                        {
                         "x":338,
                         "y":90
                        }, 
                        {
                         "x":304,
                         "y":26
                        }, 
                        {
                         "x":354,
                         "y":-2
                        }, 
                        {
                         "x":472,
                         "y":-4
                        }, 
                        {
                         "x":512,
                         "y":8
                        }, 
                        {
                         "x":472,
                         "y":138
                        }, 
                        {
                         "x":544,
                         "y":146
                        }, 
                        {
                         "x":612,
                         "y":154
                        }, 
                        {
                         "x":688,
                         "y":170
                        }, 
                        {
                         "x":732,
                         "y":218
                        },
                    
                        {
                         "x":692,
                         "y":368
                        }, 
                        {
                         "x":732,
                         "y":408
                        }, 
                        {
                         "x":884,
                         "y":368
                        }, 
                        {
                         "x":1018,
                         "y":378
                        }, 
                        {
                         "x":996,
                         "y":106
                        }, 
                        {
                         "x":932,
                         "y":128
                        }, 
                        {
                         "x":902,
                         "y":-48
                        }, 
                        {
                         "x":892,
                         "y":-126
                        }, 
                        {
                         "x":714,
                         "y":-202
                        }, 
                        {
                         "x":568,
                         "y":-172
                        }, 
                        {
                         "x":546,
                         "y":-96
                        }, 
                        {
                         "x":514,
                         "y":-206
                        }, 
                        {
                         "x":466,
                         "y":-170
                        }, 
                        {
                         "x":462,
                         "y":-76
                        }, 
                        {
                         "x":432,
                         "y":-236
                        }, 
                        {
                         "x":400,
                         "y":-318
                        },
                    
                        {
                         "x":324,
                         "y":-360
                        }, 
                        {
                         "x":284,
                         "y":-302
                        }, 
                        {
                         "x":242,
                         "y":-112
                        }, 
                        {
                         "x":128,
                         "y":-198
                        }, 
                        {
                         "x":60,
                         "y":-196
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3805.7,
                 "y":5049.94
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":114,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":114,
                         "y":2
                        }, 
                        {
                         "x":42,
                         "y":62
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3735.7,
                 "y":5007.94
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":115,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-108,
                         "y":0
                        }, 
                        {
                         "x":-140,
                         "y":-108
                        }, 
                        {
                         "x":-106,
                         "y":-122
                        }, 
                        {
                         "x":-108,
                         "y":-196
                        }, 
                        {
                         "x":-80,
                         "y":-312
                        }, 
                        {
                         "x":14,
                         "y":-204
                        }, 
                        {
                         "x":144,
                         "y":-302
                        }, 
                        {
                         "x":134,
                         "y":-180
                        }, 
                        {
                         "x":174,
                         "y":-226
                        }, 
                        {
                         "x":160,
                         "y":-72
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3535.7,
                 "y":4811.94
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":116,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":56,
                         "y":-10
                        }, 
                        {
                         "x":100,
                         "y":-30
                        }, 
                        {
                         "x":190,
                         "y":-32
                        }, 
                        {
                         "x":278,
                         "y":-32
                        }, 
                        {
                         "x":328,
                         "y":-6
                        }, 
                        {
                         "x":362,
                         "y":48
                        }, 
                        {
                         "x":282,
                         "y":100
                        }, 
                        {
                         "x":328,
                         "y":114
                        }, 
                        {
                         "x":304,
                         "y":140
                        }, 
                        {
                         "x":256,
                         "y":172
                        }, 
                        {
                         "x":174,
                         "y":176
                        }, 
                        {
                         "x":78,
                         "y":154
                        }, 
                        {
                         "x":6,
                         "y":114
                        }, 
                        {
                         "x":-38,
                         "y":78
                        }, 
                        {
                         "x":-42,
                         "y":110
                        },
                    
                        {
                         "x":-6,
                         "y":178
                        }, 
                        {
                         "x":78,
                         "y":214
                        }, 
                        {
                         "x":176,
                         "y":218
                        }, 
                        {
                         "x":276,
                         "y":218
                        }, 
                        {
                         "x":360,
                         "y":166
                        }, 
                        {
                         "x":420,
                         "y":150
                        }, 
                        {
                         "x":476,
                         "y":-24
                        }, 
                        {
                         "x":376,
                         "y":-122
                        }, 
                        {
                         "x":338,
                         "y":-102
                        }, 
                        {
                         "x":308,
                         "y":-118
                        }, 
                        {
                         "x":318,
                         "y":-230
                        }, 
                        {
                         "x":244,
                         "y":-228
                        }, 
                        {
                         "x":132,
                         "y":-96
                        }, 
                        {
                         "x":12,
                         "y":-18
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2913.7,
                 "y":4717.94
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":117,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-34,
                         "y":50
                        }, 
                        {
                         "x":-60,
                         "y":42
                        }, 
                        {
                         "x":-112,
                         "y":106
                        }, 
                        {
                         "x":-128,
                         "y":156
                        }, 
                        {
                         "x":-110,
                         "y":212
                        }, 
                        {
                         "x":-60,
                         "y":256
                        }, 
                        {
                         "x":10,
                         "y":294
                        }, 
                        {
                         "x":96,
                         "y":394
                        }, 
                        {
                         "x":130,
                         "y":454
                        }, 
                        {
                         "x":160,
                         "y":372
                        }, 
                        {
                         "x":140,
                         "y":308
                        }, 
                        {
                         "x":60,
                         "y":286
                        }, 
                        {
                         "x":-2,
                         "y":258
                        }, 
                        {
                         "x":-70,
                         "y":188
                        }, 
                        {
                         "x":-86,
                         "y":162
                        },
                    
                        {
                         "x":-78,
                         "y":114
                        }, 
                        {
                         "x":-28,
                         "y":68
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3049.7,
                 "y":4053.94
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":118,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-22,
                         "y":-118
                        }, 
                        {
                         "x":-84,
                         "y":-182
                        }, 
                        {
                         "x":-138,
                         "y":-242
                        }, 
                        {
                         "x":-118,
                         "y":-320
                        }, 
                        {
                         "x":-192,
                         "y":-346
                        }, 
                        {
                         "x":-340,
                         "y":-340
                        }, 
                        {
                         "x":-398,
                         "y":-324
                        }, 
                        {
                         "x":-340,
                         "y":-258
                        }, 
                        {
                         "x":-422,
                         "y":-140
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2955.7,
                 "y":4465.94
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":119,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-40,
                         "y":-105.333
                        }, 
                        {
                         "x":-28,
                         "y":-164
                        }, 
                        {
                         "x":-65.3333,
                         "y":-206.667
                        }, 
                        {
                         "x":-65.3333,
                         "y":-228
                        }, 
                        {
                         "x":-13.3333,
                         "y":-288
                        }, 
                        {
                         "x":25.3333,
                         "y":-322.667
                        }, 
                        {
                         "x":-57.3333,
                         "y":-366.667
                        }, 
                        {
                         "x":-82.6667,
                         "y":-329.333
                        }, 
                        {
                         "x":-148,
                         "y":-308
                        }, 
                        {
                         "x":-182.667,
                         "y":-316
                        }, 
                        {
                         "x":-229.333,
                         "y":-294.667
                        }, 
                        {
                         "x":-304,
                         "y":-288
                        }, 
                        {
                         "x":-408,
                         "y":-290.667
                        }, 
                        {
                         "x":-480,
                         "y":-260
                        }, 
                        {
                         "x":-689.333,
                         "y":-141.333
                        },
                    
                        {
                         "x":-685.333,
                         "y":-125.333
                        }, 
                        {
                         "x":-584,
                         "y":-172
                        }, 
                        {
                         "x":-510.667,
                         "y":-158.667
                        }, 
                        {
                         "x":-372,
                         "y":-160
                        }, 
                        {
                         "x":-242.667,
                         "y":-193.333
                        }, 
                        {
                         "x":-156,
                         "y":-160
                        }, 
                        {
                         "x":-58.6667,
                         "y":-66.6667
                        }, 
                        {
                         "x":-20,
                         "y":9.33333
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2624.36,
                 "y":4133.94
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":120,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":64,
                         "y":96
                        }, 
                        {
                         "x":152,
                         "y":36
                        }, 
                        {
                         "x":150.667,
                         "y":-9.33333
                        }, 
                        {
                         "x":185.333,
                         "y":-10.6667
                        }, 
                        {
                         "x":250.667,
                         "y":24
                        }, 
                        {
                         "x":284,
                         "y":10.6667
                        }, 
                        {
                         "x":380,
                         "y":72
                        }, 
                        {
                         "x":412,
                         "y":72
                        }, 
                        {
                         "x":442.667,
                         "y":97.3333
                        }, 
                        {
                         "x":505.333,
                         "y":90.6667
                        }, 
                        {
                         "x":581.333,
                         "y":29.3333
                        }, 
                        {
                         "x":617.333,
                         "y":-38.6667
                        }, 
                        {
                         "x":672,
                         "y":-86.6667
                        }, 
                        {
                         "x":765.333,
                         "y":-104
                        }, 
                        {
                         "x":870.667,
                         "y":-77.3333
                        },
                    
                        {
                         "x":929.333,
                         "y":-46.6667
                        }, 
                        {
                         "x":1041.33,
                         "y":-72
                        }, 
                        {
                         "x":1113.33,
                         "y":-153.333
                        }, 
                        {
                         "x":1090.67,
                         "y":-210.667
                        }, 
                        {
                         "x":1112,
                         "y":-238.667
                        }, 
                        {
                         "x":1076,
                         "y":-322.667
                        }, 
                        {
                         "x":1020,
                         "y":-329.333
                        }, 
                        {
                         "x":978.667,
                         "y":-329.333
                        }, 
                        {
                         "x":944,
                         "y":-350.667
                        }, 
                        {
                         "x":912,
                         "y":-342.667
                        }, 
                        {
                         "x":882.667,
                         "y":-362.667
                        }, 
                        {
                         "x":861.333,
                         "y":-418.667
                        }, 
                        {
                         "x":844,
                         "y":-358.667
                        }, 
                        {
                         "x":837.333,
                         "y":-325.333
                        }, 
                        {
                         "x":862.667,
                         "y":-280
                        }, 
                        {
                         "x":892,
                         "y":-249.333
                        },
                    
                        {
                         "x":894.667,
                         "y":-234.667
                        }, 
                        {
                         "x":884,
                         "y":-226.667
                        }, 
                        {
                         "x":840,
                         "y":-257.333
                        }, 
                        {
                         "x":808,
                         "y":-229.333
                        }, 
                        {
                         "x":776,
                         "y":-236
                        }, 
                        {
                         "x":729.333,
                         "y":-224
                        }, 
                        {
                         "x":692,
                         "y":-213.333
                        }, 
                        {
                         "x":626.667,
                         "y":-218.667
                        }, 
                        {
                         "x":602.667,
                         "y":-186.667
                        }, 
                        {
                         "x":574.667,
                         "y":-192
                        }, 
                        {
                         "x":524,
                         "y":-164
                        }, 
                        {
                         "x":450.667,
                         "y":-82.6667
                        }, 
                        {
                         "x":442.667,
                         "y":-69.3333
                        }, 
                        {
                         "x":417.333,
                         "y":-76
                        }, 
                        {
                         "x":372,
                         "y":-92
                        }, 
                        {
                         "x":221.333,
                         "y":-156
                        },
                    
                        {
                         "x":170.667,
                         "y":-148
                        }, 
                        {
                         "x":146.667,
                         "y":-129.333
                        }, 
                        {
                         "x":121.333,
                         "y":-132
                        }, 
                        {
                         "x":66.6667,
                         "y":-100
                        }, 
                        {
                         "x":29.3333,
                         "y":-48
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3761.7,
                 "y":4097.94
                },
            
                {
                 "class":"",
                 "height":0,
                 "id":121,
                 "name":"",
                 "polygon":[
                        {
                         "x":14.6667,
                         "y":48
                        }, 
                        {
                         "x":106.667,
                         "y":12
                        }, 
                        {
                         "x":149.333,
                         "y":28
                        }, 
                        {
                         "x":182.667,
                         "y":16
                        }, 
                        {
                         "x":196,
                         "y":22.6667
                        }, 
                        {
                         "x":257.333,
                         "y":-30.6667
                        }, 
                        {
                         "x":297.333,
                         "y":-76
                        }, 
                        {
                         "x":377.333,
                         "y":-112
                        }, 
                        {
                         "x":417.333,
                         "y":-122.667
                        }, 
                        {
                         "x":454.667,
                         "y":-166.667
                        }, 
                        {
                         "x":522.667,
                         "y":-180
                        }, 
                        {
                         "x":562.667,
                         "y":-154.667
                        }, 
                        {
                         "x":633.333,
                         "y":-160
                        }, 
                        {
                         "x":658.667,
                         "y":-125.333
                        }, 
                        {
                         "x":693.333,
                         "y":-117.333
                        }, 
                        {
                         "x":717.333,
                         "y":-134.667
                        },
                    
                        {
                         "x":744,
                         "y":-106.667
                        }, 
                        {
                         "x":793.333,
                         "y":-158.667
                        }, 
                        {
                         "x":804,
                         "y":-200
                        }, 
                        {
                         "x":866.667,
                         "y":-217.333
                        }, 
                        {
                         "x":946.667,
                         "y":-220
                        }, 
                        {
                         "x":1002.67,
                         "y":-224
                        }, 
                        {
                         "x":1054.67,
                         "y":-236
                        }, 
                        {
                         "x":1073.33,
                         "y":-262.667
                        }, 
                        {
                         "x":954.667,
                         "y":-248
                        }, 
                        {
                         "x":928,
                         "y":-240
                        }, 
                        {
                         "x":877.333,
                         "y":-237.333
                        }, 
                        {
                         "x":860,
                         "y":-253.333
                        }, 
                        {
                         "x":796,
                         "y":-254.667
                        }, 
                        {
                         "x":758.667,
                         "y":-257.333
                        }, 
                        {
                         "x":725.333,
                         "y":-272
                        }, 
                        {
                         "x":698.667,
                         "y":-297.333
                        },
                    
                        {
                         "x":706.667,
                         "y":-329.333
                        }, 
                        {
                         "x":724,
                         "y":-346.667
                        }, 
                        {
                         "x":660,
                         "y":-389.333
                        }, 
                        {
                         "x":661.333,
                         "y":-420
                        }, 
                        {
                         "x":626.667,
                         "y":-430.667
                        }, 
                        {
                         "x":566.667,
                         "y":-433.333
                        }, 
                        {
                         "x":517.333,
                         "y":-393.333
                        }, 
                        {
                         "x":482.667,
                         "y":-382.667
                        }, 
                        {
                         "x":433.333,
                         "y":-365.333
                        }, 
                        {
                         "x":404,
                         "y":-380
                        }, 
                        {
                         "x":386.667,
                         "y":-357.333
                        }, 
                        {
                         "x":409.333,
                         "y":-325.333
                        }, 
                        {
                         "x":384,
                         "y":-304
                        }, 
                        {
                         "x":329.333,
                         "y":-294.667
                        }, 
                        {
                         "x":248,
                         "y":-292
                        }, 
                        {
                         "x":176,
                         "y":-296
                        },
                    
                        {
                         "x":140,
                         "y":-253.333
                        }, 
                        {
                         "x":94.6667,
                         "y":-244
                        }, 
                        {
                         "x":5.33333,
                         "y":-73.3333
                        }, 
                        {
                         "x":-13.3333,
                         "y":4
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3449.7,
                 "y":4043.27
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":122,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-45.3333,
                         "y":62.6667
                        }, 
                        {
                         "x":-46.6667,
                         "y":73.3333
                        }, 
                        {
                         "x":-13.3333,
                         "y":70.6667
                        }, 
                        {
                         "x":21.3333,
                         "y":92
                        }, 
                        {
                         "x":60,
                         "y":128
                        }, 
                        {
                         "x":89.3333,
                         "y":140
                        }, 
                        {
                         "x":122.667,
                         "y":185.333
                        }, 
                        {
                         "x":213.333,
                         "y":193.333
                        }, 
                        {
                         "x":273.333,
                         "y":225.333
                        }, 
                        {
                         "x":302.667,
                         "y":209.333
                        }, 
                        {
                         "x":305.333,
                         "y":149.333
                        }, 
                        {
                         "x":181.333,
                         "y":77.3333
                        }, 
                        {
                         "x":126.667,
                         "y":34.6667
                        }, 
                        {
                         "x":48,
                         "y":-6.66667
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2743.03,
                 "y":3344.61
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":123,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":16,
                         "y":54.6667
                        }, 
                        {
                         "x":81.3333,
                         "y":92
                        }, 
                        {
                         "x":157.333,
                         "y":65.3333
                        }, 
                        {
                         "x":245.333,
                         "y":41.3333
                        }, 
                        {
                         "x":290.667,
                         "y":69.3333
                        }, 
                        {
                         "x":329.333,
                         "y":-6.66667
                        }, 
                        {
                         "x":232,
                         "y":-86.6667
                        }, 
                        {
                         "x":237.333,
                         "y":-144
                        }, 
                        {
                         "x":72,
                         "y":-140
                        }, 
                        {
                         "x":-20,
                         "y":-6.66667
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2905.7,
                 "y":3165.94
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":124,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":2.66667,
                         "y":17.3333
                        }, 
                        {
                         "x":22.6667,
                         "y":10.6667
                        }, 
                        {
                         "x":24,
                         "y":-5.33333
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3099.03,
                 "y":3283.27
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":125,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-22.6667,
                         "y":57.3333
                        }, 
                        {
                         "x":-57.3333,
                         "y":101.333
                        }, 
                        {
                         "x":-90.6667,
                         "y":104
                        }, 
                        {
                         "x":-120,
                         "y":184
                        }, 
                        {
                         "x":-122.667,
                         "y":234.667
                        }, 
                        {
                         "x":-76,
                         "y":250.667
                        }, 
                        {
                         "x":-38.6667,
                         "y":300
                        }, 
                        {
                         "x":76,
                         "y":280
                        }, 
                        {
                         "x":88,
                         "y":226.667
                        }, 
                        {
                         "x":192,
                         "y":220
                        }, 
                        {
                         "x":273.333,
                         "y":164
                        }, 
                        {
                         "x":336,
                         "y":190.667
                        }, 
                        {
                         "x":454.667,
                         "y":152
                        }, 
                        {
                         "x":510.667,
                         "y":42.6667
                        }, 
                        {
                         "x":480,
                         "y":-29.3333
                        },
                    
                        {
                         "x":385.333,
                         "y":-21.3333
                        }, 
                        {
                         "x":346.667,
                         "y":5.33333
                        }, 
                        {
                         "x":281.333,
                         "y":8
                        }, 
                        {
                         "x":213.333,
                         "y":-21.3333
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3611.03,
                 "y":2687.27
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":126,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-68,
                         "y":114
                        }, 
                        {
                         "x":-34,
                         "y":164
                        }, 
                        {
                         "x":-4,
                         "y":200
                        }, 
                        {
                         "x":8,
                         "y":232
                        }, 
                        {
                         "x":40,
                         "y":224
                        }, 
                        {
                         "x":96,
                         "y":230
                        }, 
                        {
                         "x":132,
                         "y":240
                        }, 
                        {
                         "x":146,
                         "y":190
                        }, 
                        {
                         "x":162,
                         "y":182
                        }, 
                        {
                         "x":186,
                         "y":190
                        }, 
                        {
                         "x":202,
                         "y":190
                        }, 
                        {
                         "x":224,
                         "y":140
                        }, 
                        {
                         "x":334,
                         "y":106
                        }, 
                        {
                         "x":368,
                         "y":124
                        }, 
                        {
                         "x":412,
                         "y":100
                        },
                    
                        {
                         "x":420,
                         "y":44
                        }, 
                        {
                         "x":520,
                         "y":20
                        }, 
                        {
                         "x":678,
                         "y":4
                        }, 
                        {
                         "x":822,
                         "y":-8
                        }, 
                        {
                         "x":938,
                         "y":-42
                        }, 
                        {
                         "x":944,
                         "y":-102
                        }, 
                        {
                         "x":1070,
                         "y":-134
                        }, 
                        {
                         "x":994,
                         "y":-174
                        }, 
                        {
                         "x":910,
                         "y":-192
                        }, 
                        {
                         "x":836,
                         "y":-266
                        }, 
                        {
                         "x":790,
                         "y":-330
                        }, 
                        {
                         "x":690,
                         "y":-354
                        }, 
                        {
                         "x":644,
                         "y":-364
                        }, 
                        {
                         "x":594,
                         "y":-392
                        }, 
                        {
                         "x":556,
                         "y":-384
                        }, 
                        {
                         "x":486,
                         "y":-366
                        },
                    
                        {
                         "x":418,
                         "y":-276
                        }, 
                        {
                         "x":396,
                         "y":-236
                        }, 
                        {
                         "x":314,
                         "y":-228
                        }, 
                        {
                         "x":224,
                         "y":-218
                        }, 
                        {
                         "x":166,
                         "y":-182
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4619.7,
                 "y":2923.94
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":127,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":186,
                         "y":-126
                        }, 
                        {
                         "x":360,
                         "y":-176
                        }, 
                        {
                         "x":512,
                         "y":-282
                        }, 
                        {
                         "x":714,
                         "y":-416
                        }, 
                        {
                         "x":780,
                         "y":-488
                        }, 
                        {
                         "x":882,
                         "y":-474
                        }, 
                        {
                         "x":916,
                         "y":-328
                        }, 
                        {
                         "x":874,
                         "y":-204
                        }, 
                        {
                         "x":596,
                         "y":-98
                        }, 
                        {
                         "x":494,
                         "y":-22
                        }, 
                        {
                         "x":282,
                         "y":30
                        }, 
                        {
                         "x":44,
                         "y":36
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":5425.7,
                 "y":3881.94
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":128,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":52,
                         "y":-50
                        }, 
                        {
                         "x":164,
                         "y":-75.3333
                        }, 
                        {
                         "x":260,
                         "y":-70
                        }, 
                        {
                         "x":316,
                         "y":-83.3333
                        }, 
                        {
                         "x":457.333,
                         "y":-34
                        }, 
                        {
                         "x":561.333,
                         "y":-34
                        }, 
                        {
                         "x":569.333,
                         "y":-115.333
                        }, 
                        {
                         "x":688,
                         "y":-158
                        }, 
                        {
                         "x":721.333,
                         "y":-195.333
                        }, 
                        {
                         "x":729.333,
                         "y":-222
                        }, 
                        {
                         "x":680,
                         "y":-271.333
                        }, 
                        {
                         "x":681.333,
                         "y":-334
                        }, 
                        {
                         "x":616,
                         "y":-350
                        }, 
                        {
                         "x":560,
                         "y":-368.667
                        }, 
                        {
                         "x":472,
                         "y":-363.333
                        },
                    
                        {
                         "x":466.667,
                         "y":-288.667
                        }, 
                        {
                         "x":449.333,
                         "y":-243.333
                        }, 
                        {
                         "x":448,
                         "y":-167.333
                        }, 
                        {
                         "x":473.333,
                         "y":-159.333
                        }, 
                        {
                         "x":478.667,
                         "y":-143.333
                        }, 
                        {
                         "x":412,
                         "y":-142
                        }, 
                        {
                         "x":341.333,
                         "y":-152.667
                        }, 
                        {
                         "x":314.667,
                         "y":-178
                        }, 
                        {
                         "x":214.667,
                         "y":-147.333
                        }, 
                        {
                         "x":132,
                         "y":-131.333
                        }, 
                        {
                         "x":105.333,
                         "y":-154
                        }, 
                        {
                         "x":232,
                         "y":-355.333
                        }, 
                        {
                         "x":277.333,
                         "y":-520.667
                        }, 
                        {
                         "x":282.667,
                         "y":-571.333
                        }, 
                        {
                         "x":341.333,
                         "y":-619.333
                        }, 
                        {
                         "x":480,
                         "y":-628.667
                        },
                    
                        {
                         "x":565.333,
                         "y":-744.667
                        }, 
                        {
                         "x":670.667,
                         "y":-798
                        }, 
                        {
                         "x":749.333,
                         "y":-790
                        }, 
                        {
                         "x":886.667,
                         "y":-803.333
                        }, 
                        {
                         "x":982.667,
                         "y":-776.667
                        }, 
                        {
                         "x":1009.33,
                         "y":-710
                        }, 
                        {
                         "x":1041.33,
                         "y":-516.667
                        }, 
                        {
                         "x":1036,
                         "y":-428.667
                        }, 
                        {
                         "x":1013.33,
                         "y":-364.667
                        }, 
                        {
                         "x":946.667,
                         "y":-320.667
                        }, 
                        {
                         "x":1066.67,
                         "y":-192.667
                        }, 
                        {
                         "x":1074.67,
                         "y":-136.667
                        }, 
                        {
                         "x":877.333,
                         "y":22
                        }, 
                        {
                         "x":836,
                         "y":64.6667
                        }, 
                        {
                         "x":662.667,
                         "y":96.6667
                        }, 
                        {
                         "x":580,
                         "y":108.667
                        },
                    
                        {
                         "x":496,
                         "y":79.3333
                        }, 
                        {
                         "x":390.667,
                         "y":71.3333
                        }, 
                        {
                         "x":369.333,
                         "y":78
                        }, 
                        {
                         "x":316,
                         "y":51.3333
                        }, 
                        {
                         "x":266.667,
                         "y":64.6667
                        }, 
                        {
                         "x":241.333,
                         "y":64.6667
                        }, 
                        {
                         "x":218.667,
                         "y":40.6667
                        }, 
                        {
                         "x":140,
                         "y":27.3333
                        }, 
                        {
                         "x":54.6667,
                         "y":34
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":5593.7,
                 "y":2247.94
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":129,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":73.3333,
                         "y":-18.6667
                        }, 
                        {
                         "x":132,
                         "y":-16
                        }, 
                        {
                         "x":194.667,
                         "y":6.66667
                        }, 
                        {
                         "x":250.667,
                         "y":-49.3333
                        }, 
                        {
                         "x":300,
                         "y":-81.3333
                        }, 
                        {
                         "x":326.667,
                         "y":-21.3333
                        }, 
                        {
                         "x":396,
                         "y":-57.3333
                        }, 
                        {
                         "x":438.667,
                         "y":-48
                        }, 
                        {
                         "x":486.667,
                         "y":-77.3333
                        }, 
                        {
                         "x":496,
                         "y":-141.333
                        }, 
                        {
                         "x":530.667,
                         "y":-200
                        }, 
                        {
                         "x":466.667,
                         "y":-264
                        }, 
                        {
                         "x":380,
                         "y":-354.667
                        }, 
                        {
                         "x":345.333,
                         "y":-402.667
                        }, 
                        {
                         "x":258.667,
                         "y":-473.333
                        },
                    
                        {
                         "x":229.333,
                         "y":-546.667
                        }, 
                        {
                         "x":160,
                         "y":-601.333
                        }, 
                        {
                         "x":145.333,
                         "y":-689.333
                        }, 
                        {
                         "x":242.667,
                         "y":-712
                        }, 
                        {
                         "x":112,
                         "y":-857.333
                        }, 
                        {
                         "x":28,
                         "y":-1046.67
                        }, 
                        {
                         "x":-23.2727,
                         "y":-1042.91
                        }, 
                        {
                         "x":-189.939,
                         "y":-1385.33
                        }, 
                        {
                         "x":-232.364,
                         "y":-1364.12
                        }, 
                        {
                         "x":-389.939,
                         "y":-1718.67
                        }, 
                        {
                         "x":-586.909,
                         "y":-1367.15
                        }, 
                        {
                         "x":-786.909,
                         "y":-1145.94
                        }, 
                        {
                         "x":-911.152,
                         "y":-1358.06
                        }, 
                        {
                         "x":-1111.15,
                         "y":-1536.85
                        }, 
                        {
                         "x":-1174.79,
                         "y":-1536.85
                        }, 
                        {
                         "x":-1423.27,
                         "y":-1252
                        },
                    
                        {
                         "x":-1647.52,
                         "y":-945.939
                        }, 
                        {
                         "x":-1689.94,
                         "y":-1073.21
                        }, 
                        {
                         "x":-1789.94,
                         "y":-1082.3
                        }, 
                        {
                         "x":-1977.82,
                         "y":-1021.7
                        }, 
                        {
                         "x":-2108.12,
                         "y":-824.727
                        }, 
                        {
                         "x":-2259.64,
                         "y":-1015.64
                        }, 
                        {
                         "x":-2347.52,
                         "y":-827.758
                        }, 
                        {
                         "x":-2396,
                         "y":-836.848
                        }, 
                        {
                         "x":-2444.48,
                         "y":-758.061
                        }, 
                        {
                         "x":-2511.15,
                         "y":-830.788
                        }, 
                        {
                         "x":-2568.73,
                         "y":-855.03
                        }, 
                        {
                         "x":-2641.45,
                         "y":-973.212
                        }, 
                        {
                         "x":-2711.15,
                         "y":-897.455
                        }, 
                        {
                         "x":-2765.7,
                         "y":-818.667
                        }, 
                        {
                         "x":-2799.03,
                         "y":-818.667
                        }, 
                        {
                         "x":-2892.97,
                         "y":-694.424
                        },
                    
                        {
                         "x":-2996,
                         "y":-612.606
                        }, 
                        {
                         "x":-2656.61,
                         "y":-591.394
                        }, 
                        {
                         "x":-2480.85,
                         "y":-636.848
                        }, 
                        {
                         "x":-2811.15,
                         "y":-148.97
                        }, 
                        {
                         "x":-1862.67,
                         "y":-221.697
                        }, 
                        {
                         "x":-1741.45,
                         "y":-61.0909
                        }, 
                        {
                         "x":-1147.52,
                         "y":-209.576
                        }, 
                        {
                         "x":-871.758,
                         "y":-170.182
                        }, 
                        {
                         "x":-538.424,
                         "y":-212.606
                        }, 
                        {
                         "x":-420.242,
                         "y":-206.545
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":6632.36,
                 "y":1645.94
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":130,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-50,
                         "y":166
                        }, 
                        {
                         "x":-168,
                         "y":210
                        }, 
                        {
                         "x":-394,
                         "y":202
                        }, 
                        {
                         "x":-642,
                         "y":288
                        }, 
                        {
                         "x":-768,
                         "y":370
                        }, 
                        {
                         "x":-1078,
                         "y":356
                        }, 
                        {
                         "x":-1142,
                         "y":320
                        }, 
                        {
                         "x":-1360,
                         "y":404
                        }, 
                        {
                         "x":-1442,
                         "y":422
                        }, 
                        {
                         "x":-1560,
                         "y":380
                        }, 
                        {
                         "x":-1754,
                         "y":382
                        }, 
                        {
                         "x":-2012,
                         "y":356
                        }, 
                        {
                         "x":-1990,
                         "y":293
                        }, 
                        {
                         "x":-2035,
                         "y":262
                        }, 
                        {
                         "x":-2004,
                         "y":190
                        },
                    
                        {
                         "x":-2042,
                         "y":60
                        }, 
                        {
                         "x":-1330,
                         "y":-34
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":5895.7,
                 "y":1411.94
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":131,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":17.6667,
                         "y":39.6667
                        }, 
                        {
                         "x":67.6667,
                         "y":83.667
                        }, 
                        {
                         "x":111.667,
                         "y":112
                        }, 
                        {
                         "x":126.667,
                         "y":102.667
                        }, 
                        {
                         "x":140,
                         "y":65.3333
                        }, 
                        {
                         "x":165.333,
                         "y":2.66667
                        }, 
                        {
                         "x":218.667,
                         "y":33.3333
                        }, 
                        {
                         "x":245.333,
                         "y":-10.6667
                        }, 
                        {
                         "x":349.333,
                         "y":-24
                        }, 
                        {
                         "x":404,
                         "y":-65.3333
                        }, 
                        {
                         "x":428,
                         "y":-78.6667
                        }, 
                        {
                         "x":441.333,
                         "y":-20
                        }, 
                        {
                         "x":509.333,
                         "y":-25.3333
                        }, 
                        {
                         "x":556,
                         "y":16
                        }, 
                        {
                         "x":592,
                         "y":16
                        },
                    
                        {
                         "x":673.333,
                         "y":69.3333
                        }, 
                        {
                         "x":720,
                         "y":72
                        }, 
                        {
                         "x":780,
                         "y":74.6667
                        }, 
                        {
                         "x":810.667,
                         "y":90.6667
                        }, 
                        {
                         "x":872,
                         "y":-48
                        }, 
                        {
                         "x":541.333,
                         "y":-212
                        }, 
                        {
                         "x":418.667,
                         "y":-241.333
                        }, 
                        {
                         "x":273.333,
                         "y":-192
                        }, 
                        {
                         "x":156,
                         "y":-126.667
                        }, 
                        {
                         "x":64,
                         "y":-45.3333
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3489.7,
                 "y":2073.94
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":132,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-72,
                         "y":52
                        }, 
                        {
                         "x":-178,
                         "y":22
                        }, 
                        {
                         "x":-250,
                         "y":58
                        }, 
                        {
                         "x":-284,
                         "y":230
                        }, 
                        {
                         "x":-152,
                         "y":242
                        }, 
                        {
                         "x":-102,
                         "y":274
                        }, 
                        {
                         "x":-48,
                         "y":306
                        }, 
                        {
                         "x":-12,
                         "y":298
                        }, 
                        {
                         "x":-14,
                         "y":350
                        }, 
                        {
                         "x":44,
                         "y":414
                        }, 
                        {
                         "x":158,
                         "y":518
                        }, 
                        {
                         "x":484,
                         "y":46
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3695.7,
                 "y":995.939
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":133,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-70,
                         "y":86
                        }, 
                        {
                         "x":-130,
                         "y":108
                        }, 
                        {
                         "x":-180,
                         "y":152
                        }, 
                        {
                         "x":-256,
                         "y":204
                        }, 
                        {
                         "x":-322,
                         "y":230
                        }, 
                        {
                         "x":-382,
                         "y":378
                        }, 
                        {
                         "x":-484,
                         "y":392
                        }, 
                        {
                         "x":-536,
                         "y":390
                        }, 
                        {
                         "x":-566,
                         "y":284
                        }, 
                        {
                         "x":-552,
                         "y":160
                        }, 
                        {
                         "x":-460,
                         "y":130
                        }, 
                        {
                         "x":-372,
                         "y":78
                        }, 
                        {
                         "x":-236,
                         "y":24
                        }, 
                        {
                         "x":-132,
                         "y":-30
                        }, 
                        {
                         "x":-82,
                         "y":-46
                        },
                    
                        {
                         "x":-2,
                         "y":-28
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3517.7,
                 "y":853.939
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":170,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-34.6667,
                         "y":-16
                        }, 
                        {
                         "x":9.33333,
                         "y":-76
                        }, 
                        {
                         "x":-13.3333,
                         "y":-132
                        }, 
                        {
                         "x":32,
                         "y":-212
                        }, 
                        {
                         "x":86.6667,
                         "y":-276
                        }, 
                        {
                         "x":149.333,
                         "y":-304
                        }, 
                        {
                         "x":273.333,
                         "y":-317.333
                        }, 
                        {
                         "x":340,
                         "y":-317.333
                        }, 
                        {
                         "x":384,
                         "y":-350.667
                        }, 
                        {
                         "x":424,
                         "y":-370.667
                        }, 
                        {
                         "x":488,
                         "y":-362.667
                        }, 
                        {
                         "x":558.667,
                         "y":-358.667
                        }, 
                        {
                         "x":558.667,
                         "y":-337.333
                        }, 
                        {
                         "x":464,
                         "y":-313.333
                        }, 
                        {
                         "x":385.333,
                         "y":-289.333
                        },
                    
                        {
                         "x":328,
                         "y":-274.667
                        }, 
                        {
                         "x":249.333,
                         "y":-230.667
                        }, 
                        {
                         "x":192,
                         "y":-213.333
                        }, 
                        {
                         "x":133.333,
                         "y":-161.333
                        }, 
                        {
                         "x":76,
                         "y":-86.6667
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3353.7,
                 "y":2120.61
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":171,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":98.6667,
                         "y":62.6667
                        }, 
                        {
                         "x":156,
                         "y":101.333
                        }, 
                        {
                         "x":220,
                         "y":76
                        }, 
                        {
                         "x":228,
                         "y":4
                        }, 
                        {
                         "x":257.333,
                         "y":-18.6667
                        }, 
                        {
                         "x":302.667,
                         "y":-8
                        }, 
                        {
                         "x":342.667,
                         "y":-58.6667
                        }, 
                        {
                         "x":380,
                         "y":-97.3333
                        }, 
                        {
                         "x":442.667,
                         "y":-118.667
                        }, 
                        {
                         "x":478.667,
                         "y":-138.667
                        }, 
                        {
                         "x":538.667,
                         "y":-166.667
                        }, 
                        {
                         "x":600,
                         "y":-229.333
                        }, 
                        {
                         "x":660,
                         "y":-260
                        }, 
                        {
                         "x":684,
                         "y":-265.333
                        }, 
                        {
                         "x":753.333,
                         "y":-312
                        },
                    
                        {
                         "x":732,
                         "y":-420
                        }, 
                        {
                         "x":701.333,
                         "y":-456
                        }, 
                        {
                         "x":657.333,
                         "y":-456
                        }, 
                        {
                         "x":487.333,
                         "y":-445.333
                        }, 
                        {
                         "x":488,
                         "y":-446.667
                        }, 
                        {
                         "x":304,
                         "y":-426.667
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2668.36,
                 "y":2055.27
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":172,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":22.6667,
                         "y":16
                        }, 
                        {
                         "x":30.6667,
                         "y":1.33333
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3017.7,
                 "y":1492.61
                },
            
                {
                 "class":"",
                 "height":0,
                 "id":173,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":28,
                         "y":16
                        }, 
                        {
                         "x":45.3333,
                         "y":-22.6667
                        }, 
                        {
                         "x":-5.33333,
                         "y":-32
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3076.36,
                 "y":1421.94
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":174,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-156,
                         "y":78.6667
                        }, 
                        {
                         "x":-132,
                         "y":89.3333
                        }, 
                        {
                         "x":13.3333,
                         "y":8
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3199.03,
                 "y":1495.27
                }],
         "offsetx":30.303,
         "offsety":206.061,
         "opacity":1,
         "type":"objectgroup",
         "visible":false,
         "x":0,
         "y":0
        }, 
        {
         "color":"#55aaff",
         "draworder":"topdown",
         "id":13,
         "name":"water",
         "objects":[
                {
                 "class":"",
                 "height":0,
                 "id":78,
                 "name":"",
                 "polygon":[
                        {
                         "x":-90.9091,
                         "y":30.303
                        }, 
                        {
                         "x":-35.4545,
                         "y":190.667
                        }, 
                        {
                         "x":20.6667,
                         "y":253.333
                        }, 
                        {
                         "x":123.879,
                         "y":320.182
                        }, 
                        {
                         "x":207.03,
                         "y":328.909
                        }, 
                        {
                         "x":279.97,
                         "y":367.333
                        }, 
                        {
                         "x":339.909,
                         "y":411.333
                        }, 
                        {
                         "x":403,
                         "y":426
                        }, 
                        {
                         "x":533,
                         "y":457
                        }, 
                        {
                         "x":602.727,
                         "y":567.394
                        }, 
                        {
                         "x":681.909,
                         "y":644.364
                        }, 
                        {
                         "x":766,
                         "y":650
                        }, 
                        {
                         "x":892,
                         "y":622
                        }, 
                        {
                         "x":1022,
                         "y":603
                        }, 
                        {
                         "x":1130,
                         "y":617
                        }, 
                        {
                         "x":1272,
                         "y":631
                        },
                    
                        {
                         "x":1392,
                         "y":607
                        }, 
                        {
                         "x":1451,
                         "y":550
                        }, 
                        {
                         "x":1581,
                         "y":575
                        }, 
                        {
                         "x":1676,
                         "y":679
                        }, 
                        {
                         "x":1792,
                         "y":688
                        }, 
                        {
                         "x":1835,
                         "y":843
                        }, 
                        {
                         "x":-68,
                         "y":835
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2097,
                 "y":5330
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":79,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":172,
                         "y":-30
                        }, 
                        {
                         "x":332,
                         "y":40
                        }, 
                        {
                         "x":446,
                         "y":-20
                        }, 
                        {
                         "x":660,
                         "y":-202
                        }, 
                        {
                         "x":852,
                         "y":-270
                        }, 
                        {
                         "x":914,
                         "y":-310
                        }, 
                        {
                         "x":980,
                         "y":-458
                        }, 
                        {
                         "x":990,
                         "y":-536
                        }, 
                        {
                         "x":914,
                         "y":-666
                        }, 
                        {
                         "x":912,
                         "y":-828
                        }, 
                        {
                         "x":876,
                         "y":-926
                        }, 
                        {
                         "x":1140,
                         "y":-1250
                        }, 
                        {
                         "x":1390,
                         "y":-1246
                        }, 
                        {
                         "x":1378,
                         "y":52
                        }, 
                        {
                         "x":1384,
                         "y":118
                        },
                    
                        {
                         "x":-10,
                         "y":114
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3867.76,
                 "y":6047.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":81,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":0,
                         "y":-38
                        }, 
                        {
                         "x":-32,
                         "y":-98
                        }, 
                        {
                         "x":-40,
                         "y":-144
                        }, 
                        {
                         "x":-56,
                         "y":-174
                        }, 
                        {
                         "x":-86,
                         "y":-218
                        }, 
                        {
                         "x":-140,
                         "y":-258
                        }, 
                        {
                         "x":-146,
                         "y":-292
                        }, 
                        {
                         "x":-96,
                         "y":-292
                        }, 
                        {
                         "x":-94,
                         "y":-264
                        }, 
                        {
                         "x":-66,
                         "y":-228
                        }, 
                        {
                         "x":-42,
                         "y":-180
                        }, 
                        {
                         "x":-16,
                         "y":-120
                        }, 
                        {
                         "x":30,
                         "y":-4
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4689.76,
                 "y":5813.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":82,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":0,
                         "y":-44
                        }, 
                        {
                         "x":-104,
                         "y":-144
                        }, 
                        {
                         "x":-230,
                         "y":-182
                        }, 
                        {
                         "x":-228,
                         "y":-202
                        }, 
                        {
                         "x":-106,
                         "y":-162
                        }, 
                        {
                         "x":-26,
                         "y":-94
                        }, 
                        {
                         "x":46,
                         "y":-38
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4801.76,
                 "y":5723.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":83,
                 "name":"",
                 "polygon":[
                        {
                         "x":28.3333,
                         "y":-4
                        }, 
                        {
                         "x":29.3333,
                         "y":18.6667
                        }, 
                        {
                         "x":70.6667,
                         "y":10.6667
                        }, 
                        {
                         "x":364,
                         "y":10.6667
                        }, 
                        {
                         "x":366.667,
                         "y":-4
                        }, 
                        {
                         "x":18.6667,
                         "y":-4
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4190.42,
                 "y":5529.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":84,
                 "name":"",
                 "polygon":[
                        {
                         "x":-34.6667,
                         "y":0
                        }, 
                        {
                         "x":-35,
                         "y":20
                        }, 
                        {
                         "x":-189.333,
                         "y":16
                        }, 
                        {
                         "x":-272,
                         "y":5.33333
                        }, 
                        {
                         "x":-306.667,
                         "y":-1.33333
                        }, 
                        {
                         "x":-334.667,
                         "y":-13.3333
                        }, 
                        {
                         "x":-408,
                         "y":-56
                        }, 
                        {
                         "x":-473.333,
                         "y":-89.3333
                        }, 
                        {
                         "x":-544,
                         "y":-129.333
                        }, 
                        {
                         "x":-533.333,
                         "y":-153.333
                        }, 
                        {
                         "x":-473.333,
                         "y":-109.333
                        }, 
                        {
                         "x":-370.667,
                         "y":-52
                        }, 
                        {
                         "x":-280,
                         "y":-14.6667
                        }, 
                        {
                         "x":-206.667,
                         "y":-5.33333
                        }, 
                        {
                         "x":-90.6667,
                         "y":5.33333
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4091.76,
                 "y":5529.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":85,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-4,
                         "y":-32
                        }, 
                        {
                         "x":-21.3333,
                         "y":-74.6667
                        }, 
                        {
                         "x":-74.6667,
                         "y":-153.333
                        }, 
                        {
                         "x":-124,
                         "y":-208
                        }, 
                        {
                         "x":-153.333,
                         "y":-253.333
                        }, 
                        {
                         "x":-173.333,
                         "y":-285.333
                        }, 
                        {
                         "x":-178.666,
                         "y":-377.334
                        }, 
                        {
                         "x":-193.333,
                         "y":-350.667
                        }, 
                        {
                         "x":-192,
                         "y":-300
                        }, 
                        {
                         "x":-184,
                         "y":-270.667
                        }, 
                        {
                         "x":-160,
                         "y":-228
                        }, 
                        {
                         "x":-114.667,
                         "y":-173.333
                        }, 
                        {
                         "x":-77.3333,
                         "y":-125.333
                        }, 
                        {
                         "x":-45.3333,
                         "y":-77.3333
                        }, 
                        {
                         "x":-18.6667,
                         "y":-25.3333
                        },
                    
                        {
                         "x":-24,
                         "y":14.6667
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3843.76,
                 "y":6033.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":86,
                 "name":"",
                 "polygon":[
                        {
                         "x":-19.3333,
                         "y":-34.6667
                        }, 
                        {
                         "x":-94.6667,
                         "y":-169.333
                        }, 
                        {
                         "x":-113.333,
                         "y":-157.333
                        }, 
                        {
                         "x":-85.3333,
                         "y":-120
                        }, 
                        {
                         "x":-65.3333,
                         "y":-82.6667
                        }, 
                        {
                         "x":-38,
                         "y":-16.6667
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3647.76,
                 "y":5540.36
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":87,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-1.33333,
                         "y":24
                        }, 
                        {
                         "x":86.6667,
                         "y":48
                        }, 
                        {
                         "x":166.667,
                         "y":65.3333
                        }, 
                        {
                         "x":300,
                         "y":92
                        }, 
                        {
                         "x":374.667,
                         "y":110.667
                        }, 
                        {
                         "x":429.333,
                         "y":137.333
                        }, 
                        {
                         "x":533.333,
                         "y":273.333
                        }, 
                        {
                         "x":554.667,
                         "y":262.667
                        }, 
                        {
                         "x":517.333,
                         "y":218.667
                        }, 
                        {
                         "x":509.333,
                         "y":217.333
                        }, 
                        {
                         "x":440,
                         "y":130.667
                        }, 
                        {
                         "x":408,
                         "y":104
                        }, 
                        {
                         "x":40,
                         "y":21.3333
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3010.42,
                 "y":5120.36
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":88,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-6.66667,
                         "y":21.3333
                        }, 
                        {
                         "x":-78.6667,
                         "y":-8
                        }, 
                        {
                         "x":-146.667,
                         "y":-53.3333
                        }, 
                        {
                         "x":-213.333,
                         "y":-117.333
                        }, 
                        {
                         "x":-248,
                         "y":-164
                        }, 
                        {
                         "x":-270.667,
                         "y":-196
                        }, 
                        {
                         "x":-284,
                         "y":-237.333
                        }, 
                        {
                         "x":-300,
                         "y":-296
                        }, 
                        {
                         "x":-312,
                         "y":-353.333
                        }, 
                        {
                         "x":-325.333,
                         "y":-401.333
                        }, 
                        {
                         "x":-330.667,
                         "y":-418.667
                        }, 
                        {
                         "x":-333.667,
                         "y":-435
                        }, 
                        {
                         "x":-312.333,
                         "y":-425.333
                        }, 
                        {
                         "x":-310.667,
                         "y":-417.333
                        }, 
                        {
                         "x":-293.333,
                         "y":-353.333
                        },
                    
                        {
                         "x":-281.333,
                         "y":-293.333
                        }, 
                        {
                         "x":-266.667,
                         "y":-245.333
                        }, 
                        {
                         "x":-252,
                         "y":-201.333
                        }, 
                        {
                         "x":-222.667,
                         "y":-152
                        }, 
                        {
                         "x":-197.333,
                         "y":-122.667
                        }, 
                        {
                         "x":-164,
                         "y":-93.3333
                        }, 
                        {
                         "x":-122.667,
                         "y":-61.3333
                        }, 
                        {
                         "x":-85.3333,
                         "y":-36
                        }, 
                        {
                         "x":-41.3333,
                         "y":-8
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3026.42,
                 "y":5125.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":89,
                 "name":"",
                 "polygon":[
                        {
                         "x":1.33333,
                         "y":-28.3333
                        }, 
                        {
                         "x":0,
                         "y":-116
                        }, 
                        {
                         "x":22.6667,
                         "y":-116
                        }, 
                        {
                         "x":24.6667,
                         "y":-22.6667
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2690.42,
                 "y":4580.36
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":90,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":25.3333,
                         "y":854.667
                        }, 
                        {
                         "x":-2124,
                         "y":850.667
                        }, 
                        {
                         "x":-2108,
                         "y":274.667
                        }, 
                        {
                         "x":-972,
                         "y":270.667
                        }, 
                        {
                         "x":-460,
                         "y":-101.333
                        }, 
                        {
                         "x":-448,
                         "y":-109.333
                        }, 
                        {
                         "x":-427,
                         "y":-56.3333
                        }, 
                        {
                         "x":-377,
                         "y":-8.33333
                        }, 
                        {
                         "x":-322,
                         "y":11.6667
                        }, 
                        {
                         "x":-264,
                         "y":15.6667
                        }, 
                        {
                         "x":-200,
                         "y":-3.33333
                        }, 
                        {
                         "x":-108,
                         "y":-33.3333
                        }, 
                        {
                         "x":-75,
                         "y":-33.3333
                        }, 
                        {
                         "x":-28,
                         "y":-17.3333
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2011.76,
                 "y":5303.03
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":91,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":3,
                         "y":-51
                        }, 
                        {
                         "x":9,
                         "y":-96
                        }, 
                        {
                         "x":44,
                         "y":-236
                        }, 
                        {
                         "x":56,
                         "y":-259
                        }, 
                        {
                         "x":82,
                         "y":-255
                        }, 
                        {
                         "x":53,
                         "y":-204
                        }, 
                        {
                         "x":22,
                         "y":-73
                        }, 
                        {
                         "x":14,
                         "y":27
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":1991.76,
                 "y":5302.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":92,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":13,
                         "y":-58
                        }, 
                        {
                         "x":34,
                         "y":-122
                        }, 
                        {
                         "x":78,
                         "y":-177
                        }, 
                        {
                         "x":127,
                         "y":-221
                        }, 
                        {
                         "x":174,
                         "y":-264
                        }, 
                        {
                         "x":192,
                         "y":-247
                        }, 
                        {
                         "x":136,
                         "y":-201
                        }, 
                        {
                         "x":70,
                         "y":-142
                        }, 
                        {
                         "x":52,
                         "y":-110
                        }, 
                        {
                         "x":31,
                         "y":-60
                        }, 
                        {
                         "x":26,
                         "y":10
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":1874.76,
                 "y":5286.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":93,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":85,
                         "y":-77
                        }, 
                        {
                         "x":106.667,
                         "y":-95
                        }, 
                        {
                         "x":127,
                         "y":-91
                        }, 
                        {
                         "x":97,
                         "y":-58
                        }, 
                        {
                         "x":37,
                         "y":-11
                        }, 
                        {
                         "x":25,
                         "y":29
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2037.76,
                 "y":5032.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":94,
                 "name":"",
                 "polygon":[
                        {
                         "x":-5.66667,
                         "y":-41.3333
                        }, 
                        {
                         "x":-12,
                         "y":-95
                        }, 
                        {
                         "x":-19,
                         "y":-138
                        }, 
                        {
                         "x":-50,
                         "y":-233
                        }, 
                        {
                         "x":-27,
                         "y":-242
                        }, 
                        {
                         "x":-16,
                         "y":-178
                        }, 
                        {
                         "x":2,
                         "y":-135
                        }, 
                        {
                         "x":17,
                         "y":-51
                        }, 
                        {
                         "x":16.3333,
                         "y":-38.3333
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2207.76,
                 "y":4821.7
                },
            
                {
                 "class":"",
                 "height":0,
                 "id":95,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-17.3333,
                         "y":-73.3333
                        }, 
                        {
                         "x":-22.6667,
                         "y":-158.667
                        }, 
                        {
                         "x":-5.33333,
                         "y":-245.333
                        }, 
                        {
                         "x":73.3333,
                         "y":-310.667
                        }, 
                        {
                         "x":117.333,
                         "y":-377.333
                        }, 
                        {
                         "x":130.667,
                         "y":-482.667
                        }, 
                        {
                         "x":108,
                         "y":-553.333
                        }, 
                        {
                         "x":17.3333,
                         "y":-709.333
                        }, 
                        {
                         "x":6.66667,
                         "y":-761.333
                        }, 
                        {
                         "x":-84,
                         "y":-862.667
                        }, 
                        {
                         "x":-145.333,
                         "y":-918.667
                        }, 
                        {
                         "x":-188,
                         "y":-984
                        }, 
                        {
                         "x":-190.667,
                         "y":-1050.67
                        }, 
                        {
                         "x":-152,
                         "y":-1149.33
                        }, 
                        {
                         "x":-714.667,
                         "y":-1149.33
                        },
                    
                        {
                         "x":-704,
                         "y":-1054.67
                        }, 
                        {
                         "x":-297.333,
                         "y":-714.667
                        }, 
                        {
                         "x":-186.667,
                         "y":-568
                        }, 
                        {
                         "x":-248,
                         "y":-341.333
                        }, 
                        {
                         "x":-388,
                         "y":-160
                        }, 
                        {
                         "x":-394.667,
                         "y":-46.6667
                        }, 
                        {
                         "x":-344,
                         "y":57.3333
                        }, 
                        {
                         "x":-257.333,
                         "y":213.333
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":1567.76,
                 "y":5223.03
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":96,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":73.3333,
                         "y":-40
                        }, 
                        {
                         "x":192,
                         "y":-129.333
                        }, 
                        {
                         "x":262.667,
                         "y":-198.667
                        }, 
                        {
                         "x":316,
                         "y":-249.333
                        }, 
                        {
                         "x":356,
                         "y":-309.333
                        }, 
                        {
                         "x":372,
                         "y":-376
                        }, 
                        {
                         "x":361.333,
                         "y":-430.667
                        }, 
                        {
                         "x":309.333,
                         "y":-532
                        }, 
                        {
                         "x":264,
                         "y":-629.333
                        }, 
                        {
                         "x":-290.667,
                         "y":-528
                        }, 
                        {
                         "x":-185.333,
                         "y":-397.333
                        }, 
                        {
                         "x":-172,
                         "y":-314.667
                        }, 
                        {
                         "x":-225.333,
                         "y":-226.667
                        }, 
                        {
                         "x":-294.667,
                         "y":-181.333
                        }, 
                        {
                         "x":-458.667,
                         "y":-116
                        },
                    
                        {
                         "x":-533.333,
                         "y":-1.33333
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":1377.09,
                 "y":4100.36
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":97,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":28,
                         "y":-136
                        }, 
                        {
                         "x":53.3333,
                         "y":-177.333
                        }, 
                        {
                         "x":64,
                         "y":-242.667
                        }, 
                        {
                         "x":61.3333,
                         "y":-293.333
                        }, 
                        {
                         "x":50.6667,
                         "y":-346.667
                        }, 
                        {
                         "x":98.6667,
                         "y":-413.333
                        }, 
                        {
                         "x":194.667,
                         "y":-444
                        }, 
                        {
                         "x":153.333,
                         "y":-628
                        }, 
                        {
                         "x":72,
                         "y":-618.667
                        }, 
                        {
                         "x":-26.6667,
                         "y":-634.667
                        }, 
                        {
                         "x":-118.667,
                         "y":-701.333
                        }, 
                        {
                         "x":-290.667,
                         "y":-776
                        }, 
                        {
                         "x":-344,
                         "y":-773.333
                        }, 
                        {
                         "x":-626.667,
                         "y":-621.333
                        }, 
                        {
                         "x":-748,
                         "y":-137.333
                        },
                    
                        {
                         "x":-514.667,
                         "y":89.3333
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":1647.76,
                 "y":3531.03
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":98,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":60,
                         "y":-16
                        }, 
                        {
                         "x":117.333,
                         "y":-22.6667
                        }, 
                        {
                         "x":201.333,
                         "y":4
                        }, 
                        {
                         "x":285.333,
                         "y":60
                        }, 
                        {
                         "x":321.333,
                         "y":73.3333
                        }, 
                        {
                         "x":361.333,
                         "y":66.6667
                        }, 
                        {
                         "x":394.667,
                         "y":38.6667
                        }, 
                        {
                         "x":422.667,
                         "y":-20
                        }, 
                        {
                         "x":433.333,
                         "y":-318.667
                        }, 
                        {
                         "x":429.333,
                         "y":-364
                        }, 
                        {
                         "x":433.333,
                         "y":-388
                        }, 
                        {
                         "x":448,
                         "y":-460
                        }, 
                        {
                         "x":464,
                         "y":-605.333
                        }, 
                        {
                         "x":469.333,
                         "y":-632
                        }, 
                        {
                         "x":489.333,
                         "y":-676
                        },
                    
                        {
                         "x":532,
                         "y":-714.667
                        }, 
                        {
                         "x":604,
                         "y":-738.667
                        }, 
                        {
                         "x":630.667,
                         "y":-745.333
                        }, 
                        {
                         "x":665.333,
                         "y":-766.667
                        }, 
                        {
                         "x":704,
                         "y":-812
                        }, 
                        {
                         "x":713.333,
                         "y":-841.333
                        }, 
                        {
                         "x":714.667,
                         "y":-864
                        }, 
                        {
                         "x":181.333,
                         "y":-812
                        }, 
                        {
                         "x":236,
                         "y":-637.333
                        }, 
                        {
                         "x":194.667,
                         "y":-449.333
                        }, 
                        {
                         "x":141.333,
                         "y":-385.333
                        }, 
                        {
                         "x":68,
                         "y":-340
                        }, 
                        {
                         "x":17.3333,
                         "y":-209.333
                        }, 
                        {
                         "x":-61.3333,
                         "y":-178.667
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":1823.76,
                 "y":3092.36
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":99,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":32,
                         "y":16
                        }, 
                        {
                         "x":78.6667,
                         "y":20
                        }, 
                        {
                         "x":130.667,
                         "y":1.33333
                        }, 
                        {
                         "x":198.667,
                         "y":-32
                        }, 
                        {
                         "x":265.333,
                         "y":-64
                        }, 
                        {
                         "x":338.667,
                         "y":-116
                        }, 
                        {
                         "x":449.333,
                         "y":-177.333
                        }, 
                        {
                         "x":470.667,
                         "y":-148
                        }, 
                        {
                         "x":286.667,
                         "y":-52
                        }, 
                        {
                         "x":213.333,
                         "y":-8
                        }, 
                        {
                         "x":136,
                         "y":28
                        }, 
                        {
                         "x":-1.33333,
                         "y":46.6667
                        }, 
                        {
                         "x":-34.6667,
                         "y":44
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":1633.09,
                 "y":3873.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":100,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":52,
                         "y":10.6667
                        }, 
                        {
                         "x":94.6667,
                         "y":24
                        }, 
                        {
                         "x":154.667,
                         "y":45.3333
                        }, 
                        {
                         "x":217.333,
                         "y":40
                        }, 
                        {
                         "x":238.667,
                         "y":38.6667
                        }, 
                        {
                         "x":341.333,
                         "y":6.66667
                        }, 
                        {
                         "x":341.333,
                         "y":22.6667
                        }, 
                        {
                         "x":234.667,
                         "y":68
                        }, 
                        {
                         "x":120,
                         "y":68
                        }, 
                        {
                         "x":81.3333,
                         "y":50.6667
                        }, 
                        {
                         "x":22.6667,
                         "y":33.3333
                        }, 
                        {
                         "x":1.33333,
                         "y":37.3333
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":1730.42,
                 "y":3692.36
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":101,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":86.6667,
                         "y":30.6667
                        }, 
                        {
                         "x":162.667,
                         "y":64
                        }, 
                        {
                         "x":248,
                         "y":98.6667
                        }, 
                        {
                         "x":326.667,
                         "y":158.667
                        }, 
                        {
                         "x":402.667,
                         "y":201.333
                        }, 
                        {
                         "x":464,
                         "y":221.333
                        }, 
                        {
                         "x":392,
                         "y":242.667
                        }, 
                        {
                         "x":381.333,
                         "y":221.333
                        }, 
                        {
                         "x":292,
                         "y":166.667
                        }, 
                        {
                         "x":230.667,
                         "y":124
                        }, 
                        {
                         "x":128,
                         "y":76
                        }, 
                        {
                         "x":-24,
                         "y":32
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":1631.76,
                 "y":3485.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":102,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-20,
                         "y":24
                        }, 
                        {
                         "x":-17.3333,
                         "y":82.6667
                        }, 
                        {
                         "x":12,
                         "y":154.667
                        }, 
                        {
                         "x":77.3333,
                         "y":225.333
                        }, 
                        {
                         "x":109.333,
                         "y":241.333
                        }, 
                        {
                         "x":136,
                         "y":182.667
                        }, 
                        {
                         "x":145.333,
                         "y":121.333
                        }, 
                        {
                         "x":157.333,
                         "y":84
                        }, 
                        {
                         "x":197.333,
                         "y":77.3333
                        }, 
                        {
                         "x":286.667,
                         "y":108
                        }, 
                        {
                         "x":300,
                         "y":194.667
                        }, 
                        {
                         "x":357.333,
                         "y":157.333
                        }, 
                        {
                         "x":386.667,
                         "y":8
                        }, 
                        {
                         "x":410.667,
                         "y":-81.3333
                        }, 
                        {
                         "x":604,
                         "y":-532
                        },
                    
                        {
                         "x":544,
                         "y":-512
                        }, 
                        {
                         "x":484,
                         "y":-521.333
                        }, 
                        {
                         "x":445.333,
                         "y":-530.667
                        }, 
                        {
                         "x":389.333,
                         "y":-520
                        }, 
                        {
                         "x":340,
                         "y":-484
                        }, 
                        {
                         "x":296,
                         "y":-430.667
                        }, 
                        {
                         "x":257.333,
                         "y":-400
                        }, 
                        {
                         "x":225.333,
                         "y":-380
                        }, 
                        {
                         "x":149.333,
                         "y":-370.667
                        }, 
                        {
                         "x":124,
                         "y":-370.667
                        }, 
                        {
                         "x":50.6667,
                         "y":-322.667
                        }, 
                        {
                         "x":34.6667,
                         "y":-272
                        }, 
                        {
                         "x":34.6667,
                         "y":-192
                        }, 
                        {
                         "x":45.3333,
                         "y":-125.333
                        }, 
                        {
                         "x":41.3333,
                         "y":-66.6667
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3613.09,
                 "y":4643.03
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":103,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":802.667,
                         "y":634.667
                        }, 
                        {
                         "x":1006.67,
                         "y":260
                        }, 
                        {
                         "x":69.3333,
                         "y":-316
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4014.42,
                 "y":4557.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":104,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":65.3333,
                         "y":-36
                        }, 
                        {
                         "x":98.6667,
                         "y":-96
                        }, 
                        {
                         "x":134.667,
                         "y":-158.667
                        }, 
                        {
                         "x":189.333,
                         "y":-200
                        }, 
                        {
                         "x":253.333,
                         "y":-216
                        }, 
                        {
                         "x":337.333,
                         "y":-190.667
                        }, 
                        {
                         "x":410.667,
                         "y":-145.333
                        }, 
                        {
                         "x":442.667,
                         "y":-124
                        }, 
                        {
                         "x":481.333,
                         "y":-70.6667
                        }, 
                        {
                         "x":549.333,
                         "y":-13.3333
                        }, 
                        {
                         "x":682.667,
                         "y":72
                        }, 
                        {
                         "x":762.667,
                         "y":86.6667
                        }, 
                        {
                         "x":844,
                         "y":38.6667
                        }, 
                        {
                         "x":966.667,
                         "y":758.667
                        }, 
                        {
                         "x":-116,
                         "y":142.667
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4186.42,
                 "y":4128.36
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":134,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":4,
                         "y":30.6667
                        }, 
                        {
                         "x":114.667,
                         "y":8
                        }, 
                        {
                         "x":229.333,
                         "y":8
                        }, 
                        {
                         "x":312,
                         "y":-53.3333
                        }, 
                        {
                         "x":390.667,
                         "y":-66.6667
                        }, 
                        {
                         "x":497.333,
                         "y":-50.6667
                        }, 
                        {
                         "x":562.667,
                         "y":-37.3333
                        }, 
                        {
                         "x":642.667,
                         "y":-58.6667
                        }, 
                        {
                         "x":709.333,
                         "y":-54.6667
                        }, 
                        {
                         "x":743.333,
                         "y":-70
                        }, 
                        {
                         "x":718,
                         "y":-92.667
                        }, 
                        {
                         "x":700,
                         "y":-88
                        }, 
                        {
                         "x":657.333,
                         "y":-80
                        }, 
                        {
                         "x":613.333,
                         "y":-77.3333
                        }, 
                        {
                         "x":568,
                         "y":-62.6667
                        },
                    
                        {
                         "x":505.333,
                         "y":-74.6667
                        }, 
                        {
                         "x":426.667,
                         "y":-88
                        }, 
                        {
                         "x":349.333,
                         "y":-84
                        }, 
                        {
                         "x":296,
                         "y":-73.3333
                        }, 
                        {
                         "x":244,
                         "y":-37.3333
                        }, 
                        {
                         "x":218.667,
                         "y":-14.6667
                        }, 
                        {
                         "x":182.667,
                         "y":-13.3333
                        }, 
                        {
                         "x":70.6667,
                         "y":-9.33333
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2082.42,
                 "y":3695.03
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":135,
                 "name":"",
                 "polygon":[
                        {
                         "x":35.3333,
                         "y":2.66667
                        }, 
                        {
                         "x":48,
                         "y":0
                        }, 
                        {
                         "x":93.3333,
                         "y":-8
                        }, 
                        {
                         "x":166.667,
                         "y":-29.3333
                        }, 
                        {
                         "x":209.333,
                         "y":-56
                        }, 
                        {
                         "x":238.667,
                         "y":-82.6667
                        }, 
                        {
                         "x":284,
                         "y":-121.333
                        }, 
                        {
                         "x":296,
                         "y":-138.667
                        }, 
                        {
                         "x":292,
                         "y":-222.667
                        }, 
                        {
                         "x":320,
                         "y":-225.333
                        }, 
                        {
                         "x":318.667,
                         "y":-133.333
                        }, 
                        {
                         "x":312,
                         "y":-112
                        }, 
                        {
                         "x":281.333,
                         "y":-84
                        }, 
                        {
                         "x":246.667,
                         "y":-56
                        }, 
                        {
                         "x":214.667,
                         "y":-29.3333
                        }, 
                        {
                         "x":173.333,
                         "y":-4
                        },
                    
                        {
                         "x":141.333,
                         "y":5.33333
                        }, 
                        {
                         "x":49.3333,
                         "y":29.3333
                        }, 
                        {
                         "x":30,
                         "y":27.3333
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2963.76,
                 "y":3527.03
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":136,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-17.3333,
                         "y":-58.6667
                        }, 
                        {
                         "x":-9.33333,
                         "y":-121.333
                        }, 
                        {
                         "x":10.6667,
                         "y":-156
                        }, 
                        {
                         "x":29.3333,
                         "y":-209.333
                        }, 
                        {
                         "x":40,
                         "y":-258.667
                        }, 
                        {
                         "x":53.3333,
                         "y":-314.667
                        }, 
                        {
                         "x":90.6667,
                         "y":-348
                        }, 
                        {
                         "x":144,
                         "y":-370.667
                        }, 
                        {
                         "x":216,
                         "y":-398.667
                        }, 
                        {
                         "x":301.333,
                         "y":-417.333
                        }, 
                        {
                         "x":361.333,
                         "y":-429.333
                        }, 
                        {
                         "x":484,
                         "y":-433.333
                        }, 
                        {
                         "x":506.667,
                         "y":-434.667
                        }, 
                        {
                         "x":545.333,
                         "y":-445.333
                        }, 
                        {
                         "x":641.333,
                         "y":-454.667
                        },
                    
                        {
                         "x":685.333,
                         "y":-465.333
                        }, 
                        {
                         "x":696,
                         "y":-466.667
                        }, 
                        {
                         "x":725.333,
                         "y":-438.667
                        }, 
                        {
                         "x":672,
                         "y":-433.333
                        }, 
                        {
                         "x":620,
                         "y":-417.333
                        }, 
                        {
                         "x":524,
                         "y":-410.667
                        }, 
                        {
                         "x":374.667,
                         "y":-400
                        }, 
                        {
                         "x":346.667,
                         "y":-401.333
                        }, 
                        {
                         "x":284,
                         "y":-385.333
                        }, 
                        {
                         "x":242.667,
                         "y":-381.333
                        }, 
                        {
                         "x":80,
                         "y":-310.667
                        }, 
                        {
                         "x":58.6667,
                         "y":-246.667
                        }, 
                        {
                         "x":48,
                         "y":-182.667
                        }, 
                        {
                         "x":22.6667,
                         "y":-130.667
                        }, 
                        {
                         "x":10.6667,
                         "y":-97.3333
                        }, 
                        {
                         "x":8,
                         "y":-48
                        },
                    
                        {
                         "x":25.3333,
                         "y":16
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3255.76,
                 "y":3312.36
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":137,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-29.3333,
                         "y":13.3333
                        }, 
                        {
                         "x":-84,
                         "y":24
                        }, 
                        {
                         "x":-106.667,
                         "y":48
                        }, 
                        {
                         "x":-118.667,
                         "y":76
                        }, 
                        {
                         "x":-102.667,
                         "y":104
                        }, 
                        {
                         "x":-69.3333,
                         "y":112
                        }, 
                        {
                         "x":-12,
                         "y":97.3333
                        }, 
                        {
                         "x":32,
                         "y":84
                        }, 
                        {
                         "x":68,
                         "y":77.3333
                        }, 
                        {
                         "x":90.6667,
                         "y":82.6667
                        }, 
                        {
                         "x":120,
                         "y":73.3333
                        }, 
                        {
                         "x":148,
                         "y":62.6667
                        }, 
                        {
                         "x":148,
                         "y":14.6667
                        }, 
                        {
                         "x":105.333,
                         "y":-14.6667
                        }, 
                        {
                         "x":74.6667,
                         "y":-33.3333
                        },
                    
                        {
                         "x":33.3333,
                         "y":-41.3333
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2486.42,
                 "y":3057.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":138,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-48,
                         "y":21.3333
                        }, 
                        {
                         "x":-65.3333,
                         "y":54.6667
                        }, 
                        {
                         "x":-53.3333,
                         "y":89.3333
                        }, 
                        {
                         "x":-17.3333,
                         "y":114.667
                        }, 
                        {
                         "x":42.6667,
                         "y":130.667
                        }, 
                        {
                         "x":74.6667,
                         "y":122.667
                        }, 
                        {
                         "x":301.333,
                         "y":80
                        }, 
                        {
                         "x":386.667,
                         "y":122.667
                        }, 
                        {
                         "x":482.667,
                         "y":89.3333
                        }, 
                        {
                         "x":534.667,
                         "y":78.6667
                        }, 
                        {
                         "x":570.667,
                         "y":77.3333
                        }, 
                        {
                         "x":622.667,
                         "y":106.667
                        }, 
                        {
                         "x":634.667,
                         "y":124
                        }, 
                        {
                         "x":657.333,
                         "y":129.333
                        }, 
                        {
                         "x":700,
                         "y":82.6667
                        },
                    
                        {
                         "x":736,
                         "y":58.6667
                        }, 
                        {
                         "x":765.333,
                         "y":2.66667
                        }, 
                        {
                         "x":757.333,
                         "y":-37.3333
                        }, 
                        {
                         "x":736,
                         "y":-64
                        }, 
                        {
                         "x":704,
                         "y":-85.3333
                        }, 
                        {
                         "x":650.667,
                         "y":-98.6667
                        }, 
                        {
                         "x":621.333,
                         "y":-86.6667
                        }, 
                        {
                         "x":592,
                         "y":-78.6667
                        }, 
                        {
                         "x":545.333,
                         "y":-73.3333
                        }, 
                        {
                         "x":513.333,
                         "y":-74.6667
                        }, 
                        {
                         "x":473.333,
                         "y":-88
                        }, 
                        {
                         "x":384,
                         "y":-97.3333
                        }, 
                        {
                         "x":336,
                         "y":-93.3333
                        }, 
                        {
                         "x":300,
                         "y":-93.3333
                        }, 
                        {
                         "x":241.333,
                         "y":-73.3333
                        }, 
                        {
                         "x":168,
                         "y":-53.3333
                        },
                    
                        {
                         "x":56,
                         "y":-21.3333
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3629.09,
                 "y":3092.36
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":139,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":55.3333,
                         "y":-90
                        }, 
                        {
                         "x":65.3333,
                         "y":-174
                        }, 
                        {
                         "x":58.6667,
                         "y":-145.333
                        }, 
                        {
                         "x":37.3333,
                         "y":-170.667
                        }, 
                        {
                         "x":122,
                         "y":-236
                        }, 
                        {
                         "x":149.333,
                         "y":-217.333
                        }, 
                        {
                         "x":101.333,
                         "y":-176.667
                        }, 
                        {
                         "x":84,
                         "y":-158.667
                        }, 
                        {
                         "x":80,
                         "y":-125.333
                        }, 
                        {
                         "x":56.6667,
                         "y":-50
                        }, 
                        {
                         "x":36,
                         "y":0
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3912.42,
                 "y":3017.03
                },
            
                {
                 "class":"",
                 "height":0,
                 "id":140,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":63,
                         "y":-55
                        }, 
                        {
                         "x":81,
                         "y":-94
                        }, 
                        {
                         "x":93,
                         "y":-137
                        }, 
                        {
                         "x":122,
                         "y":-219
                        }, 
                        {
                         "x":157,
                         "y":-282
                        }, 
                        {
                         "x":191,
                         "y":-320
                        }, 
                        {
                         "x":215,
                         "y":-341
                        }, 
                        {
                         "x":234,
                         "y":-376
                        }, 
                        {
                         "x":262,
                         "y":-418
                        }, 
                        {
                         "x":297,
                         "y":-481
                        }, 
                        {
                         "x":316,
                         "y":-528
                        }, 
                        {
                         "x":325.5,
                         "y":-553.5
                        }, 
                        {
                         "x":353.5,
                         "y":-554.5
                        }, 
                        {
                         "x":348,
                         "y":-541
                        }, 
                        {
                         "x":332,
                         "y":-495
                        },
                    
                        {
                         "x":305,
                         "y":-435
                        }, 
                        {
                         "x":275,
                         "y":-395
                        }, 
                        {
                         "x":248,
                         "y":-350
                        }, 
                        {
                         "x":227,
                         "y":-315
                        }, 
                        {
                         "x":189,
                         "y":-278
                        }, 
                        {
                         "x":161,
                         "y":-242
                        }, 
                        {
                         "x":129,
                         "y":-181
                        }, 
                        {
                         "x":110,
                         "y":-127
                        }, 
                        {
                         "x":100,
                         "y":-76
                        }, 
                        {
                         "x":84,
                         "y":-38
                        }, 
                        {
                         "x":50,
                         "y":-3
                        }, 
                        {
                         "x":13,
                         "y":17
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4027.76,
                 "y":2794.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":141,
                 "name":"",
                 "polygon":[
                        {
                         "x":2.5,
                         "y":-33.5
                        }, 
                        {
                         "x":6,
                         "y":-69
                        }, 
                        {
                         "x":9,
                         "y":-119
                        }, 
                        {
                         "x":18,
                         "y":-192
                        }, 
                        {
                         "x":38,
                         "y":-234
                        }, 
                        {
                         "x":78,
                         "y":-291
                        }, 
                        {
                         "x":91,
                         "y":-318
                        }, 
                        {
                         "x":121,
                         "y":-357
                        }, 
                        {
                         "x":162,
                         "y":-351
                        }, 
                        {
                         "x":131,
                         "y":-318
                        }, 
                        {
                         "x":84,
                         "y":-257
                        }, 
                        {
                         "x":50,
                         "y":-200
                        }, 
                        {
                         "x":41,
                         "y":-168
                        }, 
                        {
                         "x":35,
                         "y":-118
                        }, 
                        {
                         "x":34,
                         "y":-91
                        }, 
                        {
                         "x":27,
                         "y":-37
                        },
                    
                        {
                         "x":25,
                         "y":-33
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4362.76,
                 "y":2082.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":142,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":42.6667,
                         "y":-2.66667
                        }, 
                        {
                         "x":113.333,
                         "y":-26
                        }, 
                        {
                         "x":176.667,
                         "y":-62.6667
                        }, 
                        {
                         "x":234,
                         "y":-113.333
                        }, 
                        {
                         "x":288.667,
                         "y":-146
                        }, 
                        {
                         "x":386.667,
                         "y":-202
                        }, 
                        {
                         "x":467,
                         "y":-249
                        }, 
                        {
                         "x":517,
                         "y":-277
                        }, 
                        {
                         "x":569,
                         "y":-299
                        }, 
                        {
                         "x":613,
                         "y":-304
                        }, 
                        {
                         "x":636,
                         "y":-282
                        }, 
                        {
                         "x":617,
                         "y":-278
                        }, 
                        {
                         "x":576,
                         "y":-270
                        }, 
                        {
                         "x":528,
                         "y":-251
                        }, 
                        {
                         "x":418,
                         "y":-190
                        },
                    
                        {
                         "x":289,
                         "y":-118
                        }, 
                        {
                         "x":201,
                         "y":-53
                        }, 
                        {
                         "x":169,
                         "y":-25
                        }, 
                        {
                         "x":146,
                         "y":-8
                        }, 
                        {
                         "x":105,
                         "y":9
                        }, 
                        {
                         "x":54,
                         "y":23
                        }, 
                        {
                         "x":5,
                         "y":34
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2239.76,
                 "y":2677.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":143,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":85,
                         "y":-7
                        }, 
                        {
                         "x":138,
                         "y":-12
                        }, 
                        {
                         "x":170.5,
                         "y":-13
                        }, 
                        {
                         "x":255.5,
                         "y":-17.5
                        }, 
                        {
                         "x":359,
                         "y":-35.5
                        }, 
                        {
                         "x":416.5,
                         "y":-54
                        }, 
                        {
                         "x":460,
                         "y":-82
                        }, 
                        {
                         "x":479,
                         "y":-123.5
                        }, 
                        {
                         "x":478,
                         "y":-181.5
                        }, 
                        {
                         "x":475,
                         "y":-217.5
                        }, 
                        {
                         "x":460,
                         "y":-252.5
                        }, 
                        {
                         "x":460,
                         "y":-257
                        }, 
                        {
                         "x":488.5,
                         "y":-261.5
                        }, 
                        {
                         "x":484,
                         "y":-259.5
                        }, 
                        {
                         "x":499,
                         "y":-215.5
                        },
                    
                        {
                         "x":504,
                         "y":-153.5
                        }, 
                        {
                         "x":502,
                         "y":-116.5
                        }, 
                        {
                         "x":476,
                         "y":-61.5
                        }, 
                        {
                         "x":430,
                         "y":-26.5
                        }, 
                        {
                         "x":378,
                         "y":-13.5
                        }, 
                        {
                         "x":278,
                         "y":3.5
                        }, 
                        {
                         "x":-7,
                         "y":20.5
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2835.76,
                 "y":2376.2
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":144,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":10,
                         "y":-31
                        }, 
                        {
                         "x":43.5,
                         "y":-69.5
                        }, 
                        {
                         "x":129,
                         "y":-144
                        }, 
                        {
                         "x":209.5,
                         "y":-182.5
                        }, 
                        {
                         "x":319.5,
                         "y":-243.5
                        }, 
                        {
                         "x":340.5,
                         "y":-225.5
                        }, 
                        {
                         "x":264.5,
                         "y":-185.5
                        }, 
                        {
                         "x":182.5,
                         "y":-138.5
                        }, 
                        {
                         "x":130.5,
                         "y":-111.5
                        }, 
                        {
                         "x":89.5,
                         "y":-77.5
                        }, 
                        {
                         "x":53.5,
                         "y":-40.5
                        }, 
                        {
                         "x":29.5,
                         "y":-8.5
                        }, 
                        {
                         "x":25.5,
                         "y":5.5
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3313.26,
                 "y":1957.2
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":145,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":75,
                         "y":-31
                        }, 
                        {
                         "x":120,
                         "y":-44
                        }, 
                        {
                         "x":194,
                         "y":-55
                        }, 
                        {
                         "x":259,
                         "y":-44
                        }, 
                        {
                         "x":342,
                         "y":-13
                        }, 
                        {
                         "x":458,
                         "y":13
                        }, 
                        {
                         "x":576,
                         "y":43
                        }, 
                        {
                         "x":698,
                         "y":42
                        }, 
                        {
                         "x":757,
                         "y":33
                        }, 
                        {
                         "x":794,
                         "y":26
                        }, 
                        {
                         "x":791,
                         "y":54
                        }, 
                        {
                         "x":719,
                         "y":66
                        }, 
                        {
                         "x":660,
                         "y":68
                        }, 
                        {
                         "x":567,
                         "y":67
                        }, 
                        {
                         "x":522,
                         "y":60
                        },
                    
                        {
                         "x":460,
                         "y":40
                        }, 
                        {
                         "x":361,
                         "y":19
                        }, 
                        {
                         "x":266,
                         "y":-11
                        }, 
                        {
                         "x":249,
                         "y":-20
                        }, 
                        {
                         "x":191,
                         "y":-26
                        }, 
                        {
                         "x":134,
                         "y":-26
                        }, 
                        {
                         "x":81,
                         "y":-11
                        }, 
                        {
                         "x":-15,
                         "y":26
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3625.76,
                 "y":1720.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":146,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":82,
                         "y":-22
                        }, 
                        {
                         "x":146,
                         "y":-69
                        }, 
                        {
                         "x":156,
                         "y":-40
                        }, 
                        {
                         "x":138,
                         "y":-28
                        }, 
                        {
                         "x":103,
                         "y":1
                        }, 
                        {
                         "x":-14,
                         "y":29
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4390.76,
                 "y":1753.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":147,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":59,
                         "y":-27
                        }, 
                        {
                         "x":118,
                         "y":-41
                        }, 
                        {
                         "x":185,
                         "y":-42
                        }, 
                        {
                         "x":246,
                         "y":-34
                        }, 
                        {
                         "x":314,
                         "y":-22
                        }, 
                        {
                         "x":378,
                         "y":-25
                        }, 
                        {
                         "x":468,
                         "y":-26
                        }, 
                        {
                         "x":531,
                         "y":-36
                        }, 
                        {
                         "x":571,
                         "y":-60
                        }, 
                        {
                         "x":595,
                         "y":-48
                        }, 
                        {
                         "x":544,
                         "y":-15
                        }, 
                        {
                         "x":511,
                         "y":-6
                        }, 
                        {
                         "x":455,
                         "y":2
                        }, 
                        {
                         "x":385,
                         "y":-1
                        }, 
                        {
                         "x":262,
                         "y":2
                        },
                    
                        {
                         "x":222,
                         "y":-12
                        }, 
                        {
                         "x":151,
                         "y":-14
                        }, 
                        {
                         "x":95,
                         "y":-11
                        }, 
                        {
                         "x":9,
                         "y":21
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4523.76,
                 "y":1694.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":148,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":14,
                         "y":-62
                        }, 
                        {
                         "x":47,
                         "y":-134
                        }, 
                        {
                         "x":82,
                         "y":-218
                        }, 
                        {
                         "x":99,
                         "y":-260
                        }, 
                        {
                         "x":118,
                         "y":-373
                        }, 
                        {
                         "x":117,
                         "y":-538
                        }, 
                        {
                         "x":137,
                         "y":-550
                        }, 
                        {
                         "x":129,
                         "y":-489
                        }, 
                        {
                         "x":131,
                         "y":-402
                        }, 
                        {
                         "x":125,
                         "y":-307
                        }, 
                        {
                         "x":118,
                         "y":-274
                        }, 
                        {
                         "x":112,
                         "y":-240
                        }, 
                        {
                         "x":27,
                         "y":-40
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4645.76,
                 "y":2663.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":149,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":14,
                         "y":-47
                        }, 
                        {
                         "x":49,
                         "y":-103
                        }, 
                        {
                         "x":79,
                         "y":-148
                        }, 
                        {
                         "x":105,
                         "y":-178
                        }, 
                        {
                         "x":144,
                         "y":-207
                        }, 
                        {
                         "x":177,
                         "y":-243
                        }, 
                        {
                         "x":209,
                         "y":-269
                        }, 
                        {
                         "x":236,
                         "y":-293
                        }, 
                        {
                         "x":254,
                         "y":-317
                        }, 
                        {
                         "x":271,
                         "y":-355
                        }, 
                        {
                         "x":282,
                         "y":-404
                        }, 
                        {
                         "x":284,
                         "y":-454
                        }, 
                        {
                         "x":302,
                         "y":-459
                        }, 
                        {
                         "x":296,
                         "y":-437
                        }, 
                        {
                         "x":302,
                         "y":-421
                        },
                    
                        {
                         "x":291,
                         "y":-365
                        }, 
                        {
                         "x":280,
                         "y":-328
                        }, 
                        {
                         "x":273,
                         "y":-311
                        }, 
                        {
                         "x":250,
                         "y":-282
                        }, 
                        {
                         "x":234,
                         "y":-267
                        }, 
                        {
                         "x":219,
                         "y":-250
                        }, 
                        {
                         "x":190,
                         "y":-228
                        }, 
                        {
                         "x":169,
                         "y":-209
                        }, 
                        {
                         "x":140,
                         "y":-182
                        }, 
                        {
                         "x":123,
                         "y":-163
                        }, 
                        {
                         "x":103,
                         "y":-150
                        }, 
                        {
                         "x":85,
                         "y":-127
                        }, 
                        {
                         "x":73,
                         "y":-106
                        }, 
                        {
                         "x":60,
                         "y":-80
                        }, 
                        {
                         "x":50,
                         "y":-70
                        }, 
                        {
                         "x":32,
                         "y":-43
                        },
                    
                        {
                         "x":14,
                         "y":2
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4761.76,
                 "y":2127.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":150,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":38,
                         "y":-26
                        }, 
                        {
                         "x":77,
                         "y":-56
                        }, 
                        {
                         "x":105,
                         "y":-71
                        }, 
                        {
                         "x":158,
                         "y":-86
                        }, 
                        {
                         "x":210,
                         "y":-98
                        }, 
                        {
                         "x":275,
                         "y":-120
                        }, 
                        {
                         "x":334,
                         "y":-131
                        }, 
                        {
                         "x":467,
                         "y":-133
                        }, 
                        {
                         "x":547,
                         "y":-131
                        }, 
                        {
                         "x":603,
                         "y":-144
                        }, 
                        {
                         "x":718,
                         "y":-161
                        }, 
                        {
                         "x":723,
                         "y":-134
                        }, 
                        {
                         "x":598,
                         "y":-118
                        }, 
                        {
                         "x":562,
                         "y":-114
                        }, 
                        {
                         "x":542,
                         "y":-105
                        },
                    
                        {
                         "x":376,
                         "y":-106
                        }, 
                        {
                         "x":313,
                         "y":-102
                        }, 
                        {
                         "x":257,
                         "y":-88
                        }, 
                        {
                         "x":177,
                         "y":-63
                        }, 
                        {
                         "x":115,
                         "y":-46
                        }, 
                        {
                         "x":66,
                         "y":-19
                        }, 
                        {
                         "x":23,
                         "y":13
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":5081.76,
                 "y":1639.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":151,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":94,
                         "y":-30
                        }, 
                        {
                         "x":164,
                         "y":-59
                        }, 
                        {
                         "x":197,
                         "y":-74
                        }, 
                        {
                         "x":264,
                         "y":-183
                        }, 
                        {
                         "x":287,
                         "y":-185
                        }, 
                        {
                         "x":273,
                         "y":-148
                        }, 
                        {
                         "x":240,
                         "y":-89
                        }, 
                        {
                         "x":205,
                         "y":-51
                        }, 
                        {
                         "x":98,
                         "y":-6
                        }, 
                        {
                         "x":-2,
                         "y":27
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":5786.76,
                 "y":1483.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":152,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":22,
                         "y":73
                        }, 
                        {
                         "x":22,
                         "y":164
                        }, 
                        {
                         "x":-21,
                         "y":279
                        }, 
                        {
                         "x":-112,
                         "y":419
                        }, 
                        {
                         "x":-90,
                         "y":430
                        }, 
                        {
                         "x":-7,
                         "y":300
                        }, 
                        {
                         "x":37,
                         "y":191
                        }, 
                        {
                         "x":43,
                         "y":48
                        }, 
                        {
                         "x":26,
                         "y":-3
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":5709.76,
                 "y":1503.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":153,
                 "name":"",
                 "polygon":[
                        {
                         "x":-11,
                         "y":14
                        }, 
                        {
                         "x":2,
                         "y":24
                        }, 
                        {
                         "x":-20,
                         "y":52
                        }, 
                        {
                         "x":-2,
                         "y":72
                        }, 
                        {
                         "x":15,
                         "y":116
                        }, 
                        {
                         "x":46,
                         "y":181
                        }, 
                        {
                         "x":89,
                         "y":224
                        }, 
                        {
                         "x":154,
                         "y":262
                        }, 
                        {
                         "x":207,
                         "y":295
                        }, 
                        {
                         "x":259,
                         "y":339
                        }, 
                        {
                         "x":302,
                         "y":385
                        }, 
                        {
                         "x":343,
                         "y":423
                        }, 
                        {
                         "x":394,
                         "y":423.667
                        }, 
                        {
                         "x":389.667,
                         "y":438.333
                        }, 
                        {
                         "x":345,
                         "y":438
                        }, 
                        {
                         "x":313,
                         "y":418
                        },
                    
                        {
                         "x":282,
                         "y":387
                        }, 
                        {
                         "x":257,
                         "y":361
                        }, 
                        {
                         "x":228,
                         "y":335
                        }, 
                        {
                         "x":196,
                         "y":305
                        }, 
                        {
                         "x":170,
                         "y":292
                        }, 
                        {
                         "x":129,
                         "y":265
                        }, 
                        {
                         "x":74,
                         "y":232
                        }, 
                        {
                         "x":43,
                         "y":199
                        }, 
                        {
                         "x":20,
                         "y":165
                        }, 
                        {
                         "x":4,
                         "y":127
                        }, 
                        {
                         "x":-16,
                         "y":85
                        }, 
                        {
                         "x":-27,
                         "y":44
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":5504.76,
                 "y":2061.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":154,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-13,
                         "y":-4
                        }, 
                        {
                         "x":-31,
                         "y":35
                        }, 
                        {
                         "x":-46,
                         "y":89
                        }, 
                        {
                         "x":-51,
                         "y":152
                        }, 
                        {
                         "x":-47,
                         "y":198
                        }, 
                        {
                         "x":-37,
                         "y":252
                        }, 
                        {
                         "x":-9,
                         "y":298
                        }, 
                        {
                         "x":27,
                         "y":340
                        }, 
                        {
                         "x":64,
                         "y":371
                        }, 
                        {
                         "x":108,
                         "y":409
                        }, 
                        {
                         "x":154,
                         "y":441
                        }, 
                        {
                         "x":193,
                         "y":474
                        }, 
                        {
                         "x":224,
                         "y":519
                        }, 
                        {
                         "x":252,
                         "y":577
                        }, 
                        {
                         "x":298,
                         "y":673
                        },
                    
                        {
                         "x":295.382,
                         "y":670.02
                        }, 
                        {
                         "x":296.333,
                         "y":671.667
                        }, 
                        {
                         "x":311,
                         "y":664.333
                        }, 
                        {
                         "x":279,
                         "y":603.333
                        }, 
                        {
                         "x":271,
                         "y":590
                        }, 
                        {
                         "x":250,
                         "y":541
                        }, 
                        {
                         "x":222,
                         "y":489
                        }, 
                        {
                         "x":198,
                         "y":458
                        }, 
                        {
                         "x":151,
                         "y":418
                        }, 
                        {
                         "x":109,
                         "y":384
                        }, 
                        {
                         "x":74,
                         "y":362
                        }, 
                        {
                         "x":49,
                         "y":339
                        }, 
                        {
                         "x":14,
                         "y":299
                        }, 
                        {
                         "x":-10,
                         "y":261
                        }, 
                        {
                         "x":-31,
                         "y":209
                        }, 
                        {
                         "x":-36,
                         "y":120
                        },
                    
                        {
                         "x":-27,
                         "y":74
                        }, 
                        {
                         "x":-9,
                         "y":33
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":5494.76,
                 "y":2097.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":155,
                 "name":"",
                 "polygon":[
                        {
                         "x":8.66667,
                         "y":23.3333
                        }, 
                        {
                         "x":-6.66667,
                         "y":28.6667
                        }, 
                        {
                         "x":13.3333,
                         "y":80.6667
                        }, 
                        {
                         "x":16,
                         "y":99
                        }, 
                        {
                         "x":5,
                         "y":167
                        }, 
                        {
                         "x":-9,
                         "y":197
                        }, 
                        {
                         "x":-42,
                         "y":223
                        }, 
                        {
                         "x":-103,
                         "y":253
                        }, 
                        {
                         "x":-147,
                         "y":273
                        }, 
                        {
                         "x":-190,
                         "y":318
                        }, 
                        {
                         "x":-231,
                         "y":366
                        }, 
                        {
                         "x":-264,
                         "y":381
                        }, 
                        {
                         "x":-357,
                         "y":389
                        }, 
                        {
                         "x":-437,
                         "y":388
                        }, 
                        {
                         "x":-471,
                         "y":397
                        }, 
                        {
                         "x":-544,
                         "y":449
                        },
                    
                        {
                         "x":-570,
                         "y":495
                        }, 
                        {
                         "x":-550,
                         "y":517
                        }, 
                        {
                         "x":-505,
                         "y":521
                        }, 
                        {
                         "x":-458,
                         "y":546
                        }, 
                        {
                         "x":-411,
                         "y":572
                        }, 
                        {
                         "x":-369,
                         "y":580
                        }, 
                        {
                         "x":-314,
                         "y":572
                        }, 
                        {
                         "x":-257,
                         "y":542
                        }, 
                        {
                         "x":-152,
                         "y":513
                        }, 
                        {
                         "x":-102,
                         "y":470
                        }, 
                        {
                         "x":-66,
                         "y":449
                        }, 
                        {
                         "x":-66,
                         "y":419
                        }, 
                        {
                         "x":-96,
                         "y":410
                        }, 
                        {
                         "x":-161,
                         "y":414
                        }, 
                        {
                         "x":-199,
                         "y":417
                        }, 
                        {
                         "x":-244,
                         "y":407
                        },
                    
                        {
                         "x":-249,
                         "y":389
                        }, 
                        {
                         "x":-204,
                         "y":370
                        }, 
                        {
                         "x":-185,
                         "y":333
                        }, 
                        {
                         "x":-165,
                         "y":305
                        }, 
                        {
                         "x":-133,
                         "y":278
                        }, 
                        {
                         "x":-104,
                         "y":270
                        }, 
                        {
                         "x":-69,
                         "y":253
                        }, 
                        {
                         "x":-31,
                         "y":228
                        }, 
                        {
                         "x":6,
                         "y":200
                        }, 
                        {
                         "x":29,
                         "y":160
                        }, 
                        {
                         "x":36,
                         "y":135
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":5873.76,
                 "y":2917.7
                },
            
                {
                 "class":"",
                 "height":0,
                 "id":156,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":65,
                         "y":118
                        }, 
                        {
                         "x":121,
                         "y":183
                        }, 
                        {
                         "x":165,
                         "y":213
                        }, 
                        {
                         "x":216,
                         "y":239
                        }, 
                        {
                         "x":260,
                         "y":248
                        }, 
                        {
                         "x":321,
                         "y":258
                        }, 
                        {
                         "x":385,
                         "y":268
                        }, 
                        {
                         "x":390,
                         "y":244
                        }, 
                        {
                         "x":355,
                         "y":244
                        }, 
                        {
                         "x":268,
                         "y":231
                        }, 
                        {
                         "x":205,
                         "y":211
                        }, 
                        {
                         "x":153,
                         "y":187
                        }, 
                        {
                         "x":119,
                         "y":157
                        }, 
                        {
                         "x":82,
                         "y":112
                        }, 
                        {
                         "x":69,
                         "y":87
                        },
                    
                        {
                         "x":49,
                         "y":57
                        }, 
                        {
                         "x":27,
                         "y":28
                        }, 
                        {
                         "x":10,
                         "y":-8
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":5887.76,
                 "y":3003.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":157,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-33.3333,
                         "y":66.6667
                        }, 
                        {
                         "x":-21.3333,
                         "y":166.667
                        }, 
                        {
                         "x":9.33333,
                         "y":292
                        }, 
                        {
                         "x":-28,
                         "y":336
                        }, 
                        {
                         "x":-98.6667,
                         "y":353.333
                        }, 
                        {
                         "x":-324,
                         "y":440
                        }, 
                        {
                         "x":-360,
                         "y":489.333
                        }, 
                        {
                         "x":-572,
                         "y":556
                        }, 
                        {
                         "x":-618.667,
                         "y":566.667
                        }, 
                        {
                         "x":-766.667,
                         "y":596
                        }, 
                        {
                         "x":-840,
                         "y":585.333
                        }, 
                        {
                         "x":-1032,
                         "y":544
                        }, 
                        {
                         "x":-1108,
                         "y":564
                        }, 
                        {
                         "x":-1172,
                         "y":625.333
                        }, 
                        {
                         "x":-1197.33,
                         "y":718.667
                        },
                    
                        {
                         "x":-1194.67,
                         "y":804
                        }, 
                        {
                         "x":-1210.67,
                         "y":880
                        }, 
                        {
                         "x":-1252,
                         "y":940
                        }, 
                        {
                         "x":-1278.67,
                         "y":974.667
                        }, 
                        {
                         "x":-1229.33,
                         "y":1216
                        }, 
                        {
                         "x":-614.667,
                         "y":1577.33
                        }, 
                        {
                         "x":-380,
                         "y":1324
                        }, 
                        {
                         "x":-282.667,
                         "y":1308
                        }, 
                        {
                         "x":-209.333,
                         "y":1337.33
                        }, 
                        {
                         "x":-134.667,
                         "y":1350.67
                        }, 
                        {
                         "x":-34.6667,
                         "y":1325.33
                        }, 
                        {
                         "x":61.3333,
                         "y":1256
                        }, 
                        {
                         "x":89.3333,
                         "y":1144
                        }, 
                        {
                         "x":132,
                         "y":1057.33
                        }, 
                        {
                         "x":260,
                         "y":1000
                        }, 
                        {
                         "x":313.333,
                         "y":960
                        },
                    
                        {
                         "x":360,
                         "y":865.333
                        }, 
                        {
                         "x":354.667,
                         "y":772
                        }, 
                        {
                         "x":341.333,
                         "y":700
                        }, 
                        {
                         "x":362.667,
                         "y":628
                        }, 
                        {
                         "x":416,
                         "y":606.667
                        }, 
                        {
                         "x":464,
                         "y":602.667
                        }, 
                        {
                         "x":518.667,
                         "y":605.333
                        }, 
                        {
                         "x":496,
                         "y":-290.667
                        }, 
                        {
                         "x":441.333,
                         "y":-264
                        }, 
                        {
                         "x":336,
                         "y":-237.333
                        }, 
                        {
                         "x":290.667,
                         "y":-233.333
                        }, 
                        {
                         "x":254.667,
                         "y":-218.667
                        }, 
                        {
                         "x":224,
                         "y":-176
                        }, 
                        {
                         "x":205.333,
                         "y":-122.667
                        }, 
                        {
                         "x":181.333,
                         "y":-93.3333
                        }, 
                        {
                         "x":145.333,
                         "y":-66.6667
                        },
                    
                        {
                         "x":104,
                         "y":-57.3333
                        }, 
                        {
                         "x":40,
                         "y":-30.6667
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":6290.42,
                 "y":3220.36
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":158,
                 "name":"",
                 "polygon":[
                        {
                         "x":6.66667,
                         "y":0.666667
                        }, 
                        {
                         "x":5.33333,
                         "y":21.3333
                        }, 
                        {
                         "x":41.3333,
                         "y":34.6667
                        }, 
                        {
                         "x":74.6667,
                         "y":77.3333
                        }, 
                        {
                         "x":106.667,
                         "y":116
                        }, 
                        {
                         "x":153.333,
                         "y":154.667
                        }, 
                        {
                         "x":217.333,
                         "y":181.333
                        }, 
                        {
                         "x":280,
                         "y":193.333
                        }, 
                        {
                         "x":313.333,
                         "y":194.667
                        }, 
                        {
                         "x":342.667,
                         "y":209.333
                        }, 
                        {
                         "x":378.667,
                         "y":216
                        }, 
                        {
                         "x":450.667,
                         "y":237.333
                        }, 
                        {
                         "x":534.667,
                         "y":296
                        }, 
                        {
                         "x":609.333,
                         "y":385.333
                        }, 
                        {
                         "x":666.667,
                         "y":474.667
                        }, 
                        {
                         "x":690.667,
                         "y":438.667
                        },
                    
                        {
                         "x":648,
                         "y":417.333
                        }, 
                        {
                         "x":612,
                         "y":365.333
                        }, 
                        {
                         "x":582.667,
                         "y":325.333
                        }, 
                        {
                         "x":544,
                         "y":286.667
                        }, 
                        {
                         "x":492,
                         "y":246.667
                        }, 
                        {
                         "x":449.333,
                         "y":226.667
                        }, 
                        {
                         "x":400,
                         "y":212
                        }, 
                        {
                         "x":376,
                         "y":200
                        }, 
                        {
                         "x":340,
                         "y":172.667
                        }, 
                        {
                         "x":270.667,
                         "y":170.667
                        }, 
                        {
                         "x":192,
                         "y":156
                        }, 
                        {
                         "x":154.667,
                         "y":129.333
                        }, 
                        {
                         "x":108,
                         "y":89.3333
                        }, 
                        {
                         "x":92,
                         "y":62.6667
                        }, 
                        {
                         "x":62.6667,
                         "y":25.3333
                        }, 
                        {
                         "x":36,
                         "y":10
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":6090.42,
                 "y":2503.03
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":159,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":82.6667,
                         "y":-8.66667
                        }, 
                        {
                         "x":130.667,
                         "y":-12
                        }, 
                        {
                         "x":198,
                         "y":-2.66667
                        }, 
                        {
                         "x":252,
                         "y":6
                        }, 
                        {
                         "x":475.333,
                         "y":89.3333
                        }, 
                        {
                         "x":468.667,
                         "y":111.333
                        }, 
                        {
                         "x":420,
                         "y":94
                        }, 
                        {
                         "x":352,
                         "y":63.3333
                        }, 
                        {
                         "x":289.333,
                         "y":36
                        }, 
                        {
                         "x":227.333,
                         "y":14
                        }, 
                        {
                         "x":151.333,
                         "y":10.6667
                        }, 
                        {
                         "x":93.3333,
                         "y":5.33333
                        }, 
                        {
                         "x":44,
                         "y":14.6667
                        }, 
                        {
                         "x":28.6667,
                         "y":19.3333
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":6419.76,
                 "y":2679.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":160,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":57,
                         "y":-74
                        }, 
                        {
                         "x":70,
                         "y":-155
                        }, 
                        {
                         "x":72,
                         "y":-210
                        }, 
                        {
                         "x":81,
                         "y":-272
                        }, 
                        {
                         "x":122,
                         "y":-340
                        }, 
                        {
                         "x":237,
                         "y":-411
                        }, 
                        {
                         "x":281,
                         "y":-476
                        }, 
                        {
                         "x":286,
                         "y":-550
                        }, 
                        {
                         "x":308,
                         "y":-614
                        }, 
                        {
                         "x":428,
                         "y":-728
                        }, 
                        {
                         "x":468,
                         "y":-788
                        }, 
                        {
                         "x":484,
                         "y":-868
                        }, 
                        {
                         "x":1174,
                         "y":-634
                        }, 
                        {
                         "x":408,
                         "y":-30
                        }, 
                        {
                         "x":308,
                         "y":1250
                        },
                    
                        {
                         "x":118,
                         "y":936
                        }, 
                        {
                         "x":-34,
                         "y":846
                        }, 
                        {
                         "x":-30,
                         "y":72
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":6777.76,
                 "y":2939.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":162,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-10.6667,
                         "y":-46.6667
                        }, 
                        {
                         "x":-37.3333,
                         "y":-109.333
                        }, 
                        {
                         "x":-65.3333,
                         "y":-129.333
                        }, 
                        {
                         "x":-108,
                         "y":-157.333
                        }, 
                        {
                         "x":-156,
                         "y":-185.333
                        }, 
                        {
                         "x":-201.333,
                         "y":-229.333
                        }, 
                        {
                         "x":-214.667,
                         "y":-280
                        }, 
                        {
                         "x":-194.667,
                         "y":-334.667
                        }, 
                        {
                         "x":-161.333,
                         "y":-373.333
                        }, 
                        {
                         "x":-96,
                         "y":-416
                        }, 
                        {
                         "x":-25.3333,
                         "y":-436
                        }, 
                        {
                         "x":36,
                         "y":-460
                        }, 
                        {
                         "x":74.6667,
                         "y":-501.333
                        }, 
                        {
                         "x":81.3333,
                         "y":-582.667
                        }, 
                        {
                         "x":68,
                         "y":-617.333
                        },
                    
                        {
                         "x":30.6667,
                         "y":-678.667
                        }, 
                        {
                         "x":-4,
                         "y":-733.333
                        }, 
                        {
                         "x":-20,
                         "y":-810.667
                        }, 
                        {
                         "x":-9.33333,
                         "y":-889.333
                        }, 
                        {
                         "x":10.6667,
                         "y":-944
                        }, 
                        {
                         "x":-2.66667,
                         "y":-1006.67
                        }, 
                        {
                         "x":-21.3333,
                         "y":-1050.67
                        }, 
                        {
                         "x":-50.6667,
                         "y":-1078.67
                        }, 
                        {
                         "x":-80,
                         "y":-1125.33
                        }, 
                        {
                         "x":-106.667,
                         "y":-1182.67
                        }, 
                        {
                         "x":-97.3333,
                         "y":-1272
                        }, 
                        {
                         "x":-116,
                         "y":-1356
                        }, 
                        {
                         "x":-156,
                         "y":-1398.67
                        }, 
                        {
                         "x":-252,
                         "y":-1441.33
                        }, 
                        {
                         "x":-386.667,
                         "y":-1474.67
                        }, 
                        {
                         "x":-657.333,
                         "y":-1557.33
                        },
                    
                        {
                         "x":-716,
                         "y":-1673.33
                        }, 
                        {
                         "x":-760,
                         "y":-1664
                        }, 
                        {
                         "x":-942.667,
                         "y":-2028
                        }, 
                        {
                         "x":-156,
                         "y":-2402.67
                        }, 
                        {
                         "x":524,
                         "y":-1377.33
                        }, 
                        {
                         "x":344,
                         "y":-1128
                        }, 
                        {
                         "x":304,
                         "y":-1053.33
                        }, 
                        {
                         "x":346.667,
                         "y":-904
                        }, 
                        {
                         "x":498.667,
                         "y":-662.667
                        }, 
                        {
                         "x":648,
                         "y":-610.667
                        }, 
                        {
                         "x":900,
                         "y":-550.667
                        }, 
                        {
                         "x":809.333,
                         "y":482.667
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":7262.42,
                 "y":2101.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":163,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":24,
                         "y":-24
                        }, 
                        {
                         "x":30,
                         "y":-104
                        }, 
                        {
                         "x":42,
                         "y":-236
                        }, 
                        {
                         "x":88,
                         "y":-374
                        }, 
                        {
                         "x":222,
                         "y":-546
                        }, 
                        {
                         "x":286,
                         "y":-612
                        }, 
                        {
                         "x":348,
                         "y":-736
                        }, 
                        {
                         "x":386,
                         "y":-858
                        }, 
                        {
                         "x":386,
                         "y":-992
                        }, 
                        {
                         "x":350,
                         "y":-1124
                        }, 
                        {
                         "x":296,
                         "y":-1226
                        }, 
                        {
                         "x":306,
                         "y":-1360
                        }, 
                        {
                         "x":386,
                         "y":-1426
                        }, 
                        {
                         "x":556,
                         "y":-1510
                        }, 
                        {
                         "x":696,
                         "y":-1568
                        },
                    
                        {
                         "x":786,
                         "y":-1630
                        }, 
                        {
                         "x":896,
                         "y":-1708
                        }, 
                        {
                         "x":990,
                         "y":-1794
                        }, 
                        {
                         "x":1102,
                         "y":-1794
                        }, 
                        {
                         "x":1186,
                         "y":-1760
                        }, 
                        {
                         "x":1244,
                         "y":-1736
                        }, 
                        {
                         "x":1290,
                         "y":-1726
                        }, 
                        {
                         "x":1330,
                         "y":-1838
                        }, 
                        {
                         "x":1700,
                         "y":-2006
                        }, 
                        {
                         "x":1952,
                         "y":-2006
                        }, 
                        {
                         "x":2156,
                         "y":-2018
                        }, 
                        {
                         "x":2360,
                         "y":-2062
                        }, 
                        {
                         "x":2478,
                         "y":-2066
                        }, 
                        {
                         "x":2590,
                         "y":-2038
                        }, 
                        {
                         "x":2606,
                         "y":-2024
                        }, 
                        {
                         "x":3104,
                         "y":-2594
                        },
                    
                        {
                         "x":-50,
                         "y":-2584
                        }, 
                        {
                         "x":-260,
                         "y":-1782
                        }, 
                        {
                         "x":-286,
                         "y":-1632
                        }, 
                        {
                         "x":-200,
                         "y":-1580
                        }, 
                        {
                         "x":-166,
                         "y":-1450
                        }, 
                        {
                         "x":-210,
                         "y":-1350
                        }, 
                        {
                         "x":-304,
                         "y":-1334
                        }, 
                        {
                         "x":-446,
                         "y":-1360
                        }, 
                        {
                         "x":-572,
                         "y":-128
                        }, 
                        {
                         "x":-558,
                         "y":-40
                        }, 
                        {
                         "x":-480,
                         "y":38
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2511.76,
                 "y":2267.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":164,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":51,
                         "y":-14
                        }, 
                        {
                         "x":154,
                         "y":-54
                        }, 
                        {
                         "x":164,
                         "y":-32
                        }, 
                        {
                         "x":106,
                         "y":-22
                        }, 
                        {
                         "x":16,
                         "y":26
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2845.76,
                 "y":1193.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":165,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-4,
                         "y":17
                        }, 
                        {
                         "x":179,
                         "y":21
                        }, 
                        {
                         "x":287,
                         "y":28
                        }, 
                        {
                         "x":297,
                         "y":27
                        }, 
                        {
                         "x":296,
                         "y":7
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3183.76,
                 "y":1117.7
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":166,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":42.6667,
                         "y":-29.3333
                        }, 
                        {
                         "x":92,
                         "y":-97.3333
                        }, 
                        {
                         "x":102.667,
                         "y":-182.667
                        }, 
                        {
                         "x":84,
                         "y":-282.667
                        }, 
                        {
                         "x":58.6667,
                         "y":-398.667
                        }, 
                        {
                         "x":114.667,
                         "y":-406.667
                        }, 
                        {
                         "x":100,
                         "y":-346.667
                        }, 
                        {
                         "x":118.667,
                         "y":-292
                        }, 
                        {
                         "x":125.333,
                         "y":-225.333
                        }, 
                        {
                         "x":126.667,
                         "y":-162.667
                        }, 
                        {
                         "x":122.667,
                         "y":-105.333
                        }, 
                        {
                         "x":54.6667,
                         "y":4
                        }, 
                        {
                         "x":24,
                         "y":17.3333
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4397.09,
                 "y":645.697
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":167,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":32,
                         "y":-16
                        }, 
                        {
                         "x":57.3333,
                         "y":-49.3333
                        }, 
                        {
                         "x":86.6667,
                         "y":-104
                        }, 
                        {
                         "x":117.333,
                         "y":-144
                        }, 
                        {
                         "x":142.667,
                         "y":-196
                        }, 
                        {
                         "x":125.333,
                         "y":-286.667
                        }, 
                        {
                         "x":174.667,
                         "y":-269.333
                        }, 
                        {
                         "x":172,
                         "y":-209.333
                        }, 
                        {
                         "x":141.333,
                         "y":-124
                        }, 
                        {
                         "x":72,
                         "y":-22.6667
                        }, 
                        {
                         "x":14.6667,
                         "y":50.6667
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4501.09,
                 "y":477.697
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":186,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":8.66667,
                         "y":129.333
                        }, 
                        {
                         "x":-2,
                         "y":158
                        }, 
                        {
                         "x":16,
                         "y":162.667
                        }, 
                        {
                         "x":27.3333,
                         "y":126.667
                        }, 
                        {
                         "x":22.6667,
                         "y":-4
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4970.42,
                 "y":2846.36
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":187,
                 "name":"",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":21.3333,
                         "y":1.33333
                        }, 
                        {
                         "x":-14,
                         "y":58
                        }, 
                        {
                         "x":-19.3333,
                         "y":98
                        }, 
                        {
                         "x":-18.6667,
                         "y":132.667
                        }, 
                        {
                         "x":6,
                         "y":199.333
                        }, 
                        {
                         "x":6.66667,
                         "y":210
                        }, 
                        {
                         "x":-22.6667,
                         "y":202.667
                        }, 
                        {
                         "x":-26,
                         "y":163.333
                        }, 
                        {
                         "x":-40.6667,
                         "y":121.333
                        }, 
                        {
                         "x":-30,
                         "y":58.6667
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4877.09,
                 "y":3168.36
                }],
         "offsetx":124.242,
         "offsety":330.303,
         "opacity":1,
         "type":"objectgroup",
         "visible":false,
         "x":0,
         "y":0
        }, 
        {
         "color":"#e67814",
         "draworder":"topdown",
         "id":8,
         "name":"trucks",
         "objects":[
                {
                 "class":"",
                 "height":128,
                 "id":44,
                 "name":"Truck1",
                 "properties":[
                        {
                         "name":"car",
                         "type":"object",
                         "value":42
                        }],
                 "rotation":90,
                 "visible":true,
                 "width":64,
                 "x":3764,
                 "y":2673.33
                }, 
                {
                 "class":"",
                 "height":128,
                 "id":45,
                 "name":"Truck2",
                 "properties":[
                        {
                         "name":"car",
                         "type":"object",
                         "value":42
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":64,
                 "x":3048,
                 "y":2382.67
                }],
         "opacity":1,
         "type":"objectgroup",
         "visible":true,
         "x":0,
         "y":0
        }, 
        {
         "draworder":"topdown",
         "id":12,
         "name":"camera",
         "objects":[
                {
                 "class":"",
                 "height":0,
                 "id":51,
                 "name":"SpawnLocation",
                 "point":true,
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2956,
                 "y":2000
                }],
         "opacity":1,
         "type":"objectgroup",
         "visible":true,
         "x":0,
         "y":0
        }, 
        {
         "draworder":"topdown",
         "id":16,
         "name":"messages",
         "objects":[
                {
                 "class":"",
                 "height":0,
                 "id":225,
                 "name":"Tutorial1",
                 "point":true,
                 "properties":[
                        {
                         "name":"text",
                         "type":"string",
                         "value":"Greetings! Click on the vehicle with the left button."
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3068.67333333333,
                 "y":2283.67333333333
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":226,
                 "name":"Tutorial2",
                 "point":true,
                 "properties":[
                        {
                         "name":"text",
                         "type":"string",
                         "value":"Find a building and pick up the cargo"
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3694.33,
                 "y":2643
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":307,
                 "name":"Tutorial3",
                 "point":true,
                 "properties":[
                        {
                         "name":"text",
                         "type":"string",
                         "value":"This is where bloodweiser is being made."
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3966.66333333333,
                 "y":2574.66333333333
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":308,
                 "name":"Tutorial4",
                 "point":true,
                 "properties":[
                        {
                         "name":"text",
                         "type":"string",
                         "value":"Bats are fed, when you bring the bloodweiser"
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3438.67,
                 "y":1817.33
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":309,
                 "name":"Tutorial5",
                 "point":true,
                 "properties":[
                        {
                         "name":"text",
                         "type":"string",
                         "value":"This dog wants some steak!"
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3498.67,
                 "y":1180
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":310,
                 "name":"Tutorial6",
                 "point":true,
                 "properties":[
                        {
                         "name":"text",
                         "type":"string",
                         "value":"Find a wherewolfcave!"
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4813.33,
                 "y":2312
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":322,
                 "name":"Tutorial7",
                 "point":true,
                 "properties":[
                        {
                         "name":"text",
                         "type":"string",
                         "value":"If too much is stocked up, they close forever."
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4168,
                 "y":2869.33333333333
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":323,
                 "name":"Tutorial8",
                 "point":true,
                 "properties":[
                        {
                         "name":"text",
                         "type":"string",
                         "value":"Food surplus gives birth to new bats"
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3690.66666666667,
                 "y":1624
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":324,
                 "name":"Tutorial9",
                 "point":true,
                 "properties":[
                        {
                         "name":"text",
                         "type":"string",
                         "value":"Click on the map to move."
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3078.66666666667,
                 "y":2642.66666666667
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":325,
                 "name":"Tutorial10",
                 "point":true,
                 "properties":[
                        {
                         "name":"text",
                         "type":"string",
                         "value":"Hold Shift while clicking to move along a route"
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3448,
                 "y":2425.33333333333
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":326,
                 "name":"Tutorial11",
                 "point":true,
                 "properties":[
                        {
                         "name":"text",
                         "type":"string",
                         "value":"Press Space to pause the game and plan routes"
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4022.66666666667,
                 "y":2332
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":327,
                 "name":"Tutorial12",
                 "point":true,
                 "properties":[
                        {
                         "name":"text",
                         "type":"string",
                         "value":"Press P to make the route of a truck cyclic"
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3626.66666666667,
                 "y":2013.33333333333
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":328,
                 "name":"Tutorial13",
                 "point":true,
                 "properties":[
                        {
                         "name":"text",
                         "type":"string",
                         "value":"Your task is to transport food to the creatures around."
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3142.66666666667,
                 "y":2853.33333333333
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":329,
                 "name":"Tutorial14",
                 "point":true,
                 "properties":[
                        {
                         "name":"text",
                         "type":"string",
                         "value":"Plan your logistics, or the supply chain collapses!"
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3145.33333333333,
                 "y":2974.66666666667
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":330,
                 "name":"Tutorial15",
                 "point":true,
                 "properties":[
                        {
                         "name":"text",
                         "type":"string",
                         "value":"If a species goes extinct, you lose the game."
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3146.66666666667,
                 "y":3080
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":331,
                 "name":"Tutorial16",
                 "point":true,
                 "properties":[
                        {
                         "name":"text",
                         "type":"string",
                         "value":"Move the mouse to the edge to pan the camera"
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3625.33333333333,
                 "y":2901.33333333333
                },
            
                {
                 "class":"",
                 "height":0,
                 "id":332,
                 "name":"Tutorial17",
                 "point":true,
                 "properties":[
                        {
                         "name":"text",
                         "type":"string",
                         "value":"You can also drag with the mouse anywhere to pan"
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3626.66666666667,
                 "y":3016
                }],
         "opacity":1,
         "type":"objectgroup",
         "visible":true,
         "x":0,
         "y":0
        }, 
        {
         "color":"#ff007f",
         "draworder":"topdown",
         "id":17,
         "name":"spawn-areas",
         "objects":[
                {
                 "class":"",
                 "height":0,
                 "id":315,
                 "name":"Bat spawn 1",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":56,
                         "y":88
                        }, 
                        {
                         "x":239,
                         "y":81
                        }, 
                        {
                         "x":398,
                         "y":-9
                        }, 
                        {
                         "x":401,
                         "y":-148
                        }, 
                        {
                         "x":241,
                         "y":-303
                        }, 
                        {
                         "x":95,
                         "y":-404
                        }, 
                        {
                         "x":-177,
                         "y":-382
                        }, 
                        {
                         "x":-155,
                         "y":-173
                        }, 
                        {
                         "x":-40,
                         "y":-142
                        }],
                 "properties":[
                        {
                         "name":"spawns",
                         "type":"object",
                         "value":312
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3476,
                 "y":1882
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":316,
                 "name":"Bat spawn 2",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-176,
                         "y":-100
                        }, 
                        {
                         "x":-381,
                         "y":-177
                        }, 
                        {
                         "x":-305,
                         "y":-426
                        }, 
                        {
                         "x":-28,
                         "y":-624
                        }, 
                        {
                         "x":203,
                         "y":-364
                        }, 
                        {
                         "x":373,
                         "y":-274
                        }, 
                        {
                         "x":496,
                         "y":-109
                        }, 
                        {
                         "x":445,
                         "y":120
                        }],
                 "properties":[
                        {
                         "name":"spawns",
                         "type":"object",
                         "value":312
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":2867,
                 "y":3438
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":317,
                 "name":"Broom spawn 1",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":180,
                         "y":-22
                        }, 
                        {
                         "x":466,
                         "y":-118
                        }, 
                        {
                         "x":506,
                         "y":-362
                        }, 
                        {
                         "x":413,
                         "y":-474
                        }, 
                        {
                         "x":-122,
                         "y":-397
                        }, 
                        {
                         "x":-375,
                         "y":-267
                        }, 
                        {
                         "x":-130,
                         "y":-140
                        }],
                 "properties":[
                        {
                         "name":"spawns",
                         "type":"object",
                         "value":314
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":4394,
                 "y":3948
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":318,
                 "name":"Broom spawn 2",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-556,
                         "y":110.666666666667
                        }, 
                        {
                         "x":-801.333333333334,
                         "y":513.333333333333
                        }, 
                        {
                         "x":-490.666666666667,
                         "y":748
                        }, 
                        {
                         "x":-86.666666666667,
                         "y":338.666666666667
                        }],
                 "properties":[
                        {
                         "name":"spawns",
                         "type":"object",
                         "value":314
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":5832,
                 "y":1856
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":319,
                 "name":"Wolf spawn 1",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":205.333333333333,
                         "y":340
                        }, 
                        {
                         "x":412,
                         "y":329.333333333333
                        }, 
                        {
                         "x":292,
                         "y":-29.3333333333333
                        }],
                 "properties":[
                        {
                         "name":"spawns",
                         "type":"object",
                         "value":313
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":6949.33333333333,
                 "y":1237.33333333333
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":320,
                 "name":"Wolf spawn 2",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-220,
                         "y":142.666666666667
                        }, 
                        {
                         "x":-135.333333333333,
                         "y":256.666666666667
                        }, 
                        {
                         "x":36.6666666666665,
                         "y":136.666666666667
                        }],
                 "properties":[
                        {
                         "name":"spawns",
                         "type":"object",
                         "value":313
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":3446.66666666667,
                 "y":1168.66666666667
                }, 
                {
                 "class":"",
                 "height":0,
                 "id":321,
                 "name":"Wolf spawn 3",
                 "polygon":[
                        {
                         "x":0,
                         "y":0
                        }, 
                        {
                         "x":-168.666666666667,
                         "y":56.666666666667
                        }, 
                        {
                         "x":-108.666666666667,
                         "y":326
                        }, 
                        {
                         "x":256.666666666667,
                         "y":331.333333333334
                        }, 
                        {
                         "x":283.333333333333,
                         "y":31.3333333333339
                        }],
                 "properties":[
                        {
                         "name":"spawns",
                         "type":"object",
                         "value":313
                        }],
                 "rotation":0,
                 "visible":true,
                 "width":0,
                 "x":1996.66666666667,
                 "y":4928.66666666667
                }],
         "opacity":1,
         "type":"objectgroup",
         "visible":false,
         "x":0,
         "y":0
        }],
 "nextlayerid":19,
 "nextobjectid":333,
 "orientation":"orthogonal",
 "renderorder":"right-down",
 "tiledversion":"1.9.2",
 "tileheight":16,
 "tilesets":[
        {
         "columns":1,
         "firstgid":1,
         "grid":
            {
             "height":1024,
             "orientation":"orthogonal",
             "width":512
            },
         "image":"truck.png",
         "imageheight":700,
         "imagewidth":1400,
         "margin":0,
         "name":"truck",
         "spacing":0,
         "tilecount":1,
         "tileheight":700,
         "tilewidth":1400
        }, 
        {
         "columns":1,
         "firstgid":2,
         "image":"blood-bag.png",
         "imageheight":1168,
         "imagewidth":1206,
         "margin":0,
         "name":"blood-bag",
         "spacing":0,
         "tilecount":1,
         "tileheight":1024,
         "tilewidth":1024
        }, 
        {
         "columns":1,
         "firstgid":3,
         "image":"meat.png",
         "imageheight":1250,
         "imagewidth":1250,
         "margin":0,
         "name":"meat",
         "spacing":0,
         "tilecount":1,
         "tileheight":1250,
         "tilewidth":1250
        }, 
        {
         "columns":1,
         "firstgid":4,
         "image":"hansel.png",
         "imageheight":500,
         "imagewidth":500,
         "margin":0,
         "name":"hansel",
         "spacing":0,
         "tilecount":1,
         "tileheight":500,
         "tilewidth":500
        }, 
        {
         "columns":1,
         "firstgid":5,
         "image":"gretel.png",
         "imageheight":500,
         "imagewidth":500,
         "margin":0,
         "name":"gretel",
         "spacing":0,
         "tilecount":1,
         "tileheight":500,
         "tilewidth":500
        }],
 "tilewidth":16,
 "type":"map",
 "version":"1.9",
 "width":1
}