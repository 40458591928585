import { Actor } from "@/engine/Actor";
import { ACTOR_TYPE_TRUCK } from "./Truck";
import { vec2len, vec2sub } from "@/math/Vector2";
import { DEBUG } from "@/constants";
import { PickupPointInfo } from "./PickupPointInfo";
const IMAGE_SIZE = 128;
export const PICKUP_DISTANCE = 100;
const PRODUCTION_DRAW_MARGIN = 12;
const PRODUCTION_DRAW_BOX_STYLE = "rgb(46, 31, 25)";
const PRODUCTION_DRAW_BOX_MARGIN = 3;
const PRODUCTION_DRAW_BOX_LINE_WIDTH = 2;
const PRODUCTION_DRAW_BOX_LINE_STYLE = "rgb(80, 53, 45)";
const PRODUCTION_DRAW_CIRCLE_STYLE = "rgb(160, 106, 90)";
const PRODUCTION_DRAW_CIRCLE_MARGIN = 2;
export const ACTOR_TYPE_PICKUP_POINT = "pickupPoint";
export class PickupPoint extends Actor {
    constructor(gameLogic) {
        super(gameLogic, ACTOR_TYPE_PICKUP_POINT);
        this.pickupPointId = Symbol();
        this.name = "No name";
        this.description = "No description";
        this.image = new Image();
        this.produces = {};
        this.maxCapacity = 1;
        this.productionCycleTime = 10;
        this.productionTimer = 0;
        this.stored = 0;
        this.closed = false;
        this.pickupAreaAnimationTimer = 0;
        this.isHovered = false;
        gameLogic.listen("resourcePickedUp", this);
        gameLogic.listen("pointerMove", this);
        const pickupPointInfo = new PickupPointInfo(gameLogic);
        pickupPointInfo.pickupPointId = this.pickupPointId;
        this.children.push(pickupPointInfo);
    }
    onEvent(eventName, eventData) {
        if (eventName === "resourcePickedUp") {
            if (eventData.pickupPointId === this.pickupPointId) {
                this.stored -= 1;
            }
        }
        else if (eventName === "pointerMove") {
            const { x, y } = eventData;
            const distance = vec2len(vec2sub(this.gameLogic.camera.toWorldSpace(x, y), this.position));
            if (distance < PICKUP_DISTANCE) {
                if (!this.isHovered) {
                    this.isHovered = true;
                    this.gameLogic.dispatch("pickupPointHovered", { pickupPointId: this.pickupPointId });
                }
            }
            else {
                if (this.isHovered) {
                    this.isHovered = false;
                    this.gameLogic.dispatch("pickupPointUnhovered", { pickupPointId: this.pickupPointId });
                }
            }
        }
    }
    drawProduction(context) {
        context.save();
        context.translate(this.position[0] + IMAGE_SIZE / 2 + PRODUCTION_DRAW_MARGIN, this.position[1] - IMAGE_SIZE / 2);
        const columnCount = Math.ceil(this.maxCapacity / 5);
        const rowCount = 5;
        const totalCellSize = IMAGE_SIZE / rowCount;
        const cellBoxSize = totalCellSize - 2 * PRODUCTION_DRAW_BOX_MARGIN;
        const cellCircleSize = cellBoxSize - 2 * PRODUCTION_DRAW_CIRCLE_MARGIN;
        context.fillStyle = PRODUCTION_DRAW_BOX_STYLE;
        for (let column = 0; column < columnCount; column++) {
            for (let row = 0; row < rowCount; row++) {
                if (column * rowCount + row >= this.maxCapacity) {
                    break;
                }
                context.fillRect(column * totalCellSize + PRODUCTION_DRAW_BOX_MARGIN, row * totalCellSize + PRODUCTION_DRAW_BOX_MARGIN, cellBoxSize, cellBoxSize);
            }
        }
        context.lineWidth = PRODUCTION_DRAW_BOX_LINE_WIDTH;
        context.strokeStyle = PRODUCTION_DRAW_BOX_LINE_STYLE;
        for (let column = 0; column < columnCount; column++) {
            for (let row = 0; row < rowCount; row++) {
                if (column * rowCount + row >= this.maxCapacity) {
                    break;
                }
                context.strokeRect(column * totalCellSize + PRODUCTION_DRAW_BOX_MARGIN, row * totalCellSize + PRODUCTION_DRAW_BOX_MARGIN, cellBoxSize, cellBoxSize);
            }
        }
        context.fillStyle = PRODUCTION_DRAW_CIRCLE_STYLE;
        for (let i = 0; i < this.stored; i++) {
            const column = Math.floor(i / rowCount);
            const row = i % rowCount;
            context.beginPath();
            context.arc(column * totalCellSize + totalCellSize / 2, row * totalCellSize + totalCellSize / 2, cellCircleSize / 2, 0, 2 * Math.PI);
            context.fill();
        }
        // Draw somewhat completed in the last cell, if stored < maxCapacity
        if (this.stored < this.maxCapacity) {
            const column = Math.floor(this.stored / rowCount);
            const row = this.stored % rowCount;
            const progress = this.productionTimer / this.productionCycleTime;
            context.beginPath();
            context.arc(column * totalCellSize + totalCellSize / 2, row * totalCellSize + totalCellSize / 2, cellCircleSize / 2, 0, 2 * Math.PI);
            context.fill();
            context.beginPath();
            context.arc(column * totalCellSize + totalCellSize / 2, row * totalCellSize + totalCellSize / 2, cellCircleSize / 2, -Math.PI / 2, -Math.PI / 2 + 2 * Math.PI * (1.0 - progress));
            context.lineTo(column * totalCellSize + totalCellSize / 2, row * totalCellSize + totalCellSize / 2);
            context.fillStyle = PRODUCTION_DRAW_BOX_STYLE;
            context.fill();
        }
        context.restore();
    }
    drawPickupArea(dt, context) {
        this.pickupAreaAnimationTimer += dt;
        context.save();
        context.translate(this.position[0], this.position[1]);
        context.rotate(this.pickupAreaAnimationTimer * 0.5);
        context.beginPath();
        context.strokeStyle = "rgba(46, 31, 25, 0.5)";
        context.lineWidth = 15.0;
        context.setLineDash([50, 50]);
        context.arc(0, 0, PICKUP_DISTANCE + 3.0 * Math.sin(this.pickupAreaAnimationTimer * 0.75), 0, 2 * Math.PI);
        context.stroke();
        context.setLineDash([0]);
        if (this.isHovered) {
            context.fillStyle = "rgba(46, 31, 25, 0.2)";
            context.fill();
        }
        context.restore();
    }
    onBeginDraw(dt, context) {
        context.save();
        context.translate(this.position[0], this.position[1]);
    }
    onEndDraw(dt, context) {
        const { position } = this;
        const { image } = this;
        context.restore();
        this.drawPickupArea(dt, context);
        this.drawProduction(context);
        if (DEBUG) {
            context.strokeStyle = "blue";
            context.beginPath();
            context.arc(position[0], position[1], PICKUP_DISTANCE, 0, 2 * Math.PI);
            context.stroke();
        }
    }
    onTick(dt) {
        if (!this.gameLogic.isPaused) {
            this.productionTimer += dt;
        }
        while (this.productionTimer >= this.productionCycleTime) {
            this.productionTimer -= this.productionCycleTime;
            this.stored += 1;
        }
        if (this.stored >= this.maxCapacity) {
            this.closed = true;
            this.productionTimer = 0;
            this.stored = this.maxCapacity;
            this.gameLogic.dispatch("pickupPointClosed", this);
        }
        if (this.closed) {
            return;
        }
        if (this.stored === 0) {
            return;
        }
        const trucks = this.gameLogic.allActors
            .filter(actor => actor.actorType === ACTOR_TYPE_TRUCK);
        const emptyTrucks = trucks.filter(truck => truck.carrying === null);
        for (let emptyTruck of emptyTrucks) {
            const truckDistance = vec2len(vec2sub(emptyTruck.position, this.position));
            if (truckDistance < PICKUP_DISTANCE) {
                this.gameLogic.dispatch("truckPickup", {
                    truckId: emptyTruck.truckId,
                    resourceId: this.produces.name,
                    velocityMultiplier: this.produces.properties.find(property => property.name === "velocityMultiplier").value,
                    pickupPointId: this.pickupPointId
                });
                break;
            }
        }
    }
}
