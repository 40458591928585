import { Actor } from "../engine/Actor";
import { vec2len, vec2rotate, vec2scale, vec2sub } from "../math/Vector2";
import { MoveTowardsBehaviour } from "@/steering/MoveTowardsBehaviour";
import { CompoundBehaviour } from "@/steering/CompoundBehaviour";
import { ArriveAtBehaviour } from "@/steering/ArriveAtBehaviour";
import { CollisionAvoidanceBehaviour } from "@/steering/CollisionAvoidanceBehaviour";
import { ACTOR_TYPE_OBSTACLE } from "./Obstacle";
import { ACTOR_TYPE_FOG_OF_WAR } from "./FogOfWar";
import { ACTOR_TYPE_WATER } from "./Water";
import { closestPointOnPolygon } from "@/math/Polygon";
import { IMAGES } from "@/assets/assets";
import { DEBUG } from "@/constants";
import { ACTOR_TYPE_PICKUP_POINT } from "./PickupPoint";
import { TruckPathPrediction } from "./TruckPathPrediction";
const TRUCK_IMAGE = new Image();
TRUCK_IMAGE.src = IMAGES["truck.png"];
const HORSE_IMAGE = new Image();
HORSE_IMAGE.src = IMAGES["horse.png"];
const TRUCK_SPEED = 100;
const TRUCK_ACCELERATION = 500;
const TRUCK_ROTATION_SPEED = 1;
const TRUCK_VELOCITY_ROTATION_SPEED_PENALTY = 100;
export const TRUCK_WIDTH = 96;
export const TRUCK_HEIGHT = 48;
const ITEM_WIDTH = 40;
const ITEM_HEIGHT = 40;
const TRUCK_VISION_RADIUS = 300;
export const DESTINATION_REACHED_DISTANCE = 40;
export const ACTOR_TYPE_TRUCK = "truck";
export const UPGRADE_MODIFIERS = [
    {
        speed: 1.0,
        rotationSpeed: 1.0
    },
    {
        speed: 1.2,
        rotationSpeed: 1.5
    },
    {
        speed: 1.5,
        rotationSpeed: 2.0
    },
    {
        speed: 1.7,
        rotationSpeed: 2.2
    },
    {
        speed: 2.0,
        rotationSpeed: 2.5
    }
];
export class Truck extends Actor {
    constructor(gameLogic, options) {
        super(gameLogic, ACTOR_TYPE_TRUCK);
        this.truckId = Symbol();
        this.destination = null;
        this.rotation = 0;
        this.isSelected = false;
        this.velocity = 0;
        this.carryVelocityMultiplier = 1;
        this.lastVelocityMultiplier = 1;
        this.steeringBehaviour = new CompoundBehaviour(new MoveTowardsBehaviour(), new ArriveAtBehaviour(), new CollisionAvoidanceBehaviour(TRUCK_HEIGHT - 30));
        this.options = {
            speed: TRUCK_SPEED,
            acceleration: TRUCK_ACCELERATION,
            rotationSpeed: TRUCK_ROTATION_SPEED,
            velocityRotationSpeedPenalty: TRUCK_VELOCITY_ROTATION_SPEED_PENALTY
        };
        this.carrying = null;
        this.carryingImage = null;
        this.pointerDownLocation = null;
        this.path = [];
        this.isShiftDown = false;
        this.isPatroling = false;
        this.patrolIndex = 0;
        this.animationTimer = 0;
        this.upgradeLevel = 0;
        if (options) {
            this.options = Object.assign(Object.assign({}, this.options), options);
        }
        gameLogic.listen("pointerUp", this);
        gameLogic.listen("pointerDown", this);
        gameLogic.listen("truckSelected", this);
        gameLogic.listen("truckPickup", this);
        gameLogic.listen("truckDrop", this);
        gameLogic.listen("enemyStrike", this);
        gameLogic.listen("keyDown", this);
        gameLogic.listen("keyUp", this);
        const truckPathPrediction = new TruckPathPrediction(gameLogic);
        truckPathPrediction.truckId = this.truckId;
        truckPathPrediction.predictionCount = 15;
        truckPathPrediction.predictionTimeStep = 0.1;
        this.children.push(truckPathPrediction);
    }
    get obstacles() {
        return this.gameLogic.allActors.filter(actor => actor.actorType === ACTOR_TYPE_OBSTACLE);
    }
    onGameLogicPointerDown(x, y) {
        this.pointerDownLocation = [x, y];
    }
    onGameLogicPointerUp(x, y) {
        const pointerUpDistance = vec2len(vec2sub(this.pointerDownLocation, [x, y]));
        if (this.isSelected && pointerUpDistance < 4) {
            // this.destination = this.gameLogic.camera.toWorldSpace(x, y);
            if (this.isShiftDown) {
                this.path.push(this.gameLogic.camera.toWorldSpace(x, y));
            }
            else {
                this.path = [this.gameLogic.camera.toWorldSpace(x, y)];
                this.destination = this.path[0];
            }
            this.isPatroling = false;
        }
    }
    onGameLogicTruckSelected(truckId) {
        this.isSelected = this.truckId === truckId;
    }
    onGameLogicTruckDeselected() {
        this.isSelected = false;
    }
    onGameLogicTruckPickup(truckId, resourceId, pickupPointId, velocityMultiplier) {
        if (this.truckId === truckId) {
            const pickupPoint = this.gameLogic.allActors
                .find(actor => actor.actorType === ACTOR_TYPE_PICKUP_POINT && actor.pickupPointId === pickupPointId);
            console.log(pickupPoint);
            this.carrying = resourceId;
            this.carryingImage = new Image();
            const imageName = pickupPoint.produces.properties
                .find(property => property.name === "image").value;
            this.carryingImage.src = IMAGES[imageName];
            this.carryVelocityMultiplier = velocityMultiplier;
            this.gameLogic.dispatch("resourcePickedUp", { truckId: this.truckId, resourceId, pickupPointId });
        }
    }
    onGameLogicTruckDrop(truckId, resourceId, dropPointId) {
        if (this.truckId === truckId && this.carrying === resourceId) {
            this.carrying = null;
            this.carryingImage = null;
            this.carryVelocityMultiplier = 1;
            this.gameLogic.dispatch("resourceDelivered", { truckId, resourceId, dropPointId });
        }
    }
    onGameLogicTruckUpgraded(truckId) {
        if (this.truckId === truckId && this.upgradeLevel < 4) {
            this.upgradeLevel++;
        }
    }
    onGameLogicEnemyStrike(truckId, enemyId) {
        if (this.truckId === truckId) {
            if (this.carrying) {
                this.carrying = null;
                this.carryingImage = null;
                this.gameLogic.dispatch("enemyFed", { enemyId });
            }
        }
    }
    onGameLogicKeyDown(key) {
        if (this.isSelected) {
            if (key === "Shift") {
                this.isShiftDown = true;
            }
        }
    }
    onGameLogicKeyUp(key) {
        if (this.isSelected) {
            if (key === "Shift") {
                this.isShiftDown = false;
            }
            else if (key === "p") {
                this.isPatroling = true;
                this.patrolIndex = 0;
            }
        }
    }
    calculateTerrainSpeedModifier() {
        let mod = 1.0;
        let closestModifierDistance = Infinity;
        const waterActors = this.gameLogic.allActors
            .filter(actor => actor.actorType === ACTOR_TYPE_WATER);
        for (let water of waterActors) {
            const closestPointToWater = closestPointOnPolygon(water, this.position);
            const closestWaterDistance = vec2len(vec2sub(this.position, closestPointToWater));
            if (closestModifierDistance > closestWaterDistance) {
                closestModifierDistance = closestWaterDistance;
                mod = water.modifier;
            }
        }
        if (closestModifierDistance < TRUCK_WIDTH / 2) {
            return mod;
        }
        return 1.0;
    }
    onTick(dt) {
        const upgradeModifier = UPGRADE_MODIFIERS[this.upgradeLevel];
        const accel = this.steeringBehaviour.acceleration(this, dt);
        const angularAccel = this.steeringBehaviour.angularAcceleration(this, dt);
        const accelUnits = accel * this.options.acceleration;
        let angularAccelUnits = angularAccel * this.options.rotationSpeed * upgradeModifier.rotationSpeed;
        if (this.velocity < this.options.velocityRotationSpeedPenalty) {
            angularAccelUnits *= this.velocity / this.options.velocityRotationSpeedPenalty;
        }
        this.velocity += accelUnits * dt;
        if (this.velocity > this.options.speed * upgradeModifier.speed) {
            this.velocity = this.options.speed * upgradeModifier.speed;
        }
        if (this.velocity < 0) {
            this.velocity = 0;
        }
        if (Math.abs(this.velocity) > 0 && !this.gameLogic.isPaused) {
            this.rotation += angularAccelUnits * dt;
            const velocityMultiplier = this.calculateTerrainSpeedModifier() * this.carryVelocityMultiplier;
            this.lastVelocityMultiplier = velocityMultiplier;
            this.position = vec2sub(this.position, vec2scale(vec2rotate([0, 1], this.rotation), this.velocity * velocityMultiplier * dt));
        }
        const fogOfWar = this.gameLogic.allActors
            .find(actor => actor.actorType === ACTOR_TYPE_FOG_OF_WAR);
        fogOfWar.discover(this.position, TRUCK_VISION_RADIUS);
        if (this.path.length > 0) {
            if (!this.destination) {
                this.destination = this.path[0];
            }
            const destinationDistance = this.destination
                ? vec2len(vec2sub(this.position, this.destination))
                : Infinity;
            if (destinationDistance < DESTINATION_REACHED_DISTANCE) {
                if (this.isPatroling) {
                    this.patrolIndex = (this.patrolIndex + 1) % this.path.length;
                    this.destination = this.path[this.patrolIndex];
                }
                else {
                    this.path.shift();
                    this.destination = this.path[0] || null;
                }
            }
        }
        else {
            this.destination = null;
            this.velocity = this.velocity + (0 - this.velocity) * 0.8;
        }
    }
    onBeginDraw(dt, context) {
        this.animationTimer += dt;
        context.save();
        context.translate(this.position[0], this.position[1]);
        context.rotate(this.rotation - Math.PI / 2);
        if (this.isSelected) {
            context.filter = "drop-shadow(1px 1px 0px #ffffff) drop-shadow(-1px -1px 0px #ffffff) drop-shadow(1px -1px 0px #ffffff) drop-shadow(-1px 1px 0px #ffffff)";
        }
        context.drawImage(TRUCK_IMAGE, -TRUCK_WIDTH / 2, -TRUCK_HEIGHT / 2, TRUCK_WIDTH, TRUCK_HEIGHT);
        context.rotate(-(this.rotation - Math.PI / 2));
        context.filter = "none";
        if (this.carryingImage) {
            context.drawImage(this.carryingImage, -ITEM_WIDTH / 2, -ITEM_WIDTH / 2, ITEM_WIDTH, ITEM_WIDTH);
        }
        if (DEBUG) {
            context.beginPath();
            context.fillStyle = "rgba(255, 255, 255, 0.25)";
            context.arc(0, 0, TRUCK_VISION_RADIUS, 0, 2 * Math.PI);
            context.fill();
            context.strokeStyle = "rgba(255, 255, 255, 0.85)";
            context.lineWidth = 1;
            context.rotate(this.rotation);
            context.strokeRect(-TRUCK_WIDTH / 2, -TRUCK_HEIGHT / 2, TRUCK_WIDTH, TRUCK_HEIGHT);
            context.rotate(-this.rotation);
        }
    }
    onEndDraw(dt, context) {
        context.restore();
    }
    onEvent(eventName, eventData) {
        if (eventName === "pointerUp") {
            this.onGameLogicPointerUp(eventData.x, eventData.y);
        }
        else if (eventName === "truckSelected") {
            this.onGameLogicTruckSelected(eventData);
        }
        else if (eventName === "truckDeselected") {
            this.onGameLogicTruckDeselected();
        }
        else if (eventName === "truckPickup") {
            this.onGameLogicTruckPickup(eventData.truckId, eventData.resourceId, eventData.pickupPointId, eventData.velocityMultiplier);
        }
        else if (eventName === "truckDrop") {
            this.onGameLogicTruckDrop(eventData.truckId, eventData.resourceId, eventData.dropPointId);
        }
        else if (eventName === "truckUpgrade") {
            this.onGameLogicTruckUpgraded(eventData.truckId);
        }
        else if (eventName === "pointerDown") {
            this.onGameLogicPointerDown(eventData.x, eventData.y);
        }
        else if (eventName === "keyDown") {
            this.onGameLogicKeyDown(eventData.key);
        }
        else if (eventName === "keyUp") {
            this.onGameLogicKeyUp(eventData.key);
        }
        else if (eventName === "enemyStrike") {
            this.onGameLogicEnemyStrike(eventData.truckId, eventData.enemyId);
        }
    }
    onPointerUp() {
        if (this.isSelected) {
            this.isSelected = false;
            this.gameLogic.dispatch("truckDeselected", null);
        }
        else {
            this.isSelected = true;
            this.gameLogic.dispatch("truckSelected", this.truckId);
        }
    }
    containsPointer(sx, sy) {
        const [x, y] = this.gameLogic.camera.toWorldSpace(sx, sy);
        return x > this.position[0] - (TRUCK_WIDTH / 2) && x < this.position[0] + (TRUCK_WIDTH / 2) &&
            y > this.position[1] - (TRUCK_HEIGHT / 2) && y < this.position[1] + (TRUCK_HEIGHT / 2);
    }
}
