import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useEffect } from 'react';
import { TopUI } from './TopUI';
import { GameOver } from './GameOver';
import { BottomUI } from './BottomUI';
export function LD53Game(options) {
    const canvasRef = useRef(null);
    const gameLogic = options.gameLogic;
    let onResize = () => {
        console.log("LD53Game.onResize");
        if (canvasRef.current) {
            canvasRef.current.width = window.innerWidth;
            canvasRef.current.height = window.innerHeight;
        }
    };
    let onAnimationFrame = () => {
        gameLogic.onAnimationFrame();
        requestAnimationFrame(onAnimationFrame);
    };
    let onCanvasMouseDown = (event) => {
        gameLogic.onMouseDown(event.clientX, event.clientY);
    };
    let onCanvasMouseUp = (event) => {
        gameLogic.onMouseUp(event.clientX, event.clientY);
    };
    let onCanvasMouseMove = (event) => {
        gameLogic.onMouseMove(event.clientX, event.clientY);
    };
    let onCanvasMouseLeave = (event) => {
        gameLogic.onMouseLeave();
    };
    let onCanvasMouseScroll = (event) => {
        gameLogic.onMouseScroll(event.deltaY);
    };
    let onWindowKeyDown = (event) => {
        gameLogic.onKeyDown(event.key);
    };
    let onWindowKeyUp = (event) => {
        gameLogic.onKeyUp(event.key);
    };
    useEffect(() => {
        options.addOnResizeListener(onResize);
        options.addOnCommenceListener(onAnimationFrame);
        window.addEventListener("keydown", onWindowKeyDown);
        window.addEventListener("keyup", onWindowKeyUp);
    }, []);
    return _jsxs("div", Object.assign({ className: "game" }, { children: [_jsx("div", Object.assign({ className: "game-layer" }, { children: _jsx("canvas", { className: "game-canvas", ref: canvasRef, onMouseDown: onCanvasMouseDown, onMouseUp: onCanvasMouseUp, onMouseMove: onCanvasMouseMove, onMouseLeave: onCanvasMouseLeave, onWheel: onCanvasMouseScroll }) })), _jsx("div", Object.assign({ className: "game-layer no-pointer-events" }, { children: _jsx(TopUI, { gameLogic: gameLogic }) })), _jsx("div", Object.assign({ className: "game-layer no-pointer-events" }, { children: _jsx(BottomUI, { gameLogic: gameLogic }) })), _jsx("div", Object.assign({ className: "game-layer no-pointer-events" }, { children: _jsx(GameOver, { gameLogic: gameLogic }) }))] }));
}
