import { SteeringBehaviour } from "./SteeringBehaviour";

export class CompoundBehaviour<T> implements SteeringBehaviour<T> {
    private readonly behaviours: SteeringBehaviour<T>[];

    constructor(...behaviours: SteeringBehaviour<T>[]) {
        this.behaviours = behaviours;
    }

    acceleration(
        actor: T,
        dt: number
    ): number {
        let acceleration = 0;

        for (const behaviour of this.behaviours) {
            acceleration += behaviour.acceleration(actor, dt);
        }

        return acceleration;
    }

    angularAcceleration(
        actor: T,
        dt: number
    ): number {
        let angularAcceleration = 0;

        for (const behaviour of this.behaviours) {
            angularAcceleration += behaviour.angularAcceleration(actor, dt);
        }

        return angularAcceleration;
    }
}