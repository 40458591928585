import { Actor } from "@/engine/Actor";
import { GameLogic } from "@/engine/GameLogic";

export const ACTOR_TYPE_SOUND_MUTER = "sound-muter";

export class SoundMuter extends Actor {

    private isMuted: boolean = false;

    constructor(
        gameLogic: GameLogic
    ) {
        super(gameLogic, ACTOR_TYPE_SOUND_MUTER);

        gameLogic.listen("keyUp", this);
    }

    public onEvent(eventName: string, eventData: any): void {
        if (eventName === "keyUp") {
            if (eventData.key === "m") {
                this.isMuted = !this.isMuted;
                if (this.isMuted) {
                    this.gameLogic.dispatch("muteSound", {});
                } else {
                    this.gameLogic.dispatch("unmuteSound", {});
                }
            }
        }
    }

}