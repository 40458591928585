import { jsx as _jsx } from "react/jsx-runtime";
import { createRoot } from "react-dom/client";
import { LD53Game } from "./components/LD53Game";
import { GameLogic } from "./engine/GameLogic";
class GameApp {
    constructor() {
        this.gameLogic = new GameLogic();
        this.onResizeListeners = [];
        this.onCommenceListeners = [];
    }
    addOnResizeListener(listener) {
        this.onResizeListeners.push(listener);
    }
    addOnCommenceListener(listener) {
        this.onCommenceListeners.push(listener);
    }
    onWindowResized() {
        this.onResizeListeners.forEach(listener => listener());
    }
    onCommence() {
        this.onCommenceListeners.forEach(listener => listener());
    }
    start() {
        this.reactRoot = createRoot(document.getElementById("root"));
        this.reactRoot.render(_jsx(LD53Game, { addOnResizeListener: this.addOnResizeListener.bind(this), addOnCommenceListener: this.addOnCommenceListener.bind(this), gameLogic: this.gameLogic }));
        window.addEventListener("resize", this.onWindowResized.bind(this));
        setTimeout(() => requestAnimationFrame(() => {
            this.gameLogic.initElements(document.querySelector(".game-canvas"));
            this.onWindowResized();
            this.onCommence();
        }), 0);
    }
}
const app = new GameApp();
app.start();
