import { Actor } from "@/engine/Actor";
import { vec2add, vec2len, vec2norm, vec2scale, vec2sub } from "@/math/Vector2";
import { ACTOR_TYPE_SPAWN_AREA } from "./SpawnArea";
import { ACTOR_TYPE_TRUCK } from "./Truck";
export const ACTOR_TYPE_ENEMY = "enemy";
export const ENEMY_WIDTH = 48;
export const ENEMY_HEIGHT = 48;
export const ENEMY_SPEED = 40.0;
export const ENEMY_HOSTILE_SPEED = 150.0;
export const ENEMY_SHADOW_OFFSET = 12;
export const ENEMY_SHADOW_WIDTH = 20;
export const ENEMY_SHADOW_HEIGHT = 10;
export const ENEMY_ATTACK_DISTANCE = 100.0;
export const ENEMY_STRIKE_DISTANCE = 10.0;
export class Enemy extends Actor {
    get state() {
        if (this.health < 0.5) {
            return "hostile";
        }
        if (this.hunger >= 1.0) {
            return "dying";
        }
        return (this.timer % (this.idleTime + this.movingTime)) < this.idleTime ? "idle" : "moving";
    }
    constructor(gameLogic) {
        super(gameLogic, ACTOR_TYPE_ENEMY);
        this.enemyId = Symbol();
        this.dropPointId = null;
        this.spawnAreaId = null;
        this.species = null;
        this.image = null;
        this.health = 1.0;
        this.hunger = 0.0;
        this.hungerRate = 0.0025;
        this.damageRate = 0.0025;
        this.idleTime = 5.0;
        this.movingTime = 1.0;
        this.velocity = [0, 0];
        this.timer = 0.0;
        this.previousState = "idle";
        this.offsetY = 0.0;
        this.dead = false;
        gameLogic.listen("enemyFed", this);
    }
    onBeginDraw(dt, context) {
        if (this.dead) {
            return;
        }
        context.save();
        context.translate(this.position[0], this.position[1]);
        if (this.image) {
            context.drawImage(this.image, -ENEMY_WIDTH / 2, -ENEMY_HEIGHT / 2 + this.offsetY, ENEMY_WIDTH, ENEMY_HEIGHT);
            context.fillStyle = "rgba(0, 0, 0, 0.5)";
            context.beginPath();
            context.ellipse(0, ENEMY_HEIGHT / 2 + ENEMY_SHADOW_OFFSET, ENEMY_SHADOW_WIDTH, ENEMY_SHADOW_HEIGHT, 0, 0, 2 * Math.PI);
            context.fill();
            if (this.health < 1.0) {
                context.fillStyle = "rgba(0, 0, 0, 0.5)";
                context.beginPath();
                context.roundRect(-ENEMY_WIDTH / 2 - 1, -ENEMY_HEIGHT / 2 - 20 - 1, ENEMY_WIDTH + 2, 7);
                context.fill();
                context.beginPath();
                context.fillStyle = "rgba(255, 0, 0, 0.5)";
                context.beginPath();
                context.roundRect(-ENEMY_WIDTH / 2, -ENEMY_HEIGHT / 2 - 20, ENEMY_WIDTH * this.health, 5);
                context.fill();
            }
            if (this.hunger > 0.0) {
                context.fillStyle = "rgba(0, 0, 0, 0.5)";
                context.beginPath();
                context.roundRect(-ENEMY_WIDTH / 2 - 1, -ENEMY_HEIGHT / 2 - 30 - 1, ENEMY_WIDTH + 2, 7);
                context.fill();
                context.beginPath();
                context.fillStyle = "rgba(255, 255, 0, 0.5)";
                context.beginPath();
                context.roundRect(-ENEMY_WIDTH / 2, -ENEMY_HEIGHT / 2 - 30, ENEMY_WIDTH * this.hunger, 5);
                context.fill();
            }
        }
    }
    onEndDraw(dt, context) {
        if (this.dead) {
            return;
        }
        context.restore();
    }
    randomWanderPoint() {
        const spawnArea = this.gameLogic.allActors
            .find(actor => actor.actorType === ACTOR_TYPE_SPAWN_AREA && actor.spawnAreaId === this.spawnAreaId);
        if (spawnArea) {
            let point = spawnArea.randomSpawnPoint();
            return point;
        }
        return [0, 0];
    }
    attackPlayer(dt) {
        let trucks = this.gameLogic.allActors
            .filter(actor => actor.actorType === ACTOR_TYPE_TRUCK);
        trucks = trucks.filter(truck => truck.carrying !== null);
        if (trucks.length === 0) {
            return;
        }
        const closestTruck = trucks
            .sort((a, b) => {
            const aDistance = vec2len(vec2sub(a.position, this.position));
            const bDistance = vec2len(vec2sub(b.position, this.position));
            return aDistance - bDistance;
        })[0];
        const closestTruckDistance = vec2len(vec2sub(closestTruck.position, this.position));
        if (closestTruckDistance < ENEMY_STRIKE_DISTANCE) {
            this.gameLogic.dispatch("enemyStrike", {
                enemyId: this.enemyId,
                truckId: closestTruck.truckId
            });
        }
        if (closestTruckDistance < ENEMY_ATTACK_DISTANCE) {
            this.velocity = vec2scale(vec2norm(vec2sub(closestTruck.position, this.position)), ENEMY_HOSTILE_SPEED);
            this.position = vec2add(this.position, vec2scale(this.velocity, dt));
        }
    }
    onTick(dt) {
        if (this.dead) {
            return;
        }
        this.timer += dt;
        if (this.gameLogic.isPaused) {
            return;
        }
        const state = this.state;
        /**
            float parabola( float x, float k )
            {
                return pow( 4.0*x*(1.0-x), k );
            }
         */
        if (state === "hostile") {
            this.hunger += this.hungerRate * dt;
            this.health -= this.damageRate * dt;
            this.offsetY = Math.sin(this.timer * 20.0) * 6.0;
            this.attackPlayer(dt);
        }
        else if (state === "dying") {
            this.hunger += this.hungerRate * dt;
            this.health -= this.damageRate * dt;
            this.offsetY = Math.sin(this.timer * 8.0) * 6.0;
        }
        else if (state === "moving") {
            if (this.previousState === "idle") {
                const randomPoint = this.randomWanderPoint();
                this.velocity = vec2sub(randomPoint, this.position);
                this.velocity = vec2norm(this.velocity);
            }
            this.hunger += this.hungerRate * dt;
            this.position[0] += this.velocity[0] * ENEMY_SPEED * dt;
            this.position[1] += this.velocity[1] * ENEMY_SPEED * dt;
            this.offsetY = Math.sin(this.timer * 16.0) * 6.0;
        }
        else if (state === "idle") {
            this.hunger += this.hungerRate * dt;
            this.offsetY = Math.sin(this.timer * 4.0) * 6.0;
        }
        this.previousState = state;
        if (this.health <= 0.0) {
            this.dead = true;
        }
        if (this.hunger >= 1.0) {
            this.hunger = 1.0;
        }
    }
    onEvent(eventName, eventData) {
        if (this.dead) {
            return;
        }
        if (eventName === "enemyFed") {
            if (eventData.enemyId === this.enemyId) {
                this.hunger = 0;
                this.health = 1.0;
            }
        }
    }
}
