import { HasPosition } from "@/trait/HasPosition";
import { Vector2 } from "../math/Vector2";
import { GameLogic } from "./GameLogic";

export const ACTOR_TYPE_UNDEFINED = "undefined";

export class Actor implements HasPosition {

    public position: Vector2 = [0, 0];
    public children: Array<Actor> = [];
    public wasHovered: boolean = false;
    public wasPressed: boolean = false;

    constructor(
        public readonly gameLogic: GameLogic,
        public readonly actorType: string = ACTOR_TYPE_UNDEFINED
    ) { }

    public onTick(dt: number): void {

    }

    public onBeginDraw(
        dt: number,
        context: CanvasRenderingContext2D
    ): void {
    }

    public onEndDraw(
        dt: number,
        context: CanvasRenderingContext2D
    ): void {
    }

    public containsPointer(
        x: number,
        y: number
    ): boolean {
        return false;
    }

    public onPointerDown(): void {

    }

    public onPointerUp(): void {

    }

    public onHovered(): void {

    }

    public onUnhovered(): void {

    }

    public onEvent(
        eventName: string,
        eventData: any
    ): void {

    }

}