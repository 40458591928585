import { vec2add, vec2lerp, vec2scale, vec2sub } from "@/math/Vector2";
const zoomLevels = [1.0, 1.3, 1.6, 2.0];
export class Camera {
    constructor() {
        /** The top-left corner of the view */
        this.position = [0, 100];
        /** The current zoom level. Must be >= 1.0 & <= 4.0. */
        this.zoom = 1;
        /** The current movement vector. The camera interpolates its velocity to eventually match this value. */
        this.movement = [0, 0];
        /** The current velocity of the camera. */
        this.velocity = [0, 0];
        /** The interpolation factor when interpolating velocity towards movement vector. */
        this.movementLerpFactor = 0.95;
    }
    /** Retreives the center of the screen in world coordinates */
    getCenter(canvasSize) {
        return this.toWorldSpace(canvasSize[0] / 2, canvasSize[1] / 2);
    }
    /** Translates (x, y) into world space coordinates */
    toWorldSpace(x, y) {
        let screenPos = [x, y];
        const zoomLevel = zoomLevels[this.zoom - 1];
        let worldPos = vec2add(vec2scale(screenPos, zoomLevel), this.position);
        return worldPos;
    }
    /** Translates (x, y) into screen space coordinates */
    toScreenSpace(x, y) {
        let worldPos = [x, y];
        const zoomLevel = zoomLevels[this.zoom - 1];
        let screenPos = vec2scale(vec2sub(worldPos, this.position), 1 / zoomLevel);
        return screenPos;
    }
    onTick(dt) {
        this.position = vec2add(this.position, vec2scale(this.velocity, dt));
        // Use vec2lerp to smoothly transition the velocity towards the movement vector.
        this.velocity = vec2lerp(this.velocity, this.movement, 0.95);
    }
    onBeginDraw(dt, context) {
        context.save();
        const zoomLevel = zoomLevels[this.zoom - 1];
        context.scale(1 / zoomLevel, 1 / zoomLevel);
        context.translate(Math.floor(-this.position[0]), Math.floor(-this.position[1]));
    }
    onEndDraw(dt, context) {
        context.restore();
    }
}
