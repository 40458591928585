import { Actor } from "@/engine/Actor";
import { ACTOR_TYPE_PICKUP_POINT, PICKUP_DISTANCE } from "./PickupPoint";
export const ACTOR_TYPE_PICKUP_POINT_INFO = "pickupPointInfo";
const INFO_BOX_WIDTH = 500;
const INFO_BOX_HEIGHT = 80;
export class PickupPointInfo extends Actor {
    constructor(gameLogic) {
        super(gameLogic, ACTOR_TYPE_PICKUP_POINT_INFO);
        this.pickupPointId = null;
        this.visible = false;
        gameLogic.listen("pickupPointHovered", this);
        gameLogic.listen("pickupPointUnhovered", this);
    }
    onEvent(eventName, eventData) {
        if (eventName === "pickupPointHovered") {
            if (eventData.pickupPointId === this.pickupPointId) {
                this.visible = true;
            }
            else {
                this.visible = false;
            }
        }
        else if (eventName === "pickupPointUnhovered") {
            if (eventData.pickupPointId === this.pickupPointId) {
                this.visible = false;
            }
        }
    }
    onBeginDraw(dt, context) {
        if (this.visible) {
            const pickupPoint = this.gameLogic.allActors
                .filter(actor => actor.actorType === ACTOR_TYPE_PICKUP_POINT)
                .find(actor => actor.pickupPointId === this.pickupPointId);
            context.fillStyle = "rgba(46, 31, 25, 0.25)";
            context.beginPath();
            context.roundRect(-INFO_BOX_WIDTH / 2, -PICKUP_DISTANCE - INFO_BOX_HEIGHT - 20, INFO_BOX_WIDTH, INFO_BOX_HEIGHT, 12);
            context.fill();
            context.fillStyle = "#ffffff";
            context.font = "bold 1.25em sans-serif";
            context.textAlign = "center";
            context.fillText(pickupPoint.name, 0, -PICKUP_DISTANCE - INFO_BOX_HEIGHT, INFO_BOX_WIDTH);
            context.font = "1em sans-serif";
            context.textAlign = "left";
            context.fillText(pickupPoint.description, -INFO_BOX_WIDTH / 2 + 10, -PICKUP_DISTANCE - INFO_BOX_HEIGHT + 30, INFO_BOX_WIDTH - 20);
        }
    }
}
