import { Actor } from "@/engine/Actor";
import { GameLogic } from "@/engine/GameLogic";
import { Vector2, vec2norm } from "@/math/Vector2";

export class CameraController extends Actor {

    private edgeMovementSpeed = 1500.0;
    private edgeSize = 50.0;
    private edgeLeft = false;
    private edgeRight = false;
    private edgeTop = false;
    private edgeBottom = false;
    private dragging = false;
    private dragStart: Vector2 = [0, 0];
    private dragStartCamera: Vector2 = [0, 0];
    private pointerLocation: Vector2 = [0, 0];

    constructor(
        gameLogic: GameLogic
    ) {
        super(gameLogic);

        gameLogic.listen("pointerMove", this);
        gameLogic.listen("pointerLeave", this);
        gameLogic.listen("pointerDown", this);
        gameLogic.listen("pointerUp", this);
        gameLogic.listen("pointerScroll", this);
    }

    public onEvent(eventName: string, eventData: any): void {
        if (eventName === "pointerMove") {
            const { x, y } = eventData;
            this.pointerLocation = [x, y];
            const canvasSize = this.gameLogic.canvasSize;

            this.edgeLeft = x < this.edgeSize;
            this.edgeRight = x > canvasSize[0] - this.edgeSize;
            this.edgeTop = y < this.edgeSize;
            this.edgeBottom = y > canvasSize[1] - this.edgeSize;
        } else if (eventName === "pointerLeave") {
            this.edgeLeft = false;
            this.edgeRight = false;
            this.edgeTop = false;
            this.edgeBottom = false;
        } else if (eventName === "pointerDown") {
            this.dragging = true;
            this.dragStart = [eventData.x, eventData.y];
            this.dragStartCamera = [...this.gameLogic.camera.position];
        } else if (eventName === "pointerUp") {
            this.dragging = false;
        } else if (eventName === "pointerScroll") {
            const camera = this.gameLogic.camera;
            const zoom = camera.zoom;
            const newZoom = zoom + Math.sign(eventData.delta);
            if (newZoom < 1) {
                camera.zoom = 1;
            } else if (newZoom > 4) {
                camera.zoom = 4;
            } else {
                camera.zoom = newZoom;
            }
        }
    }

    private dragCamera(): void {

        const camera = this.gameLogic.camera;

        const dx = this.dragStart[0] - this.pointerLocation[0];
        const dy = this.dragStart[1] - this.pointerLocation[1];

        if (Math.abs(dx) > 2 || Math.abs(dy) > 2) {

            const x = this.dragStartCamera[0] + dx;
            const y = this.dragStartCamera[1] + dy;

            camera.position = [
                Math.floor(x),
                Math.floor(y)
            ];

        }

    }

    private moveCamera(dt: number): void {

        const camera = this.gameLogic.camera;

        let movement: Vector2 = [0, 0];

        if (this.edgeLeft) {
            movement[0] = -1;
        }
        if (this.edgeRight) {
            movement[0] = 1;
        }
        if (this.edgeTop) {
            movement[1] = -1;
        }
        if (this.edgeBottom) {
            movement[1] = 1;
        }

        movement = vec2norm(movement);

        if (movement[0] !== 0 || movement[1] !== 0) {
            camera.movement = [
                movement[0] * this.edgeMovementSpeed,
                movement[1] * this.edgeMovementSpeed
            ];
        } else {
            camera.movement = [0, 0];
        }

    }

    public onTick(dt: number): void {

        if (this.dragging) {
            this.dragCamera();
        } else {
            this.moveCamera(dt);
        }

    }

}