import { LOADED_SOUNDS, VOLUME_OVERRIDES } from "@/assets/assets";
import { Actor } from "@/engine/Actor";
export const ACTOR_TYPE_AMBIENT = "ambient";
export class Ambient extends Actor {
    constructor(gameLogic) {
        super(gameLogic, ACTOR_TYPE_AMBIENT);
        this.isMuted = false;
        this.effectName = null;
        this.points = [];
        this.radiusValues = [];
        const soundData = LOADED_SOUNDS[this.effectName];
        if (!soundData) {
            return;
        }
        soundData.addEventListener("canplaythrough", () => {
            soundData.loop = true;
            soundData.play();
        });
        gameLogic.listen("muteSound", this);
        gameLogic.listen("unmuteSound", this);
    }
    onTick(dt) {
        if (!this.effectName) {
            return;
        }
        const soundData = LOADED_SOUNDS[this.effectName];
        if (!soundData) {
            return;
        }
        if (soundData.paused) {
            try {
                soundData.play();
            }
            catch (ignore) { }
        }
        if (this.isMuted) {
            soundData.volume = 0;
            return;
        }
        let closestDistance = Infinity;
        const center = this.gameLogic.camera.getCenter(this.gameLogic.canvasSize);
        let pointIndex = 0;
        for (const point of this.points) {
            const distance = Math.sqrt(Math.pow(center[0] - point[0], 2) +
                Math.pow(center[1] - point[1], 2));
            const radius = this.radiusValues[pointIndex];
            if (distance < radius) {
                const percent = distance / radius;
                closestDistance = Math.min(closestDistance, percent);
            }
            ++pointIndex;
        }
        if (closestDistance <= 1.0) {
            soundData.volume = 1.0 - closestDistance;
        }
        else {
            soundData.volume = 0;
        }
        if (this.effectName in VOLUME_OVERRIDES) {
            soundData.volume *= VOLUME_OVERRIDES[this.effectName];
        }
    }
    onEvent(eventName, eventData) {
        if (eventName === "muteSound") {
            this.isMuted = true;
            console.log("MUTED");
        }
        else if (eventName === "unmuteSound") {
            this.isMuted = false;
        }
    }
}
