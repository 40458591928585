export const ACTOR_TYPE_UNDEFINED = "undefined";
export class Actor {
    constructor(gameLogic, actorType = ACTOR_TYPE_UNDEFINED) {
        this.gameLogic = gameLogic;
        this.actorType = actorType;
        this.position = [0, 0];
        this.children = [];
        this.wasHovered = false;
        this.wasPressed = false;
    }
    onTick(dt) {
    }
    onBeginDraw(dt, context) {
    }
    onEndDraw(dt, context) {
    }
    containsPointer(x, y) {
        return false;
    }
    onPointerDown() {
    }
    onPointerUp() {
    }
    onHovered() {
    }
    onUnhovered() {
    }
    onEvent(eventName, eventData) {
    }
}
