import { Actor } from "@/engine/Actor";
import { GameLogic } from "@/engine/GameLogic";
import { ACTOR_TYPE_PICKUP_POINT, PickupPoint } from "./PickupPoint";
import { ACTOR_TYPE_ENEMY, Enemy } from "./Enemy";

export const ACTOR_TYPE_GAME_PROGRESS = "gameProgress";

export class GameProgress extends Actor {

    private timer: number = 0.0;
    public score: number = 0;

    constructor(
        gameLogic: GameLogic
    ) {
        super(gameLogic, ACTOR_TYPE_GAME_PROGRESS);

        this.gameLogic.listen("resourceDelivered", this);
    }

    public onEvent(eventName: string, eventData: any): void {
        if (eventName === "resourceDelivered") {
            this.score += 3;
            this.gameLogic.dispatch("scoreChanged", this.score);
        }
    }

    private checkLossCondition(): void {

        if (this.timer < 2.0) {
            return;
        }

        const aliveEnemies = this.gameLogic.allActors.filter(
            actor => actor.actorType === ACTOR_TYPE_ENEMY && (actor as any).dead === false
        ) as Array<Enemy>;

        const aliveSpecies = new Set(
            aliveEnemies.map(enemy => enemy.species)
        );

        if (aliveSpecies.size !== 3) {
            this.gameLogic.dispatch(
                "gameOver",
                {
                    score: this.score,
                    reason: "You let a species go extinct!"
                }
            );
        }

    }

    public onTick(dt: number): void {

        this.timer += dt;

        this.checkLossCondition();
    }

    public changeScore(amount: number): void {
        this.score += amount;
        this.gameLogic.dispatch("scoreChanged", this.score);
    }
}