import { Actor } from "@/engine/Actor";
import { GameLogic } from "@/engine/GameLogic";
import { ACTOR_TYPE_GAME_PROGRESS, GameProgress } from "./GameProgress";
import { ACTOR_TYPE_TRUCK, Truck } from "./Truck";

export const TRUCK_UPGRADE_CONTROLLER = "truck-upgrade-controller";

export class TruckUpgradeController extends Actor {

    constructor(
        gameLogic: GameLogic
    ) {
        super(gameLogic, TRUCK_UPGRADE_CONTROLLER);

        gameLogic.listen("tryUpgradeTruck", this);
    }

    public onEvent(eventName: string, eventData: any): void {
        if (eventName === "tryUpgradeTruck") {
            const gameProgress = this.gameLogic.allActors.find(
                actor => actor.actorType === ACTOR_TYPE_GAME_PROGRESS
            ) as GameProgress;
            const truck = this.gameLogic.allActors.find(
                actor => actor.actorType === ACTOR_TYPE_TRUCK && (actor as any).truckId === eventData.truckId
            ) as Truck;

            console.log("tryUpgradeTruck", truck, gameProgress, eventData);

            if (truck.upgradeLevel >= 4) {
                return;
            }

            const upgradeCost = 2 * (truck.upgradeLevel + 1);

            if (gameProgress.score < upgradeCost) {
                return;
            }

            gameProgress.changeScore(-upgradeCost);
            truck.upgradeLevel += 1;
            this.gameLogic.dispatch(
                "truckUpgrade", {
                truckId: truck.truckId,
            });
        }
    }

}