import { Level } from "../content/Level";
import { Camera } from "./Camera";
import { CameraController } from "@/content/CameraController";
import { GameProgress } from "@/content/GameProgress";
import { FogOfWar } from "@/content/FogOfWar";
import { SFX } from "@/assets/assets";
import { SoundEffect } from "@/content/SoundEffect";
import { SoundEffectController } from "@/content/SoundEffectController";
import { TruckUpgradeController } from "@/content/TruckUpgradeController";
import { BuyTruckController } from "@/content/BuyTruckController";
import { PauseController } from "@/content/PauseController";
import { Music } from "@/content/Music";
import { SoundMuter } from "@/content/SoundMuter";
export class GameLogic {
    constructor() {
        this.lastFrameTime = new Date().getTime();
        this.actors = [];
        this.listenerActors = {};
        this.camera = new Camera();
        this.isPaused = false;
        this.actors.push(new GameProgress(this));
        this.actors.push(new Level(this));
        this.actors.push(new FogOfWar(this));
        this.actors.push(new Music(this));
        for (let sfxSoundName of SFX) {
            const sfx = new SoundEffect(this);
            sfx.effectName = sfxSoundName;
            this.actors.push(sfx);
        }
        this.actors.push(new CameraController(this));
        this.actors.push(new SoundEffectController(this));
        this.actors.push(new TruckUpgradeController(this));
        this.actors.push(new BuyTruckController(this));
        this.actors.push(new PauseController(this));
        this.actors.push(new SoundMuter(this));
    }
    get allActors() {
        let q = [...this.actors];
        let result = [];
        while (q.length > 0) {
            let actor = q.shift();
            result.push(actor);
            if (Array.isArray(actor.children)) {
                q.push(...actor.children);
            }
        }
        return result;
    }
    get canvasSize() {
        return [this.canvas.width * this.camera.zoom, this.canvas.height * this.camera.zoom];
    }
    initElements(canvas) {
        this.canvas = canvas;
        this.context = canvas.getContext("2d");
    }
    tickActor(actor, dt) {
        actor.onTick(dt);
        if (Array.isArray(actor.children)) {
            for (let child of actor.children) {
                this.tickActor(child, dt);
            }
        }
    }
    drawActor(actor, dt) {
        actor.onBeginDraw(dt, this.context);
        if (Array.isArray(actor.children)) {
            for (let child of actor.children) {
                this.drawActor(child, dt);
            }
        }
        actor.onEndDraw(dt, this.context);
    }
    onAnimationFrame() {
        if (!this.context) {
            return;
        }
        const now = new Date().getTime();
        const dtMs = now - this.lastFrameTime;
        let dt = dtMs / 1000.0;
        if (dt > 0.1) {
            dt = 0.1;
        }
        this.lastFrameTime = now;
        for (let actor of this.actors) {
            this.tickActor(actor, dt);
        }
        this.camera.onTick(dt);
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.camera.onBeginDraw(dt, this.context);
        for (let actor of this.actors) {
            this.drawActor(actor, dt);
        }
        this.camera.onEndDraw(dt, this.context);
    }
    onMouseDown(x, y) {
        let handled = false;
        for (let actor of this.allActors) {
            if (actor.containsPointer(x, y)) {
                if (!actor.wasPressed) {
                    actor.onPointerDown();
                    actor.wasPressed = true;
                    handled = true;
                    break;
                }
            }
        }
        if (!handled) {
            this.dispatch("pointerDown", { x, y });
        }
    }
    onMouseUp(x, y) {
        let handled = false;
        for (let actor of this.allActors) {
            if (actor.containsPointer(x, y)) {
                if (actor.wasPressed) {
                    actor.onPointerUp();
                    actor.wasPressed = false;
                    handled = true;
                    break;
                }
            }
        }
        if (!handled) {
            this.dispatch("pointerUp", { x, y });
        }
    }
    onMouseMove(x, y) {
        let handled = false;
        for (let actor of this.allActors) {
            if (actor.containsPointer(x, y)) {
                if (!actor.wasHovered) {
                    actor.onHovered();
                    actor.wasHovered = true;
                    handled = true;
                }
            }
            else {
                if (actor.wasHovered) {
                    actor.onUnhovered();
                    actor.wasHovered = false;
                    handled = true;
                }
            }
        }
        if (!handled) {
            this.dispatch("pointerMove", { x, y });
        }
    }
    onMouseScroll(delta) {
        this.dispatch("pointerScroll", { delta });
    }
    onMouseLeave() {
        this.dispatch("pointerLeave", {});
    }
    onKeyDown(key) {
        this.dispatch("keyDown", { key });
    }
    onKeyUp(key) {
        this.dispatch("keyUp", { key });
    }
    listen(event, actor) {
        if (!this.listenerActors[event]) {
            this.listenerActors[event] = {};
        }
        const id = Symbol();
        this.listenerActors[event][id] = actor;
        return id;
    }
    unlisten(event, id) {
        if (!this.listenerActors[event]) {
            return;
        }
        delete this.listenerActors[event][id];
    }
    dispatch(event, data) {
        if (!this.listenerActors[event]) {
            return;
        }
        for (let key of Object.getOwnPropertySymbols(this.listenerActors[event])) {
            this.listenerActors[event][key].onEvent(event, data);
        }
    }
}
