import { Actor } from "@/engine/Actor";
export const ACTOR_TYPE_TEXT = "text";
const TEXT_WIDTH = 500;
const TEXT_HEIGHT = 60;
export class Text extends Actor {
    constructor(gameLogic) {
        super(gameLogic, ACTOR_TYPE_TEXT);
        this.text = "";
        this.dismissed = false;
    }
    onBeginDraw(dt, context) {
        context.save();
        context.translate(this.position[0], this.position[1]);
    }
    onEndDraw(dt, context) {
        if (!this.dismissed) {
            context.fillStyle = "rgba(46, 31, 25, 0.25)";
            context.beginPath();
            context.roundRect(-TEXT_WIDTH / 2, -TEXT_HEIGHT / 2, TEXT_WIDTH, TEXT_HEIGHT, 12);
            context.fill();
            context.fillStyle = "#ffffff";
            context.font = "1.25em Quicksand";
            context.textAlign = "center";
            context.fillText(this.text, 0, 0, TEXT_WIDTH);
            context.fillStyle = "#cccccc";
            context.font = "0.75em Quicksand";
            context.textAlign = "center";
            context.fillText("Click to dismiss", 0, 20);
        }
        context.restore();
    }
    onPointerUp() {
        this.dismissed = true;
    }
    containsPointer(sx, sy) {
        if (this.dismissed)
            return false;
        const [x, y] = this.gameLogic.camera.toWorldSpace(sx, sy);
        return x > this.position[0] - (TEXT_WIDTH / 2) && x < this.position[0] + (TEXT_WIDTH / 2) &&
            y > this.position[1] - (TEXT_HEIGHT / 2) && y < this.position[1] + (TEXT_HEIGHT / 2);
    }
}
