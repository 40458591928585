import { IMAGES } from "@/assets/assets";
import { Actor } from "@/engine/Actor";
import { ACTOR_TYPE_TRUCK } from "./Truck";
const ACTOR_TYPE_GOING_INDICATOR = "goingIndicator";
const iconImage = new Image();
iconImage.src = IMAGES["going-indicator.png"];
const INDICATOR_SIZE = 32;
export class GoingIndicator extends Actor {
    constructor(gameLogic) {
        super(gameLogic, ACTOR_TYPE_GOING_INDICATOR);
        this.truckId = null;
    }
    onEndDraw(dt, context) {
        const truck = this.gameLogic.allActors
            .find(actor => actor.actorType === ACTOR_TYPE_TRUCK && actor.truckId === this.truckId);
        for (let position of truck.path) {
            context.drawImage(iconImage, position[0] - INDICATOR_SIZE / 2, position[1] - INDICATOR_SIZE / 2, INDICATOR_SIZE, INDICATOR_SIZE);
        }
        // Draw path lines
        context.beginPath();
        context.moveTo(truck.position[0], truck.position[1]);
        for (let position of truck.path) {
            context.lineTo(position[0], position[1]);
        }
        context.strokeStyle = "rgba(255, 255, 255, 0.25)";
        context.lineWidth = 4;
        if (truck.isPatroling) {
            context.strokeStyle = "rgba(0, 255, 255, 0.25)";
        }
        context.setLineDash([5, 5]);
        context.stroke();
        context.setLineDash([0]);
    }
}
