import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Actor } from "@/engine/Actor";
import { useEffect, useState } from "react";
class GameOverActor extends Actor {
    on(l) {
        this.listener = l;
    }
    onEvent(eventName, eventData) {
        if (eventName === "gameOver") {
            this.listener(eventData);
        }
    }
}
export function GameOver(options) {
    const [isGameOver, setIsGameOver] = useState(false);
    const [score, setScore] = useState(0);
    const [reason, setReason] = useState("");
    const [actor, setActor] = useState(new GameOverActor(options.gameLogic));
    useEffect(() => {
        actor.on(({ score, reason }) => {
            setScore(score);
            setReason(reason);
            setIsGameOver(true);
        });
        options.gameLogic.listen("gameOver", actor);
    }, []);
    return _jsx("div", Object.assign({ className: "game-over" }, { children: _jsxs("div", Object.assign({ className: `game-over-content ${isGameOver ? "" : "hidden"}` }, { children: [_jsx("h1", { children: "Game Over" }), _jsxs("p", { children: ["Score: ", _jsx("b", { children: score })] }), _jsxs("p", { children: ["Reason: ", _jsx("b", { children: reason })] }), _jsx("button", Object.assign({ onClick: () => { window.location.reload(); } }, { children: "Restart" }))] })) }));
}
