import { HasPolygon } from "@/trait/HasPolygon";
import { HasPosition } from "@/trait/HasPosition";
import { Vector2, vec2add, vec2dot, vec2len, vec2norm, vec2scale, vec2sub } from "./Vector2";

export function closestPointOnPolygon(polygon: HasPolygon & HasPosition, point: Vector2): Vector2 {
    let closestPoint: Vector2 | null = null;
    let closestDistance = Infinity;

    for (let i = 0; i < polygon.points.length; i++) {
        const currentPoint = vec2add(polygon.points[i], polygon.position);
        const nextPoint = vec2add(polygon.points[(i + 1) % polygon.points.length], polygon.position);
        const edge = vec2sub(nextPoint, currentPoint);
        const edgeLength = vec2len(edge);
        const normalizedEdge = vec2norm(edge);
        const v = vec2sub(point, currentPoint);
        const t = Math.max(0, Math.min(edgeLength, vec2dot(v, normalizedEdge)));
        const projection = vec2add(currentPoint, vec2scale(normalizedEdge, t));
        const distance = vec2len(vec2sub(point, projection));

        if (distance < closestDistance) {
            closestDistance = distance;
            closestPoint = projection;
        }
    }

    return closestPoint!;
}

export function polygonContains(polygon: HasPolygon & HasPosition, point: Vector2): boolean {
    let contains = false;

    for (let i = 0; i < polygon.points.length; i++) {
        const currentPoint = vec2add(polygon.points[i], polygon.position);
        const nextPoint = vec2add(polygon.points[(i + 1) % polygon.points.length], polygon.position);
        const edge = vec2sub(nextPoint, currentPoint);
        const edgeLength = vec2len(edge);
        const normalizedEdge = vec2norm(edge);
        const v = vec2sub(point, currentPoint);
        const t = Math.max(0, Math.min(edgeLength, vec2dot(v, normalizedEdge)));
        const projection = vec2add(currentPoint, vec2scale(normalizedEdge, t));
        const distance = vec2len(vec2sub(point, projection));

        if (distance === 0) {
            return true;
        }
    }

    return contains;
}