import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Actor } from "@/engine/Actor";
import { useEffect, useState } from "react";
class TopUIActor extends Actor {
    on(l) {
        this.listener = l;
    }
    onPaused(l) {
        this.pauseListener = l;
    }
    onMuted(l) {
        this.muteListener = l;
    }
    onEvent(eventName, eventData) {
        if (eventName === "scoreChanged") {
            this.listener(eventData);
        }
        else if (eventName === "paused") {
            this.pauseListener(true);
        }
        else if (eventName === "resumed") {
            this.pauseListener(false);
        }
        else if (eventName === "muteSound") {
            this.muteListener(true);
        }
        else if (eventName === "unmuteSound") {
            this.muteListener(false);
        }
    }
}
export function TopUI(options) {
    const [score, setScore] = useState(0);
    const [paused, setPaused] = useState(false);
    const [muted, setMuted] = useState(false);
    const [actor, setActor] = useState(new TopUIActor(options.gameLogic));
    useEffect(() => {
        actor.on((score) => {
            setScore(score);
        });
        actor.onPaused((paused) => {
            setPaused(paused);
        });
        actor.onMuted((muted) => {
            setMuted(muted);
        });
        options.gameLogic.listen("scoreChanged", actor);
        options.gameLogic.listen("paused", actor);
        options.gameLogic.listen("resumed", actor);
        options.gameLogic.listen("muteSound", actor);
        options.gameLogic.listen("unmuteSound", actor);
    }, []);
    return _jsxs("div", Object.assign({ className: "game-top-ui" }, { children: [_jsx("div", Object.assign({ className: "game-top-ui-left" }, { children: _jsx("div", Object.assign({ className: "game-top-ui-left-item" }, { children: _jsxs("p", { children: ["Score: ", _jsxs("b", { children: [score, paused ? (_jsx("span", { children: " (paused)" })) : null] })] }) })) })), _jsx("div", Object.assign({ className: "game-top-ui-right" }, { children: muted ? (_jsx("div", Object.assign({ className: "game-top-ui-right-item" }, { children: _jsx("p", { children: _jsx("i", { children: "Muted" }) }) }))) : null }))] }));
}
