import { Actor } from "../engine/Actor";
import { GameLogic } from "../engine/GameLogic";
import levelBackgroundImageDataUrl from "@/assets/level.png";
import { Truck, TruckOptions } from "./Truck";
import { Vector2, vec2rotate } from "@/math/Vector2";
import { PickupPoint } from "./PickupPoint";
import { AMBIENT, IMAGES } from "@/assets/assets";
import { DropPoint } from "./DropPoint";

import levelJsondata from "@/assets/level.json";
// import levelJsondata from "@/assets/level_dev.json";
import { Obstacle } from "./Obstacle";
import { GoingIndicator } from "./GoingIndicator";
import { Water } from "./Water";
import { Ambient } from "./Ambient";
import { Text } from "./Text";
import { SpawnArea } from "./SpawnArea";

export const ACTOR_TYPE_LEVEL = "level";

export class Level extends Actor {

    private roadLayer: any;
    private pickupPointsLayer: any;
    private dropoffPointsLayer: any;
    private obstaclesLayer: any;
    private trucksLayer: any;
    private carDataLayer: any;
    private itemDataLayer: any;
    private cameraLayer: any;
    private waterLayer: any;
    private ambientDataLayer: any;
    private ambientLayer: any;
    private messagesLayer: any;
    private enemyDataLayer: any;
    private spawnAreasLayer: any;

    private backgroundImage: HTMLImageElement;

    constructor(
        gameLogic: GameLogic
    ) {
        super(gameLogic, ACTOR_TYPE_LEVEL);

        this.backgroundImage = new Image();
        this.backgroundImage.src = IMAGES["level-big.png"];

        this.roadLayer = levelJsondata.layers.find((layer: any) => layer.name === "road");
        this.pickupPointsLayer = levelJsondata.layers.find((layer: any) => layer.name === "pickup-points");
        this.dropoffPointsLayer = levelJsondata.layers.find((layer: any) => layer.name === "drop-points");
        this.obstaclesLayer = levelJsondata.layers.find((layer: any) => layer.name === "obstacles");
        this.trucksLayer = levelJsondata.layers.find((layer: any) => layer.name === "trucks");
        this.carDataLayer = levelJsondata.layers.find((layer: any) => layer.name === "cardata");
        this.itemDataLayer = levelJsondata.layers.find((layer: any) => layer.name === "itemdata");
        this.cameraLayer = levelJsondata.layers.find((layer: any) => layer.name === "camera");
        this.waterLayer = levelJsondata.layers.find((layer: any) => layer.name === "water");
        this.ambientDataLayer = levelJsondata.layers.find((layer: any) => layer.name === "ambientdata");
        this.ambientLayer = levelJsondata.layers.find((layer: any) => layer.name === "ambient");
        this.messagesLayer = levelJsondata.layers.find((layer: any) => layer.name === "messages");
        this.enemyDataLayer = levelJsondata.layers.find((layer: any) => layer.name === "enemydata");
        this.spawnAreasLayer = levelJsondata.layers.find((layer: any) => layer.name === "spawn-areas");

        this.spawnTrucks();
        this.spawnPickupPoints();
        this.spawnDropPoints();
        this.spawnObstacles();
        this.spawnWater();
        this.spawnAmbient();
        this.spawnMessages();
        this.teleportToSpawnLocation();
    }

    get mapWidth(): number {
        return this.backgroundImage.width;
    }

    get mapHeight(): number {
        return this.backgroundImage.height;
    }

    private getLayerOffset(layer: any): Vector2 {
        let layerOffset: Vector2 = [layer.x, layer.y];
        if ("offsetx" in layer) {
            layerOffset[0] += layer.offsetx;
        }
        if ("offsety" in layer) {
            layerOffset[1] += layer.offsety;
        }
        return layerOffset;
    }

    private spawnTrucks(): void {
        let layerOffset = this.getLayerOffset(this.trucksLayer);
        for (const truck of this.trucksLayer.objects) {

            // Merge custom properties of truck object with defaults
            let truckOptions: Partial<TruckOptions> & Record<string, any> = {};
            if (truck.properties) {
                truckOptions = {
                    ...truckOptions,
                    ...truck.properties.reduce((acc: any, property: any) => {
                        acc[property.name] = property.value;
                        return acc;
                    }, {})
                }
                console.log(`Loaded custom properties of truck ${truck.name}: `, truckOptions);

                // See if a car is defined for this truck
                if (truckOptions.car) {
                    console.log(`Loading data from cardata for truck ${truck.name}, cardata: `, truckOptions.car);
                    const carId = truckOptions.car;
                    const carData = this.carDataLayer.objects.find((car: any) => car.id === carId);
                    console.log(`Found car data for truck ${truck.name}: `, carData);
                    if (carData) {
                        truckOptions = {
                            ...truckOptions,
                            ...carData.properties.reduce((acc: any, property: any) => {
                                acc[property.name] = property.value;
                                return acc;
                            }, {})
                        }
                    }
                }
            }


            let truckActor = new Truck(this.gameLogic, truckOptions);
            let truckCenter: Vector2 = [truck.width / 2.0, truck.height / 2.0];
            truckCenter = vec2rotate(truckCenter, truck.rotation * Math.PI / 180);
            truckActor.position = [truck.x + truckCenter[0] + layerOffset[0], truck.y + truckCenter[1] + layerOffset[1]];
            // truckActor.position = [truck.x, truck.y];
            truckActor.rotation = truck.rotation * Math.PI / 180;
            this.children.push(truckActor);

            const goingIndicator = new GoingIndicator(this.gameLogic);
            goingIndicator.truckId = truckActor.truckId;
            this.children.push(goingIndicator);

        }
    }

    private spawnPickupPoints(): void {
        let layerOffset = this.getLayerOffset(this.pickupPointsLayer);
        for (const pp of this.pickupPointsLayer.objects) {
            const pickupPoint = new PickupPoint(this.gameLogic);
            const properties = pp.properties.reduce((acc: any, property: any) => {
                acc[property.name] = property.value;
                return acc;
            }, {});
            pickupPoint.name = pp.name;
            pickupPoint.description = properties.description;
            pickupPoint.produces = this.itemDataLayer.objects.find(
                (item: any) => item.id === properties.produces
            );
            pickupPoint.image = new Image();
            pickupPoint.image.src = IMAGES[
                pickupPoint.produces.properties.find(
                    prodProp => prodProp.name === "image"
                ).value
            ];
            pickupPoint.maxCapacity = properties.maxCapacity;
            pickupPoint.productionCycleTime = properties.productionCycleTime;
            pickupPoint.position = [pp.x + layerOffset[0], pp.y + layerOffset[1]];
            this.children.push(pickupPoint);
            console.log("Spawned pickup point: ", pickupPoint);
        }
    }

    private spawnDropPoints(): void {
        let layerOffset = this.getLayerOffset(this.dropoffPointsLayer);
        for (const dp of this.dropoffPointsLayer.objects) {
            const dropoffPoint = new DropPoint(this.gameLogic);
            const properties = dp.properties.reduce((acc: any, property: any) => {
                acc[property.name] = property.value;
                return acc;
            }, {});
            dropoffPoint.name = dp.name;
            dropoffPoint.description = properties.description;
            dropoffPoint.consumes = this.itemDataLayer.objects.find(
                (item: any) => item.id === properties.consumes
            );
            dropoffPoint.image = new Image();
            dropoffPoint.image.src = IMAGES[
                properties.image
            ];
            dropoffPoint.position = [dp.x + layerOffset[0], dp.y + layerOffset[1]];
            this.children.push(dropoffPoint);
            const dropoffPointSpawnAreaProperty = dp.properties.find(
                (prop: any) => prop.name === "spawnArea"
            );
            if (dropoffPointSpawnAreaProperty) {
                const spawnArea = this.spawnAreasLayer.objects.find(
                    (spawnArea: any) => spawnArea.id === dropoffPointSpawnAreaProperty.value
                );
                if (spawnArea) {
                    this.spawnSpawnAreas(
                        dropoffPoint,
                        spawnArea.id
                    );
                }
            }
            console.log("Spawned dropoff point: ", dropoffPoint);
        }
    }

    private spawnObstacles(): void {
        let layerOffset = this.getLayerOffset(this.obstaclesLayer);
        for (const obst of this.obstaclesLayer.objects) {
            const obstacle = new Obstacle(this.gameLogic);
            obstacle.position = [obst.x + layerOffset[0], obst.y + layerOffset[1]];
            obstacle.points = obst.polygon.map((xy: { x: number, y: number }) => [xy.x, xy.y]);
            this.children.push(obstacle);
        }
    }

    private spawnWater(): void {
        let layerOffset = this.getLayerOffset(this.waterLayer);
        for (const water of this.waterLayer.objects) {
            const waterArea = new Water(this.gameLogic);
            waterArea.position = [water.x + layerOffset[0], water.y + layerOffset[1]];
            waterArea.points = water.polygon.map((xy: { x: number, y: number }) => [xy.x, xy.y]);
            this.children.push(waterArea);
        }
    }

    private spawnAmbient(): void {
        let layerOffset = this.getLayerOffset(this.ambientLayer);
        for (const ambientName of AMBIENT) {
            const ambient = new Ambient(this.gameLogic);
            const ambientDataObject = this.ambientDataLayer.objects.find((obj: any) => obj.name === ambientName);
            const ambientDataObjectSoundProperty = ambientDataObject.properties.find((prop: any) => prop.name === "sound");
            if (ambientDataObjectSoundProperty) {
                ambient.effectName = ambientDataObjectSoundProperty.value;
            }
            for (let ambientObject of this.ambientLayer.objects) {
                const ambientObjectEffectProperty = ambientObject.properties.find((prop: any) => prop.name === "effect");
                if (ambientObjectEffectProperty && ambientObjectEffectProperty.value === ambientDataObject.id) {
                    ambient.points.push(
                        [ambientObject.x + ambientObject.width / 2.0 + layerOffset[0],
                        ambientObject.y + ambientObject.height / 2.0 + layerOffset[1]]
                    );
                    ambient.radiusValues.push(
                        Math.min(ambientObject.width, ambientObject.height) / 2.0
                    );
                }
            }
            this.children.push(ambient);
        }
    }

    private spawnMessages(): void {
        let layerOffset = this.getLayerOffset(this.messagesLayer);
        for (const message of this.messagesLayer.objects) {
            const text = new Text(this.gameLogic);
            text.text = message.properties.find((prop: any) => prop.name === "text").value;
            text.position = [message.x + layerOffset[0], message.y + layerOffset[1]];
            this.children.push(text);
        }
    }

    private spawnSpawnAreas(dropPoint: DropPoint, spawnAreaTiledObjectId: any): void {
        let layerOffset = this.getLayerOffset(this.spawnAreasLayer);
        for (const spawnArea of this.spawnAreasLayer.objects) {
            if (spawnArea.id !== spawnAreaTiledObjectId) {
                continue;
            }
            const spawnAreaObject = new SpawnArea(this.gameLogic);
            spawnAreaObject.position = [spawnArea.x + layerOffset[0], spawnArea.y + layerOffset[1]];
            spawnAreaObject.points = spawnArea.polygon.map((xy: { x: number, y: number }) => [xy.x, xy.y]);
            const spawnsPropertyValue = spawnArea.properties.find((prop: any) => prop.name === "spawns").value;
            const spawnsObject = this.enemyDataLayer.objects.find((obj: any) => obj.id === spawnsPropertyValue);
            spawnAreaObject.spawns = spawnsObject;
            spawnAreaObject.dropPointId = dropPoint.dropPointId;
            this.children.push(spawnAreaObject);
        }
    }

    private teleportToSpawnLocation(): void {
        const spawnLocationPoint = this.cameraLayer.objects.find((obj: any) => obj.name === "SpawnLocation");
        this.gameLogic.camera.position = [spawnLocationPoint.x, spawnLocationPoint.y];
    }

    public onBeginDraw(dt: number, context: CanvasRenderingContext2D): void {
        context.drawImage(this.backgroundImage, 0, 0);
    }

    public onEndDraw(dt: number, context: CanvasRenderingContext2D): void {
    }

}