import { HasDestination } from "@/trait/HasDestination";
import { Actor } from "../engine/Actor";
import { GameLogic } from "../engine/GameLogic";
import { Vector2, vec2add, vec2angle, vec2dot, vec2len, vec2lerp, vec2rotate, vec2scale, vec2sub } from "../math/Vector2";
import { HasRotation } from "@/trait/HasRotation";
import { MoveTowardsBehaviour } from "@/steering/MoveTowardsBehaviour";
import { HasVelocity } from "@/trait/HasVelocity";
import { CompoundBehaviour } from "@/steering/CompoundBehaviour";
import { ArriveAtBehaviour } from "@/steering/ArriveAtBehaviour";
import { CollisionAvoidanceBehaviour } from "@/steering/CollisionAvoidanceBehaviour";
import { HasObstacles } from "@/trait/HasObstacles";
import { ACTOR_TYPE_OBSTACLE, Obstacle } from "./Obstacle";
import { GoingIndicator } from "./GoingIndicator";
import { ACTOR_TYPE_FOG_OF_WAR, FogOfWar } from "./FogOfWar";
import { ACTOR_TYPE_WATER, Water } from "./Water";
import { closestPointOnPolygon } from "@/math/Polygon";
import { IMAGES } from "@/assets/assets";
import { DEBUG } from "@/constants";
import { ACTOR_TYPE_PICKUP_POINT, PickupPoint } from "./PickupPoint";
import { HasPosition } from "@/trait/HasPosition";
import { TruckPathPrediction } from "./TruckPathPrediction";
import { HasPath } from "@/trait/HasPath";

const TRUCK_IMAGE = new Image();
TRUCK_IMAGE.src = IMAGES["truck.png"];
const HORSE_IMAGE = new Image();
HORSE_IMAGE.src = IMAGES["horse.png"];


const TRUCK_SPEED = 100;
const TRUCK_ACCELERATION = 500;
const TRUCK_ROTATION_SPEED = 1;
const TRUCK_VELOCITY_ROTATION_SPEED_PENALTY = 100;
export const TRUCK_WIDTH = 96;
export const TRUCK_HEIGHT = 48;
const ITEM_WIDTH = 40;
const ITEM_HEIGHT = 40;
const TRUCK_VISION_RADIUS = 300;
export const DESTINATION_REACHED_DISTANCE = 40;

export const ACTOR_TYPE_TRUCK = "truck";

export const UPGRADE_MODIFIERS: Array<{
    speed: number;
    rotationSpeed: number;
}> = [
        {
            speed: 1.0,
            rotationSpeed: 1.0
        },
        {
            speed: 1.2,
            rotationSpeed: 1.5
        },
        {
            speed: 1.5,
            rotationSpeed: 2.0
        },
        {
            speed: 1.7,
            rotationSpeed: 2.2
        },
        {
            speed: 2.0,
            rotationSpeed: 2.5
        }
    ];

export interface TruckOptions {
    speed: number;
    acceleration: number;
    rotationSpeed: number;
    velocityRotationSpeedPenalty: number;
}

export class Truck extends Actor implements HasDestination, HasRotation, HasVelocity, HasObstacles, HasPath {

    public truckId = Symbol();
    public destination: Vector2 | null = null;
    public rotation: number = 0;
    public isSelected: boolean = false;
    public velocity: number = 0;
    public carryVelocityMultiplier: number = 1;
    public lastVelocityMultiplier: number = 1;
    public steeringBehaviour = new CompoundBehaviour<HasPosition & HasDestination & HasRotation & HasVelocity & HasObstacles>(
        new MoveTowardsBehaviour(),
        new ArriveAtBehaviour(),
        new CollisionAvoidanceBehaviour(TRUCK_HEIGHT - 30)
    );
    public options: TruckOptions = {
        speed: TRUCK_SPEED,
        acceleration: TRUCK_ACCELERATION,
        rotationSpeed: TRUCK_ROTATION_SPEED,
        velocityRotationSpeedPenalty: TRUCK_VELOCITY_ROTATION_SPEED_PENALTY
    }
    public carrying: string | null = null;
    public carryingImage: HTMLImageElement | null = null;
    private pointerDownLocation: Vector2 | null = null;
    public path: Array<Vector2> = [];
    private isShiftDown: boolean = false;
    public isPatroling: boolean = false;
    public patrolIndex: number = 0;
    private animationTimer: number = 0;
    public upgradeLevel: number = 0;

    constructor(
        gameLogic: GameLogic,
        options?: Partial<TruckOptions>
    ) {
        super(gameLogic, ACTOR_TYPE_TRUCK);

        if (options) {
            this.options = {
                ...this.options,
                ...options
            }
        }

        gameLogic.listen("pointerUp", this);
        gameLogic.listen("pointerDown", this);
        gameLogic.listen("truckSelected", this);
        gameLogic.listen("truckPickup", this);
        gameLogic.listen("truckDrop", this);
        gameLogic.listen("enemyStrike", this);
        gameLogic.listen("keyDown", this);
        gameLogic.listen("keyUp", this);

        const truckPathPrediction = new TruckPathPrediction(gameLogic);
        truckPathPrediction.truckId = this.truckId;
        truckPathPrediction.predictionCount = 15;
        truckPathPrediction.predictionTimeStep = 0.1;
        this.children.push(truckPathPrediction);
    }

    public get obstacles(): Array<Obstacle> {
        return this.gameLogic.allActors.filter(actor => actor.actorType === ACTOR_TYPE_OBSTACLE) as Array<Obstacle>;
    }

    private onGameLogicPointerDown(
        x: number,
        y: number
    ): void {
        this.pointerDownLocation = [x, y];
    }

    private onGameLogicPointerUp(
        x: number,
        y: number
    ): void {
        const pointerUpDistance = vec2len(vec2sub(this.pointerDownLocation, [x, y]));
        if (this.isSelected && pointerUpDistance < 4) {

            // this.destination = this.gameLogic.camera.toWorldSpace(x, y);
            if (this.isShiftDown) {
                this.path.push(this.gameLogic.camera.toWorldSpace(x, y));
            } else {
                this.path = [this.gameLogic.camera.toWorldSpace(x, y)];
                this.destination = this.path[0];
            }

            this.isPatroling = false;
        }
    }

    private onGameLogicTruckSelected(truckId: Symbol): void {
        this.isSelected = this.truckId === truckId;
    }

    private onGameLogicTruckDeselected(): void {
        this.isSelected = false;
    }

    private onGameLogicTruckPickup(truckId: Symbol, resourceId: string, pickupPointId: Symbol, velocityMultiplier: number): void {
        if (this.truckId === truckId) {
            const pickupPoint = this.gameLogic.allActors
                .find(actor => actor.actorType === ACTOR_TYPE_PICKUP_POINT && (actor as PickupPoint).pickupPointId === pickupPointId) as PickupPoint;
            console.log(pickupPoint);
            this.carrying = resourceId;
            this.carryingImage = new Image();
            const imageName = pickupPoint.produces.properties
                .find(property => property.name === "image").value;
            this.carryingImage.src = IMAGES[imageName];
            this.carryVelocityMultiplier = velocityMultiplier;
            this.gameLogic.dispatch("resourcePickedUp", { truckId: this.truckId, resourceId, pickupPointId });
        }
    }

    private onGameLogicTruckDrop(truckId: Symbol, resourceId: string, dropPointId: Symbol): void {
        if (this.truckId === truckId && this.carrying === resourceId) {
            this.carrying = null;
            this.carryingImage = null;
            this.carryVelocityMultiplier = 1;
            this.gameLogic.dispatch("resourceDelivered", { truckId, resourceId, dropPointId });
        }
    }

    private onGameLogicTruckUpgraded(truckId: Symbol): void {
        if (this.truckId === truckId && this.upgradeLevel < 4) {
            this.upgradeLevel++;
        }
    }

    private onGameLogicEnemyStrike(truckId: Symbol, enemyId: Symbol): void {
        if (this.truckId === truckId) {
            if (this.carrying) {
                this.carrying = null;
                this.carryingImage = null;
                this.gameLogic.dispatch("enemyFed", { enemyId });
            }
        }
    }

    private onGameLogicKeyDown(key: string): void {
        if (this.isSelected) {
            if (key === "Shift") {
                this.isShiftDown = true;
            }
        }
    }

    private onGameLogicKeyUp(key: string): void {
        if (this.isSelected) {
            if (key === "Shift") {
                this.isShiftDown = false;
            } else if (key === "p") {
                this.isPatroling = true;
                this.patrolIndex = 0;
            }
        }
    }

    private calculateTerrainSpeedModifier(): number {
        let mod = 1.0;
        let closestModifierDistance = Infinity;
        const waterActors = this.gameLogic.allActors
            .filter(actor => actor.actorType === ACTOR_TYPE_WATER) as Array<Water>;
        for (let water of waterActors) {
            const closestPointToWater = closestPointOnPolygon(water, this.position);
            const closestWaterDistance = vec2len(vec2sub(this.position, closestPointToWater));
            if (closestModifierDistance > closestWaterDistance) {
                closestModifierDistance = closestWaterDistance;
                mod = water.modifier;
            }
        }
        if (closestModifierDistance < TRUCK_WIDTH / 2) {
            return mod;
        }
        return 1.0;
    }

    public onTick(dt: number): void {

        const upgradeModifier = UPGRADE_MODIFIERS[this.upgradeLevel];

        const accel = this.steeringBehaviour.acceleration(this, dt);
        const angularAccel = this.steeringBehaviour.angularAcceleration(this, dt);
        const accelUnits = accel * this.options.acceleration;
        let angularAccelUnits = angularAccel * this.options.rotationSpeed * upgradeModifier.rotationSpeed;
        if (this.velocity < this.options.velocityRotationSpeedPenalty) {
            angularAccelUnits *= this.velocity / this.options.velocityRotationSpeedPenalty;
        }

        this.velocity += accelUnits * dt;
        if (this.velocity > this.options.speed * upgradeModifier.speed) {
            this.velocity = this.options.speed * upgradeModifier.speed;
        }
        if (this.velocity < 0) {
            this.velocity = 0;
        }

        if (Math.abs(this.velocity) > 0 && !this.gameLogic.isPaused) {
            this.rotation += angularAccelUnits * dt;
            const velocityMultiplier = this.calculateTerrainSpeedModifier() * this.carryVelocityMultiplier;
            this.lastVelocityMultiplier = velocityMultiplier;
            this.position = vec2sub(this.position, vec2scale(vec2rotate([0, 1], this.rotation), this.velocity * velocityMultiplier * dt));
        }

        const fogOfWar = this.gameLogic.allActors
            .find(actor => actor.actorType === ACTOR_TYPE_FOG_OF_WAR) as FogOfWar;
        fogOfWar.discover(this.position, TRUCK_VISION_RADIUS);

        if (this.path.length > 0) {

            if (!this.destination) {
                this.destination = this.path[0];
            }
            const destinationDistance = this.destination
                ? vec2len(vec2sub(this.position, this.destination))
                : Infinity;
            if (destinationDistance < DESTINATION_REACHED_DISTANCE) {

                if (this.isPatroling) {
                    this.patrolIndex = (this.patrolIndex + 1) % this.path.length;
                    this.destination = this.path[this.patrolIndex];
                } else {
                    this.path.shift();
                    this.destination = this.path[0] || null;
                }
            }
        } else {
            this.destination = null;
            this.velocity = this.velocity + (0 - this.velocity) * 0.8;
        }
    }

    public onBeginDraw(
        dt: number,
        context: CanvasRenderingContext2D
    ): void {
        this.animationTimer += dt;

        context.save();
        context.translate(this.position[0], this.position[1]);
        context.rotate(this.rotation - Math.PI / 2);
        if (this.isSelected) {
            context.filter = "drop-shadow(1px 1px 0px #ffffff) drop-shadow(-1px -1px 0px #ffffff) drop-shadow(1px -1px 0px #ffffff) drop-shadow(-1px 1px 0px #ffffff)"
        }
        context.drawImage(TRUCK_IMAGE, -TRUCK_WIDTH / 2, -TRUCK_HEIGHT / 2, TRUCK_WIDTH, TRUCK_HEIGHT);
        context.rotate(-(this.rotation - Math.PI / 2));

        context.filter = "none";

        if (this.carryingImage) {
            context.drawImage(this.carryingImage, -ITEM_WIDTH / 2, -ITEM_WIDTH / 2, ITEM_WIDTH, ITEM_WIDTH);
        }

        if (DEBUG) {
            context.beginPath();
            context.fillStyle = "rgba(255, 255, 255, 0.25)";
            context.arc(0, 0, TRUCK_VISION_RADIUS, 0, 2 * Math.PI);
            context.fill();

            context.strokeStyle = "rgba(255, 255, 255, 0.85)";
            context.lineWidth = 1;
            context.rotate(this.rotation);
            context.strokeRect(-TRUCK_WIDTH / 2, -TRUCK_HEIGHT / 2, TRUCK_WIDTH, TRUCK_HEIGHT);
            context.rotate(-this.rotation);
        }
    }

    public onEndDraw(
        dt: number,
        context: CanvasRenderingContext2D
    ): void {
        context.restore();
    }

    public onEvent(eventName: string, eventData: any): void {
        if (eventName === "pointerUp") {
            this.onGameLogicPointerUp(eventData.x, eventData.y);
        } else if (eventName === "truckSelected") {
            this.onGameLogicTruckSelected(eventData);
        } else if (eventName === "truckDeselected") {
            this.onGameLogicTruckDeselected();
        } else if (eventName === "truckPickup") {
            this.onGameLogicTruckPickup(eventData.truckId, eventData.resourceId, eventData.pickupPointId, eventData.velocityMultiplier);
        } else if (eventName === "truckDrop") {
            this.onGameLogicTruckDrop(eventData.truckId, eventData.resourceId, eventData.dropPointId);
        } else if (eventName === "truckUpgrade") {
            this.onGameLogicTruckUpgraded(eventData.truckId);
        } else if (eventName === "pointerDown") {
            this.onGameLogicPointerDown(eventData.x, eventData.y);
        } else if (eventName === "keyDown") {
            this.onGameLogicKeyDown(eventData.key);
        } else if (eventName === "keyUp") {
            this.onGameLogicKeyUp(eventData.key);
        } else if (eventName === "enemyStrike") {
            this.onGameLogicEnemyStrike(eventData.truckId, eventData.enemyId);
        }
    }

    public onPointerUp(): void {
        if (this.isSelected) {
            this.isSelected = false;
            this.gameLogic.dispatch("truckDeselected", null);
        } else {
            this.isSelected = true;
            this.gameLogic.dispatch("truckSelected", this.truckId);
        }
    }

    public containsPointer(sx: number, sy: number): boolean {
        const [x, y] = this.gameLogic.camera.toWorldSpace(sx, sy);
        return x > this.position[0] - (TRUCK_WIDTH / 2) && x < this.position[0] + (TRUCK_WIDTH / 2) &&
            y > this.position[1] - (TRUCK_HEIGHT / 2) && y < this.position[1] + (TRUCK_HEIGHT / 2);
    }

}