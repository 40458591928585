import { Actor } from "@/engine/Actor";
import { GameLogic } from "@/engine/GameLogic";

export const ACTOR_TYPE_PAUSE_CONTROLLER = "pause-controller";

export class PauseController extends Actor {
    constructor(
        gameLogic: GameLogic
    ) {
        super(gameLogic, ACTOR_TYPE_PAUSE_CONTROLLER);

        gameLogic.listen("pauseResume", this);
        gameLogic.listen("keyUp", this);
    }

    public onEvent(eventName: string, eventData: any): void {
        if (eventName === "pauseResume") {
            this.gameLogic.isPaused = !this.gameLogic.isPaused;
            if (this.gameLogic.isPaused) {
                this.gameLogic.dispatch("paused", {});
            } else {
                this.gameLogic.dispatch("resumed", {});
            }
        } else if (eventName === "keyUp") {
            console.log(eventData);
            if (eventData.key === " ") {
                this.gameLogic.dispatch("pauseResume", {});
            }
        }
    }
}