import { Actor } from "@/engine/Actor";
import { GameLogic } from "@/engine/GameLogic";
import { ACTOR_TYPE_TRUCK, DESTINATION_REACHED_DISTANCE, Truck } from "./Truck";
import { Vector2, vec2len, vec2rotate, vec2scale, vec2sub } from "@/math/Vector2";
import { HasPosition } from "@/trait/HasPosition";
import { HasVelocity } from "@/trait/HasVelocity";
import { HasDestination } from "@/trait/HasDestination";
import { HasRotation } from "@/trait/HasRotation";
import { HasObstacles } from "@/trait/HasObstacles";
import { HasPath } from "@/trait/HasPath";

export const ACTOR_TYPE_TRUCK_PATH_PREDICTION = "truckPathPrediction";

export class TruckPathPrediction extends Actor {

    public truckId: Symbol | null = null;
    public predictionCount: number = 0;
    public predictionTimeStep: number = 0.1;
    private predictedPoints: Vector2[] = [];
    private visible: boolean = false;
    private predictionTimer: number = 0.0;

    constructor(
        gameLogic: GameLogic
    ) {
        super(gameLogic, ACTOR_TYPE_TRUCK_PATH_PREDICTION);
    }

    public onTick(dt: number): void {

        this.predictionTimer += dt;

        if (this.predictionTimer < 0.25) {
            return;
        }

        this.predictionTimer = 0.0;

        const trucks = this.gameLogic.allActors
            .filter(actor => actor.actorType === ACTOR_TYPE_TRUCK);
        const truck: Truck = trucks.find(actor => (actor as any).truckId === this.truckId) as Truck;

        if (!truck.destination) {
            this.visible = false;
            return;
        }

        this.visible = true;

        let t: number = 0.0;
        let simulatedTruck: HasPosition & HasDestination & HasRotation & HasVelocity & HasObstacles & HasPath = {
            position: [...truck.position],
            velocity: truck.velocity,
            destination: [...truck.destination],
            rotation: truck.rotation,
            obstacles: truck.obstacles,
            path: [...truck.path]
        };
        let patrolIndex = truck.patrolIndex;
        for (let i = 0; i <= this.predictionCount; i++) {
            t += this.predictionTimeStep;

            let accel = truck.steeringBehaviour.acceleration(simulatedTruck, this.predictionTimeStep);
            let angularAccel = truck.steeringBehaviour.angularAcceleration(simulatedTruck, this.predictionTimeStep);
            const accelUnits = accel * truck.options.acceleration;
            let angularAccelUnits = angularAccel * truck.options.rotationSpeed;
            if (simulatedTruck.velocity < truck.options.velocityRotationSpeedPenalty) {
                angularAccelUnits *= simulatedTruck.velocity / truck.options.velocityRotationSpeedPenalty;
            }
            simulatedTruck.velocity += accelUnits * this.predictionTimeStep;
            if (simulatedTruck.velocity > truck.options.speed) {
                simulatedTruck.velocity = truck.options.speed;
            }
            if (simulatedTruck.velocity < 0) {
                simulatedTruck.velocity = 0;
            }
            simulatedTruck.rotation += angularAccelUnits * this.predictionTimeStep;

            if (Math.abs(simulatedTruck.velocity) > 0) {
                const velocityMultiplier = truck.lastVelocityMultiplier;
                simulatedTruck.position = vec2sub(simulatedTruck.position, vec2scale(vec2rotate([0, 1], simulatedTruck.rotation), simulatedTruck.velocity * velocityMultiplier * this.predictionTimeStep));
            }


            if (simulatedTruck.path.length > 0) {

                if (!simulatedTruck.destination) {
                    simulatedTruck.destination = simulatedTruck.path[0];
                }
                const destinationDistance = simulatedTruck.destination
                    ? vec2len(vec2sub(simulatedTruck.position, simulatedTruck.destination))
                    : Infinity;
                if (destinationDistance < DESTINATION_REACHED_DISTANCE) {

                    if (truck.isPatroling) {
                        patrolIndex = (patrolIndex + 1) % simulatedTruck.path.length;
                        simulatedTruck.destination = simulatedTruck.path[patrolIndex];
                    } else {
                        simulatedTruck.path.shift();
                        simulatedTruck.destination = simulatedTruck.path[0] || null;
                    }
                }
            } else {
                simulatedTruck.destination = null;
                simulatedTruck.velocity = simulatedTruck.velocity + (0 - simulatedTruck.velocity) * 0.8;
            }

            this.predictedPoints[i] = [
                simulatedTruck.position[0] - truck.position[0],
                simulatedTruck.position[1] - truck.position[1]
            ];

        }

    }

    public onBeginDraw(dt: number, context: CanvasRenderingContext2D): void {

        if (!this.visible) {
            return;
        }

        context.fillStyle = "#ffffff";
        for (let p of this.predictedPoints) {
            context.fillRect(
                p[0] - 2,
                p[1] - 2,
                4,
                4
            );
        }

    }

}