import { DEBUG } from "@/constants";
import { Actor } from "@/engine/Actor";
export const ACTOR_TYPE_WATER = "water";
export class Water extends Actor {
    constructor(gameLogic) {
        super(gameLogic, ACTOR_TYPE_WATER);
        this.points = [];
        this.modifier = 0.1;
    }
    onEndDraw(dt, context) {
        if (DEBUG) {
            context.fillStyle = "rgba(0, 150, 255, 0.25)";
            context.save();
            context.translate(this.position[0], this.position[1]);
            context.beginPath();
            context.moveTo(this.points[0][0], this.points[0][1]);
            for (let i = 1; i < this.points.length; i++) {
                context.lineTo(this.points[i][0], this.points[i][1]);
            }
            context.closePath();
            context.fill();
            context.restore();
        }
    }
}
