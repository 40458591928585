import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Actor } from "@/engine/Actor";
import { ACTOR_TYPE_TRUCK } from "@/content/Truck";
import { useEffect, useState } from "react";
class BottomUIActor extends Actor {
    constructor() {
        super(...arguments);
        this.selectedTruckId = null;
        this.onTruckChanged = () => { };
    }
    setOnTruckChanged(onTruckChanged) {
        this.onTruckChanged = onTruckChanged;
    }
    onEvent(eventName, eventData) {
        if (eventName === "truckSelected") {
            const truckId = eventData;
            const trucks = this.gameLogic.allActors
                .filter(actor => actor.actorType === ACTOR_TYPE_TRUCK);
            const selectedTruck = trucks.find(truck => truck.truckId === truckId);
            this.selectedTruckId = truckId;
            this.onTruckChanged(selectedTruck);
        }
        else if (eventName === "truckDeselected") {
            this.selectedTruckId = null;
            this.onTruckChanged(null);
        }
        else if (eventName === "resourcePickedUp") {
            if (this.selectedTruckId === null)
                return;
            if (this.selectedTruckId !== eventData.truckId)
                return;
            const trucks = this.gameLogic.allActors
                .filter(actor => actor.actorType === ACTOR_TYPE_TRUCK);
            const selectedTruck = trucks.find(truck => truck.truckId === this.selectedTruckId);
            this.onTruckChanged(selectedTruck);
        }
        else if (eventName === "resourceDelivered") {
            if (this.selectedTruckId === null)
                return;
            if (this.selectedTruckId !== eventData.truckId)
                return;
            const trucks = this.gameLogic.allActors
                .filter(actor => actor.actorType === ACTOR_TYPE_TRUCK);
            const selectedTruck = trucks.find(truck => truck.truckId === this.selectedTruckId);
            this.onTruckChanged(selectedTruck);
        }
        else if (eventName === "truckUpgrade") {
            if (this.selectedTruckId === null)
                return;
            if (this.selectedTruckId !== eventData.truckId)
                return;
            const trucks = this.gameLogic.allActors
                .filter(actor => actor.actorType === ACTOR_TYPE_TRUCK);
            const selectedTruck = trucks.find(truck => truck.truckId === this.selectedTruckId);
            this.onTruckChanged(selectedTruck);
        }
    }
}
export function BottomUI(options) {
    const [truck, setTruck] = useState(null);
    const [truckKey, setTruckKey] = useState(0);
    const [canUpgradeTruck, setCanUpgradeTruck] = useState(true);
    const [actor, setActor] = useState(new BottomUIActor(options.gameLogic));
    function onTruckChanged(truck) {
        if (truck) {
            setTruck({
                carrying: truck === null || truck === void 0 ? void 0 : truck.carrying,
                truckId: truck === null || truck === void 0 ? void 0 : truck.truckId,
                upgradeLevel: truck === null || truck === void 0 ? void 0 : truck.upgradeLevel,
            });
        }
        else {
            setTruck(null);
        }
        setTruckKey(truckKey + 1);
    }
    function upgradeTruck() {
        if (truck === null)
            return;
        options.gameLogic.dispatch("tryUpgradeTruck", {
            truckId: truck.truckId,
        });
    }
    function buyTruck() {
        options.gameLogic.dispatch("tryBuyTruck", {});
    }
    useEffect(() => {
        const gameLogic = options.gameLogic;
        gameLogic.listen("truckSelected", actor);
        gameLogic.listen("truckDeselected", actor);
        gameLogic.listen("truckUpgrade", actor);
        gameLogic.listen("resourcePickedUp", actor);
        gameLogic.listen("resourceDelivered", actor);
        actor.setOnTruckChanged(onTruckChanged);
    }, []);
    return _jsx("div", Object.assign({ className: "game-bottom-ui" }, { children: _jsx("div", Object.assign({ className: "game-bottom-ui-bar" }, { children: truck === null
                ? _jsxs("div", { children: ["No selection ", _jsx("button", Object.assign({ onClick: () => buyTruck() }, { children: "Buy truck (cost: 10 points)" }))] })
                : _jsxs("div", { children: [_jsxs("p", { children: ["Truck (level ", _jsx("b", { children: truck.upgradeLevel + 1 }), ") ", _jsxs("button", Object.assign({ onClick: () => upgradeTruck(), disabled: !canUpgradeTruck }, { children: ["Upgrade (cost: ", 2 * (1 + truck.upgradeLevel), " points)"] }))] }), _jsx("p", { children: truck.carrying === null
                                ? _jsx("i", { children: "Empty" })
                                : _jsxs("span", { children: ["Carrying ", _jsx("b", { children: truck.carrying })] }) })] }) }), truckKey) }));
}
