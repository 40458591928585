import { LOADED_SOUNDS, SOUNDS, VOLUME_OVERRIDES } from "@/assets/assets";
import { Actor } from "@/engine/Actor";
import { GameLogic } from "@/engine/GameLogic";

export const ACTOR_TYPE_SOUND_EFFECT = "sound-effect";

export class SoundEffect extends Actor {

    private isMuted: boolean = false;
    public effectName: string | null = null;

    constructor(
        gameLogic: GameLogic
    ) {
        super(gameLogic, ACTOR_TYPE_SOUND_EFFECT);

        gameLogic.listen("playSoundEffect", this);
        gameLogic.listen("playSoundEffectAt", this);
        gameLogic.listen("muteSound", this);
        gameLogic.listen("unmuteSound", this);
    }

    public onEvent(eventName: string, eventData: any): void {
        if (eventName === "playSoundEffect") {
            if (this.isMuted) {
                return;
            }
            if (eventData.effectName === this.effectName) {
                const soundData = LOADED_SOUNDS[this.effectName];
                if (soundData) {
                    if (this.effectName in VOLUME_OVERRIDES) {
                        soundData.volume = VOLUME_OVERRIDES[this.effectName];
                    }
                    soundData.pause();
                    soundData.currentTime = 0;
                    soundData.play();
                }
            }
        } else if (eventName === "playSoundEffectAt") {
            if (this.isMuted) {
                return;
            }
            const soundData = LOADED_SOUNDS[eventData.effectName];
            if (soundData) {

                const camera = this.gameLogic.camera;
                const canvasSize = this.gameLogic.canvasSize;
                const screenCenter = camera.getCenter(canvasSize);
                const distance = Math.sqrt(
                    Math.pow(screenCenter[0] - eventData.x, 2) +
                    Math.pow(screenCenter[1] - eventData.y, 2)
                );
                console.log(distance, eventData.radius, screenCenter, eventData.x, eventData.y);
                const volume = Math.max(0, 1 - distance / eventData.radius);
                soundData.pause();
                soundData.volume = volume;
                soundData.currentTime = 0;
                soundData.play();

            }
        } else if (eventName === "muteSound") {
            this.isMuted = true;
        } else if (eventName === "unmuteSound") {
            this.isMuted = false;
        }
    }
}