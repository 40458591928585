import { Actor } from "@/engine/Actor";
import { polygonContains } from "@/math/Polygon";
import { Enemy } from "./Enemy";
import { IMAGES } from "@/assets/assets";
import { DEBUG } from "@/constants";
export const ACTOR_TYPE_SPAWN_AREA = "spawn-area";
export class SpawnArea extends Actor {
    constructor(gameLogic) {
        super(gameLogic, ACTOR_TYPE_SPAWN_AREA);
        this.spawnAreaId = Symbol();
        this.dropPointId = null;
        this.spawns = {};
        this.points = [];
        this.spawnTimer = 9999;
        gameLogic.listen("enemyBirth", this);
    }
    randomSpawnPoint() {
        const minX = this.points.reduce((min, point) => Math.min(min, point[0]), Infinity);
        const maxX = this.points.reduce((max, point) => Math.max(max, point[0]), -Infinity);
        const minY = this.points.reduce((min, point) => Math.min(min, point[1]), Infinity);
        const maxY = this.points.reduce((max, point) => Math.max(max, point[1]), -Infinity);
        let spawnPoint = [0, 0];
        let contains = false;
        do {
            spawnPoint = [
                this.position[0] + Math.random() * (maxX - minX) + minX,
                this.position[1] + Math.random() * (maxY - minY) + minY
            ];
            contains = polygonContains(this, spawnPoint);
        } while (contains);
        return spawnPoint;
    }
    spawnEnemy() {
        let spawnPoint = this.randomSpawnPoint();
        const enemy = new Enemy(this.gameLogic);
        enemy.dropPointId = this.dropPointId;
        enemy.spawnAreaId = this.spawnAreaId;
        enemy.position = spawnPoint;
        enemy.species = this.spawns.name;
        enemy.image = new Image();
        enemy.image.src = IMAGES[this.spawns.properties.find((property) => property.name === "image").value];
        this.children.push(enemy);
    }
    onEvent(eventName, eventData) {
        if (eventName === "enemyBirth") {
            if (eventData.dropPointId === this.dropPointId) {
                this.spawnEnemy();
            }
        }
    }
    onEndDraw(dt, context) {
        if (DEBUG) {
            context.save();
            context.translate(this.position[0], this.position[1]);
            context.strokeStyle = "red";
            context.beginPath();
            context.moveTo(this.points[0][0], this.points[0][1]);
            for (let i = 1; i < this.points.length; i++) {
                context.lineTo(this.points[i][0], this.points[i][1]);
            }
            context.closePath();
            context.stroke();
            context.restore();
        }
    }
    onTick(dt) {
        this.spawnTimer += dt;
        if (this.spawnTimer >= 10000) {
            this.spawnTimer = 0;
            this.spawnEnemy();
        }
    }
}
