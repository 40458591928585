import { Actor } from "@/engine/Actor";
import { ACTOR_TYPE_TRUCK } from "./Truck";
import { vec2len, vec2sub } from "@/math/Vector2";
import { DEBUG } from "@/constants";
import { DropPointInfo } from "./DropPointInfo";
import { ACTOR_TYPE_ENEMY } from "./Enemy";
export const ACTOR_TYPE_DROP_POINT = "dropPoint";
const IMAGE_SIZE = 128;
export const DROP_DISTANCE = 100;
const FEEDING_HUNGER_THRESHOLD = 0.25;
const FEEDING_NUTRIENTS = 1;
const BIRTH_NUTRIENTS = 6;
const FOOD_NUTRIENTS = 6;
export class DropPoint extends Actor {
    constructor(gameLogic) {
        super(gameLogic, ACTOR_TYPE_DROP_POINT);
        this.dropPointId = Symbol();
        this.name = "No name";
        this.description = "No description";
        this.image = new Image();
        this.consumes = {};
        this.isHovered = false;
        this.dropAreaAnimationTimer = 0;
        gameLogic.listen("pointerMove", this);
        gameLogic.listen("resourceDelivered", this);
        const dropPointInfo = new DropPointInfo(gameLogic);
        dropPointInfo.dropPointId = this.dropPointId;
        this.children.push(dropPointInfo);
    }
    onBeginDraw(dt, context) {
        context.save();
        context.translate(this.position[0], this.position[1]);
    }
    drawDropArea(dt, context) {
        this.dropAreaAnimationTimer += dt;
        context.save();
        context.translate(this.position[0], this.position[1]);
        context.rotate(this.dropAreaAnimationTimer * 0.5);
        context.beginPath();
        context.strokeStyle = "rgba(46, 31, 25, 0.5)";
        context.lineWidth = 15.0;
        context.setLineDash([50, 50]);
        context.arc(0, 0, DROP_DISTANCE + 3.0 * Math.sin(this.dropAreaAnimationTimer * 0.75), 0, 2 * Math.PI);
        context.stroke();
        context.setLineDash([0]);
        if (this.isHovered) {
            context.fillStyle = "rgba(46, 31, 25, 0.2)";
            context.fill();
        }
        context.restore();
    }
    onEndDraw(dt, context) {
        const { position } = this;
        const { image } = this;
        context.restore();
        this.drawDropArea(dt, context);
        if (DEBUG) {
            context.strokeStyle = "red";
            context.beginPath();
            context.arc(position[0], position[1], DROP_DISTANCE, 0, 2 * Math.PI);
            context.stroke();
        }
    }
    onTick(dt) {
        const trucks = this.gameLogic.allActors
            .filter(actor => actor.actorType === ACTOR_TYPE_TRUCK);
        const loadedTrucks = trucks.filter(truck => truck.carrying !== null);
        for (let loadedTruck of loadedTrucks) {
            const truckDistance = vec2len(vec2sub(loadedTruck.position, this.position));
            if (truckDistance < DROP_DISTANCE) {
                this.gameLogic.dispatch("truckDrop", {
                    truckId: loadedTruck.truckId,
                    resourceId: this.consumes.name,
                    dropPointId: this.dropPointId
                });
                break;
            }
        }
    }
    distributeNutrients() {
        let total = 0.0;
        const enemies = this.gameLogic.allActors
            .filter(actor => actor.actorType === ACTOR_TYPE_ENEMY);
        const hungryEnemies = enemies.filter(enemy => enemy.hunger > FEEDING_HUNGER_THRESHOLD
            && !enemy.dead
            && enemy.dropPointId === this.dropPointId);
        for (let enemy of hungryEnemies) {
            if (total >= FOOD_NUTRIENTS) {
                return;
            }
            total += FEEDING_NUTRIENTS;
            this.gameLogic.dispatch("enemyFed", {
                enemyId: enemy.enemyId,
            });
        }
        while (total + BIRTH_NUTRIENTS <= FOOD_NUTRIENTS) {
            this.gameLogic.dispatch("enemyBirth", {
                dropPointId: this.dropPointId
            });
            total += BIRTH_NUTRIENTS;
        }
    }
    onEvent(eventName, eventData) {
        if (eventName === "pointerMove") {
            const { x, y } = eventData;
            const distance = vec2len(vec2sub(this.gameLogic.camera.toWorldSpace(x, y), this.position));
            if (distance < DROP_DISTANCE) {
                if (!this.isHovered) {
                    this.isHovered = true;
                    this.gameLogic.dispatch("dropPointHovered", { dropPointId: this.dropPointId });
                }
            }
            else {
                if (this.isHovered) {
                    this.isHovered = false;
                    this.gameLogic.dispatch("dropPointUnhovered", { dropPointId: this.dropPointId });
                }
            }
        }
        else if (eventName === "resourceDelivered") {
            const { dropPointId } = eventData;
            if (dropPointId === this.dropPointId) {
                this.distributeNutrients();
            }
        }
    }
}
