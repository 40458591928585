import { Actor } from "@/engine/Actor";
import { GameLogic } from "@/engine/GameLogic";
import { Vector2 } from "@/math/Vector2";
import { ACTOR_TYPE_GAME_PROGRESS, GameProgress } from "./GameProgress";
import { TRUCK_HEIGHT, TRUCK_WIDTH, Truck } from "./Truck";
import { GoingIndicator } from "./GoingIndicator";

export const ACTOR_TYPE_BUY_TRUCK_CONTROLLER = "buy_truck_controller";

export class BuyTruckController extends Actor {

    private isBuying: boolean = false;
    private buyLocation: Vector2 = [0, 0];

    constructor(
        gameLogic: GameLogic
    ) {
        super(gameLogic, ACTOR_TYPE_BUY_TRUCK_CONTROLLER);

        gameLogic.listen("tryBuyTruck", this);
        gameLogic.listen("pointerMove", this);
        gameLogic.listen("pointerUp", this);
    }

    public onEvent(eventName: string, eventData: any): void {
        if (eventName === "tryBuyTruck") {
            const gameProgress = this.gameLogic.allActors.find(
                actor => actor.actorType === ACTOR_TYPE_GAME_PROGRESS
            ) as GameProgress;

            if (gameProgress.score < 10) {
                return;
            }

            this.isBuying = true;
        } else if (eventName === "pointerMove") {
            if (this.isBuying) {
                this.buyLocation = this.gameLogic.camera.toWorldSpace(
                    eventData.x,
                    eventData.y
                );
            }
        } else if (eventName === "pointerUp") {
            if (!this.isBuying) {
                return;
            }

            this.isBuying = false;

            const gameProgress = this.gameLogic.allActors.find(
                actor => actor.actorType === ACTOR_TYPE_GAME_PROGRESS
            ) as GameProgress;

            if (gameProgress.score < 10) {
                return;
            }

            gameProgress.changeScore(-10);

            const newTruck = new Truck(this.gameLogic);
            newTruck.position = this.buyLocation;
            this.gameLogic.actors.push(newTruck);

            const goingIndicator = new GoingIndicator(this.gameLogic);
            goingIndicator.truckId = newTruck.truckId;
            this.gameLogic.actors.push(goingIndicator);

        }
    }

    public onEndDraw(dt: number, context: CanvasRenderingContext2D): void {
        if (this.isBuying) {
            context.fillStyle = "rgba(0, 0, 0, 0.2)";
            context.fillRect(
                this.buyLocation[0] - 0.5,
                this.buyLocation[1] - 0.5,
                TRUCK_HEIGHT,
                TRUCK_WIDTH
            );
        }
    }

}