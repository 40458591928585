import { HasPosition } from "@/trait/HasPosition";
import { SteeringBehaviour } from "./SteeringBehaviour";
import { HasVelocity } from "@/trait/HasVelocity";
import { HasRotation } from "@/trait/HasRotation";
import { HasPolygon } from "@/trait/HasPolygon";
import { Vector2, vec2add, vec2dot, vec2len, vec2norm, vec2rotate, vec2scale, vec2sub } from "@/math/Vector2";
import { HasObstacles } from "@/trait/HasObstacles";
import { closestPointOnPolygon } from "@/math/Polygon";

export class CollisionAvoidanceBehaviour implements SteeringBehaviour<HasPosition & HasVelocity & HasRotation & HasObstacles> {

    constructor(radius: number) {
        this.radius = radius;
    }

    acceleration(actor: HasPosition & HasVelocity & HasRotation & HasObstacles, dt: number): number {
        return 0;
    }


    private radius: number;
    private obstacles: Array<HasPolygon>;


    angularAcceleration(actor: HasPosition & HasRotation & HasObstacles, dt: number): number {
        const position = actor.position;

        if (!position) {
            return 0;
        }

        let closestObstacle: HasPolygon | null = null;
        let closestPoint: Vector2 | null = null;
        let closestDistance = Infinity;

        for (const obstacle of actor.obstacles) {
            const point = closestPointOnPolygon(obstacle, position);
            const distance = vec2len(vec2sub(point, position));

            if (distance < closestDistance) {
                closestDistance = distance;
                closestObstacle = obstacle;
                closestPoint = point;
            }
        }

        if (!closestObstacle || !closestPoint || closestDistance > this.radius) {
            return 0;
        }

        const avoidanceVector = vec2sub(position, closestPoint);
        const forwardVector = vec2rotate([0, -1], actor.rotation);
        const forwardDotAvoidance = vec2dot(forwardVector, avoidanceVector);

        if(forwardDotAvoidance > 0.95) {
            return 0;
        }
        const leftRotated = actor.rotation - dt * 2.0;
        const rightRotated = actor.rotation + dt * 2.0;
        const leftVector = vec2rotate([0, 1], leftRotated);
        const rightVector = vec2rotate([0, 1], rightRotated);
        const leftDotAvoidance = vec2dot(leftVector, avoidanceVector);
        const rightDotAvoidance = vec2dot(rightVector, avoidanceVector);

        if (leftDotAvoidance < rightDotAvoidance) {
            return -6;
        } else {
            return 6;
        }

    }
}