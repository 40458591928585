import { Vector2, vec2dot, vec2len, vec2rotate, vec2sub } from "@/math/Vector2";
import { SteeringBehaviour } from "./SteeringBehaviour";
import { HasPosition } from "@/trait/HasPosition";
import { HasDestination } from "@/trait/HasDestination";
import { HasVelocity } from "@/trait/HasVelocity";

const TRUCK_ARRIVAL_THRESHOLD = 40;

export class ArriveAtBehaviour implements SteeringBehaviour<HasPosition & HasDestination & HasVelocity> {

    angularAcceleration(actor: HasPosition & HasDestination & HasVelocity, dt: number): number {

        return 0;

    }

    acceleration(actor: HasPosition & HasDestination & HasVelocity, dt: number): number {

        const position = actor.position;
        const destination = actor.destination;
        const velocity = actor.velocity;

        if (!position || !destination) {
            return 0;
        }

        // See if we have reached the target
        const destinationDistance = vec2len(vec2sub(destination, position));

        if (destinationDistance > TRUCK_ARRIVAL_THRESHOLD) {
            return 0;
        }

        if (velocity > 0) {
            return -10;
        }

        return 0.0;

    }

}