import bloodBagDataUrl from './blood-bag.png';
import boxesDataUrl from './boxes.png';
import forkliftDataUrl from './forklift.png';
import goingIndicatorDataUrl from './going-indicator.png';
import levelDataUrl from './level.png';
import levelBigDataUrl from './level-big.jpg';
import truckDataUrl from './truck.png';
import horseDataUrl from './horse.png';
import meatDataUrl from './meat.png';
import hanselDataUrl from './hansel.png';
import gretelDataUrl from './gretel.png';
import batDataUrl from './bat.png';
import werewolfDataUrl from './werewolf.png';
import broomDataUrl from './broom.png';

import carSound from "./Car.flac";
import cargoSound from "./Cargo.flac";
import citySound from "./City.flac";
import forestSound from "./Forest.flac";
import monsterSound from "./Monster.flac";
import mountainSound from "./Mountain.flac";
import musicSound from "./Music.flac";
import ui0Sound from "./Ui0.flac";
import ui1Sound from "./Ui1.flac";
import ui2Sound from "./Ui2.flac";
import ui3Sound from "./Ui3.flac";
import waterSound from "./Water.flac";

export const IMAGES = {
    "blood-bag.png": bloodBagDataUrl,
    "boxes.png": boxesDataUrl,
    "forklift.png": forkliftDataUrl,
    "going-indicator.png": goingIndicatorDataUrl,
    "level.png": levelDataUrl,
    "level-big.png": levelBigDataUrl,
    "truck.png": truckDataUrl,
    "horse.png": horseDataUrl,
    "meat.png": meatDataUrl,
    "hansel.png": hanselDataUrl,
    "gretel.png": gretelDataUrl,
    "bat.png": batDataUrl,
    "werewolf.png": werewolfDataUrl,
    "broom.png": broomDataUrl,
}

export const SOUNDS = {
    "Car.flac": carSound,
    "Cargo.flac": cargoSound,
    "City.flac": citySound,
    "Forest.flac": forestSound,
    "Monster.flac": monsterSound,
    "Mountain.flac": mountainSound,
    "Music.flac": musicSound,
    "Ui0.flac": ui0Sound,
    "Ui1.flac": ui1Sound,
    "Ui2.flac": ui2Sound,
    "Ui3.flac": ui3Sound,
    "Water.flac": waterSound,
}

export const VOLUME_OVERRIDES = {
    "Car.flac": 0.15,
    "Water.flac": 0.8,
}

function blobObjectUrl(dataUrl: string): string {
    // Strip off the data: URL prefix to get just the base64-encoded bytes
    let base64String = dataUrl.split(',')[1];

    // Decode base64 string to a byte array
    let binaryString = atob(base64String);
    let len = binaryString.length;
    let bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob from the byte array
    let blob = new Blob([bytes.buffer], { type: 'audio/flac' });

    // Create an Object URL from the Blob
    let url = URL.createObjectURL(blob);
    return url;
}

export const LOADED_SOUNDS = Object.keys(SOUNDS).reduce(
    (acc, key) => {
        const sound = new Audio();
        sound.src = blobObjectUrl(SOUNDS[key]);
        acc[key] = sound;
        return acc;
    },
    {} as { [key: string]: HTMLAudioElement }
);

export const SFX = [
    "Car.flac",
    "Cargo.flac",
    "Monster.flac",
    "Ui0.flac",
    "Ui1.flac",
    "Ui2.flac",
    "Ui3.flac"
];

export const AMBIENT = [
    "City",
    "Forest",
    "Mountain",
    "Water"
];