import { Actor } from "@/engine/Actor";
import { GameLogic } from "@/engine/GameLogic";
import { Vector2, vec2len } from "@/math/Vector2";
import { ACTOR_TYPE_LEVEL, Level } from "./Level";
import { DRAW_FOG_OF_WAR } from "@/constants";

const GRID_WIDTH = 128;
const GRID_HEIGHT = 128;
export const ACTOR_TYPE_FOG_OF_WAR = "fog-of-war";

export class FogOfWar extends Actor {

    public grid: Array<Array<boolean>> = [];

    constructor(
        gameLogic: GameLogic
    ) {
        super(gameLogic, ACTOR_TYPE_FOG_OF_WAR);

        for (let y = 0; y < GRID_HEIGHT; y++) {
            this.grid[y] = [];
            for (let x = 0; x < GRID_WIDTH; x++) {
                this.grid[y][x] = false;
            }
        }
    }

    discover(
        pos: Vector2,
        radius: number
    ) {
        const levelActor = this.gameLogic.allActors
            .find(actor => actor.actorType === ACTOR_TYPE_LEVEL) as Level;

        const mapWidth = levelActor.mapWidth;
        const mapHeight = levelActor.mapHeight;
        const tileWidth = mapWidth / GRID_WIDTH;
        const tileHeight = mapHeight / GRID_HEIGHT;

        const fromX = Math.max(0, Math.floor(pos[0] / tileWidth) - Math.ceil(radius / tileWidth));
        const fromY = Math.max(0, Math.floor(pos[1] / tileHeight) - Math.ceil(radius / tileHeight));
        const toX = Math.min(GRID_WIDTH, Math.floor(pos[0] / tileWidth) + Math.ceil(radius / tileWidth));
        const toY = Math.min(GRID_HEIGHT, Math.floor(pos[1] / tileHeight) + Math.ceil(radius / tileHeight));

        for (let y = fromY; y < toY; y++) {
            for (let x = fromX; x < toX; x++) {
                const center: Vector2 = [x * tileWidth + tileWidth / 2, y * tileHeight + tileHeight / 2];
                const distance = vec2len([pos[0] - center[0], pos[1] - center[1]]);
                if (distance < radius) {
                    this.grid[y][x] = true;
                }
            }
        }
    }

    public onEndDraw(dt: number, context: CanvasRenderingContext2D): void {

        if (!DRAW_FOG_OF_WAR) {
            return;
        }
        const levelActor = this.gameLogic.allActors
            .find(actor => actor.actorType === ACTOR_TYPE_LEVEL) as Level;

        const mapWidth = levelActor.mapWidth;
        const mapHeight = levelActor.mapHeight;
        const tileWidth = mapWidth / GRID_WIDTH;
        const tileHeight = mapHeight / GRID_HEIGHT;

        const camera = this.gameLogic.camera;
        const canvasSize = this.gameLogic.canvasSize;
        const fromX = Math.max(0, Math.floor(camera.position[0] / tileWidth));
        const fromY = Math.max(0, Math.floor(camera.position[1] / tileHeight));
        const toX = Math.min(GRID_WIDTH, Math.ceil((camera.position[0] + canvasSize[0]) / tileWidth));
        const toY = Math.min(GRID_HEIGHT, Math.ceil((camera.position[1] + canvasSize[1]) / tileHeight));

        context.fillStyle = "rgb(46, 31, 25)";

        for (let y = fromY; y < toY; y++) {
            for (let x = fromX; x < toX; x++) {
                if (!this.grid[y][x]) {
                    context.fillRect(
                        Math.floor(x * tileWidth),
                        Math.floor(y * tileHeight),
                        Math.ceil(tileWidth) + 1,
                        Math.ceil(tileHeight) + 1
                    );
                }
            }
        }

    }

}