export type Vector2 = [number, number];

export function vec2add(a: Vector2, b: Vector2): Vector2 {
    return [a[0] + b[0], a[1] + b[1]];
}

export function vec2sub(a: Vector2, b: Vector2): Vector2 {
    return [a[0] - b[0], a[1] - b[1]];
}

export function vec2scale(a: Vector2, b: number): Vector2 {
    return [a[0] * b, a[1] * b];
}

export function vec2len(a: Vector2): number {
    return Math.sqrt(a[0] * a[0] + a[1] * a[1]);
}

export function vec2norm(a: Vector2): Vector2 {
    if (a[0] === 0 && a[1] === 0) {
        return [0, 0];
    }
    return vec2scale(a, 1 / vec2len(a));
}

export function vec2dot(a: Vector2, b: Vector2): number {
    return a[0] * b[0] + a[1] * b[1];
}

export function vec2angle(a: Vector2, b: Vector2): number {
    let aNorm = vec2norm(a);
    let bNorm = vec2norm(b);
    return Math.acos(vec2dot(aNorm, bNorm));
}

export function vec2rotate(a: Vector2, angle: number): Vector2 {
    return [
        a[0] * Math.cos(angle) - a[1] * Math.sin(angle),
        a[0] * Math.sin(angle) + a[1] * Math.cos(angle)
    ];
}

export function vec2lerp(a: Vector2, b: Vector2, t: number): Vector2 {
    return [
        a[0] + (b[0] - a[0]) * t,
        a[1] + (b[1] - a[1]) * t
    ];
}