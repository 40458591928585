import { DEBUG } from "@/constants";
import { Actor } from "@/engine/Actor";
import { GameLogic } from "@/engine/GameLogic";
import { Vector2 } from "@/math/Vector2";
import { HasPolygon } from "@/trait/HasPolygon";

export const ACTOR_TYPE_WATER = "water";

export class Water extends Actor implements HasPolygon {
    public points: Vector2[] = [];
    public modifier: number = 0.1;

    constructor(
        gameLogic: GameLogic
    ) {
        super(gameLogic, ACTOR_TYPE_WATER);
    }

    public onEndDraw(dt: number, context: CanvasRenderingContext2D): void {
        if (DEBUG) {
            context.fillStyle = "rgba(0, 150, 255, 0.25)";
            context.save();
            context.translate(this.position[0], this.position[1]);
            context.beginPath();
            context.moveTo(this.points[0][0], this.points[0][1]);
            for (let i = 1; i < this.points.length; i++) {
                context.lineTo(this.points[i][0], this.points[i][1]);
            }
            context.closePath();
            context.fill();
            context.restore();
        }
    }
}