import { LOADED_SOUNDS, SOUNDS } from "@/assets/assets";
import { Actor } from "@/engine/Actor";
import { GameLogic } from "@/engine/GameLogic";

export const ACTOR_TYPE_MUSIC = "music";

const MUSIC_VOLUME = 0.5;

export class Music extends Actor {

    private musicData: HTMLAudioElement;

    constructor(
        gameLogic: GameLogic
    ) {
        super(gameLogic, ACTOR_TYPE_MUSIC);

        const musicdata = LOADED_SOUNDS["Music.flac"];
        musicdata.addEventListener(
            "canplaythrough",
            () => {
                musicdata.loop = true;
                musicdata.volume = MUSIC_VOLUME;

                setTimeout(
                    () => {
                        musicdata.play();
                    },
                    10000
                )

            }
        );

        gameLogic.listen("muteSound", this);
        gameLogic.listen("unmuteSound", this);

        this.musicData = musicdata;
    }

    public onEvent(eventName: string, eventData: any): void {
        if (eventName === "muteSound") {
            this.musicData.volume = 0;
        } else if (eventName === "unmuteSound") {
            this.musicData.volume = MUSIC_VOLUME;
        }
    }
}