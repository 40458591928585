import { Vector2, vec2dot, vec2len, vec2rotate, vec2sub } from "@/math/Vector2";
import { SteeringBehaviour } from "./SteeringBehaviour";
import { HasPosition } from "@/trait/HasPosition";
import { HasDestination } from "@/trait/HasDestination";
import { HasRotation } from "@/trait/HasRotation";
import { HasVelocity } from "@/trait/HasVelocity";

const TRUCK_ARRIVAL_THRESHOLD = 50;
const TRUCK_APPROACH_THRESHOLD = 100;
const TRUCK_NO_ROTATE_THRESHOLD = 10;
const TRUCK_ROTATION_SPEED = 5;
const TRUCK_APPROACH_SPEED = 50;

export class MoveTowardsBehaviour implements SteeringBehaviour<HasPosition & HasDestination & HasRotation & HasVelocity> {

    angularAcceleration(actor: HasPosition & HasDestination & HasRotation & HasVelocity, dt: number): number {

        const position = actor.position;
        const destination = actor.destination;
        const rotation = actor.rotation;

        if (!position || !destination) {
            return 0;
        }

        const hereToDest = vec2sub(destination, position);

        // See if we have reached the target
        const destinationDistance = vec2len(hereToDest);

        if (destinationDistance < TRUCK_NO_ROTATE_THRESHOLD) {
            return 0;
        }

        // Try to rotate left, see if dot product gets smaller
        const leftRotate = rotation - TRUCK_ROTATION_SPEED * dt;
        const leftRotateVector = vec2rotate([0, 1], leftRotate);
        const leftRotateDot = vec2dot(hereToDest, leftRotateVector);
        // Try to rotate right, see if dot product gets smaller
        const rightRotate = rotation + TRUCK_ROTATION_SPEED * dt;
        const rightRotateVector = vec2rotate([0, 1], rightRotate);
        const rightRotateDot = vec2dot(hereToDest, rightRotateVector);

        if (leftRotateDot < rightRotateDot) {
            return -1;
        } else {
            return 1;
        }

    }

    acceleration(actor: HasPosition & HasDestination & HasRotation & HasVelocity, dt: number): number {

        const position = actor.position;
        const destination = actor.destination;
        const velocity = actor.velocity;

        if (!position || !destination) {
            return 0;
        }

        // See if we have reached the target
        const destinationDistance = vec2len(vec2sub(destination, position));

        if (destinationDistance < TRUCK_APPROACH_THRESHOLD) {
            if (velocity < TRUCK_APPROACH_SPEED) {
                return 0.05;
            } else {
                return -0.05;
            }
        }

        if (destinationDistance < TRUCK_ARRIVAL_THRESHOLD) {
            return 0;
        }

        return 1;

    }

}