export function vec2add(a, b) {
    return [a[0] + b[0], a[1] + b[1]];
}
export function vec2sub(a, b) {
    return [a[0] - b[0], a[1] - b[1]];
}
export function vec2scale(a, b) {
    return [a[0] * b, a[1] * b];
}
export function vec2len(a) {
    return Math.sqrt(a[0] * a[0] + a[1] * a[1]);
}
export function vec2norm(a) {
    if (a[0] === 0 && a[1] === 0) {
        return [0, 0];
    }
    return vec2scale(a, 1 / vec2len(a));
}
export function vec2dot(a, b) {
    return a[0] * b[0] + a[1] * b[1];
}
export function vec2angle(a, b) {
    let aNorm = vec2norm(a);
    let bNorm = vec2norm(b);
    return Math.acos(vec2dot(aNorm, bNorm));
}
export function vec2rotate(a, angle) {
    return [
        a[0] * Math.cos(angle) - a[1] * Math.sin(angle),
        a[0] * Math.sin(angle) + a[1] * Math.cos(angle)
    ];
}
export function vec2lerp(a, b, t) {
    return [
        a[0] + (b[0] - a[0]) * t,
        a[1] + (b[1] - a[1]) * t
    ];
}
