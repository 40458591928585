import { Actor } from "@/engine/Actor";
import { GameLogic } from "@/engine/GameLogic";
import { ACTOR_TYPE_TRUCK, Truck } from "./Truck";

export const ACTOR_TYPE_SOUND_EFFECT_CONTROLLER = "sound-effect-controller";

export class SoundEffectController extends Actor {
    constructor(
        gameLogic: GameLogic
    ) {
        super(gameLogic, ACTOR_TYPE_SOUND_EFFECT_CONTROLLER);

        gameLogic.listen("truckSelected", this);
        gameLogic.listen("resourcePickedUp", this);
        gameLogic.listen("resourceDelivered", this);
    }

    public onEvent(eventName: string, eventData: any): void {
        if (eventName === "truckSelected") {
            this.gameLogic.dispatch("playSoundEffect", {
                effectName: "Car.flac"
            });
        } else if (eventName === "resourcePickedUp" || eventName === "resourceDelivered") {
            const trucks = this.gameLogic.allActors
                .filter(actor => actor.actorType === ACTOR_TYPE_TRUCK);
            const deliveryTruck = trucks.find(
                truck => (truck as any).truckId === eventData.truckId
            ) as Truck;
            if (deliveryTruck) {
                this.gameLogic.dispatch("playSoundEffectAt", {
                    effectName: "Cargo.flac",
                    x: deliveryTruck.position[0],
                    y: deliveryTruck.position[1],
                    radius: 700
                });
            }
        }
    }
}